import React, { useEffect, useState } from 'react'
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm'
import AuthCardLayout from 'layouts/AuthCardLayout'

const ForgetPassword = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <AuthCardLayout>
      <h1 style={{ color: '#000', fontWeight: '600', fontSize: width > 769 ? '30px' : '35px' }}>
        Forgot <br />
        Password?
      </h1>
      <p style={{ color: '#808080', fontSize: '15px', fontWeight: '500' }}>
        Enter your email and we'll send you a reset link.
      </p>
      <ForgetPasswordForm layout='card' />
    </AuthCardLayout>
  )
}

export default ForgetPassword
