import React, { useEffect, useState } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPipelines, getAllDevOpportunities, getAllProjectsSettings } from '../../../redux/projects/action'
import InprogressProjects from '../components/InProgress'
import MaintenanceJobs from '../components/Maintenance'
import PreviousProjects from '../components/Previous'
import EmptyImg from 'assets/dashboard/empty.svg'
import { fetchOpportunityEvents } from 'utils/socket'
import { getALLUnreadCount } from '../../../redux/groupChat/action'


const Projects = () => {
  const { userDetails } = useSelector(state => state.login)
  const { isDevJobs, DevJobs, pipelines, allProjectSettings } = useSelector(state => state.projects)
  const { savedPipleline } = useSelector(state => state.calenders)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [inProgressProjects, setInProgressProjects] = useState([])
  const [previousProjects, setPreviousProjects] = useState([])
  const [maintenanceJobs, setMaintenanceJobs] = useState([])
  let selectedPipeLine = pipelines?.find(item => item?.id == savedPipleline?.pipelineId)
  const dispatch = useDispatch()

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  useEffect(() => {
    if (DevJobs?.length > 0 && selectedPipeLine && allProjectSettings.length > 0) {
      let inProgress = DevJobs.filter((
        item => {
          if (
            item.pipelineStageId !== selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 2]?.id &&
            item.pipelineStageId !== selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 1]?.id ||
            allProjectSettings.some(setting =>
              setting?.projectId === item?.id && setting?.isInMaintenance == 1
            )
          ) {
            return item
          }

        }));


      let previous = DevJobs.filter(
        item => item.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 2]?.id
      )
      let maintenance = DevJobs.filter(
        // item => item.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 1]?.id
        item => {
          if (
            item.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 1]?.id
            &&
            allProjectSettings.some(setting =>
              setting?.projectId === item?.id && !setting?.isInMaintenance
            )
          ) {
            return item
          }

        })




      setInProgressProjects(inProgress)
      setPreviousProjects(previous)
      setMaintenanceJobs(maintenance)
    }
  }, [DevJobs, selectedPipeLine, allProjectSettings])

  useEffect(() => {
    if (savedPipleline && selectedPipeLine) {
      dispatch(getAllProjectsSettings({ pipelineId: savedPipleline?.pipelineId }))

      dispatch(
        getAllDevOpportunities({
          pipeline_id: savedPipleline?.pipelineId,
          setUpStageId: selectedPipeLine?.stages[0]?.id,
          shelfStageId: selectedPipeLine?.stages[1]?.id,
          limit: 100
        })
      )
      dispatch(getALLUnreadCount())

    }
  }, [savedPipleline, selectedPipeLine])



  useEffect(() => {
    if (pipelines.length > 0) {
      fetchOpportunityEvents(dispatch)
    }
  }, [pipelines])


  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        flexDirection: windowSize < 770 ? 'column' : 'row'
      }}
    >
      {!isDevJobs && inProgressProjects?.length === 0 ? (
        <>
          <div style={{ width: windowSize < 770 ? '100%' : 'calc(100% - 800px)' }}>
            <p
              className='fw-bolder'
              style={{
                background: '#fff',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                padding: '15px 10px',
                borderTop: '2px solid rgb(255 161 161)',
                borderRadius: '10px',
                marginBottom: '1rem',
                marginTop:
                  !isDevJobs && inProgressProjects?.length === 0 && !isDevJobs && inProgressProjects?.length !== 0
                    ? '0px'
                    : (!isDevJobs && inProgressProjects?.length) === 0 &&
                      (!isDevJobs && inProgressProjects?.length) === 0
                      ? '0px'
                      : ''
              }}
            >
              In-Progress Projects
            </p>
            <Card
              style={{
                height: '70vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <img src={EmptyImg} alt='empty' />
              <p className='fw-bolder mt-2 mb-1'>No In-Progress Projects</p>
              <p className='fs-10 mt-2 mb-1'>Currently, here is no projects for display</p>
            </Card>
          </div>
        </>
      ) : (
        <Card style={{ background: 'transparent', boxShadow: 'none', width: windowSize < 770 ? '100%' : 'calc(100% - 800px)' }}>
          <p
            className='fw-bolder'
            style={{
              background: '#fff',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              padding: '15px 10px',
              borderTop: '2px solid rgb(255 161 161)',
              borderRadius: '10px',
              marginBottom: '1rem'
            }}
          >
            In-Progress Projects
          </p>
          {isDevJobs ? (
            <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '50vh' }}>
              <Spinner />
            </div>
          ) : (
            <Card
              style={{
                flexDirection: windowSize < 770 ? 'column' : 'row',
                flexWrap: windowSize < 770 ? 'nowrap' : 'wrap',
                boxShadow: 'none',
                borderRadius: '0px',
                width: '100%',
                background: 'transparent',
                display: 'flex',
                height: '70vh',
                overflow: 'auto',
                gap: '16px',
                alignContent: 'flex-start'
              }}
            >
              {inProgressProjects.map((item, index) => (
                <div key={index} style={{ width: windowSize < 770 ? '100%' : '310px', flex: '0 0 auto' }}>
                  {' '}
                  {/* Added flex: 0 0 auto */}
                  <InprogressProjects key={index} job={item} />
                </div>
              ))}
            </Card>
          )}
        </Card>
      )}
      {!isDevJobs && previousProjects?.length === 0 ? (
        <>
          <div style={{ width: '400px' }}>
            <p
              className='fw-bolder'
              style={{
                background: '#fff',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                padding: '15px 10px',
                borderTop: '2px solid rgb(255 161 161)',
                borderRadius: '10px',
                marginBottom: '1rem',
                marginTop:
                  !isDevJobs && previousProjects?.length === 0 && !isDevJobs && previousProjects?.length !== 0
                    ? '0px'
                    : (!isDevJobs && previousProjects?.length) === 0 && (!isDevJobs && previousProjects?.length) === 0
                      ? '0px'
                      : ''
              }}
            >
              Previous Projects
            </p>
            <Card
              style={{
                height: '70vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <img src={EmptyImg} alt='empty' />
              <p className='fw-bolder mt-2 mb-1'>No Previous Projects</p>
              <p className='fs-10 mt-2 mb-1'>Currently, here is no projects for display</p>
            </Card>
          </div>
        </>
      ) : (
        <Card style={{ background: 'transparent', boxShadow: 'none', width: windowSize < 770 ? '100%' : '400px' }}>
          <p
            className='fw-bolder'
            style={{
              background: '#fff',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              padding: '15px 10px',
              borderTop: '2px solid rgb(255 161 161)',
              borderRadius: '10px',
              marginBottom: '1rem'
            }}
          >
            Previous Projects
          </p>
          {isDevJobs ? (
            <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '50vh' }}>
              <Spinner />
            </div>
          ) : (
            <Card
              style={{
                flexDirection: windowSize < 770 ? 'column' : 'row',
                flexWrap: windowSize < 770 ? 'nowrap' : 'wrap',
                boxShadow: 'none',
                borderRadius: '0px',
                width: '100%',
                background: 'transparent',
                display: 'flex',
                height: '70vh',
                overflow: 'auto',
                gap: '16px',
                alignContent: 'flex-start'
              }}
            >
              {previousProjects.map((item, index) => (
                <div key={index} style={{ width: windowSize < 770 ? '100%' : '100%', flex: '0 0 auto' }}>
                  {' '}
                  {/* Added flex: 0 0 auto */}
                  <PreviousProjects key={index} job={item} />
                </div>
              ))}
            </Card>
          )}
        </Card>
      )}
      {!isDevJobs && maintenanceJobs?.length === 0 ? (
        <>
          <div style={{ width: windowSize < 770 ? '100%' : '400px' }}>
            <p
              className='fw-bolder'
              style={{
                background: '#fff',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                padding: '15px 10px',
                borderTop: '2px solid rgb(255 161 161)',
                borderRadius: '10px',
                marginBottom: '1rem'
              }}
            >
              Bug Tickets
            </p>
            <Card
              style={{
                height: '70vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <img src={EmptyImg} alt='empty' />
              <p className='fw-bolder mt-2 mb-1'>No Bug Tickets</p>
              <p className='fs-10 mt-2 mb-1'>Currently, here is no projects for display</p>
            </Card>
          </div>
        </>
      ) : (
        <Card style={{ width: windowSize < 770 ? '100%' : '400px', background: 'transparent', boxShadow: 'none' }}>
          <div style={{ padding: windowSize > 770 ? '0rem' : '0px' }}>
            <p
              className='fw-bolder'
              style={{
                background: '#fff',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                padding: '15px 10px',
                borderTop: '2px solid #000',
                borderRadius: '10px',
                borderTopRightRadius: '10px',
                marginBottom: '1rem'
              }}
            >
              Bug Tickets
            </p>
          </div>
          {isDevJobs ? (
            <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '50vh' }}>
              <Spinner />
            </div>
          ) : (
            <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
              {maintenanceJobs.map(item => (
                <MaintenanceJobs job={item} key={item.id} />
              ))}
            </div>
          )}
        </Card>
      )}
    </div>
  )
}

export default Projects
