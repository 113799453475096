import React, { useState, useEffect } from 'react'
import { Spinner, Badge, OverlayTrigger, Tooltip, Card } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { BsInfoCircle } from 'react-icons/bs'

import _ from 'lodash'
import Avatar from '../Avatar'
const ProjectInfo = () => {
  const { PorjectSettings, isProjectSettings, allProjectSettings } = useSelector(state => state.projects)
  const { userDetails } = useSelector(state => state.login)
  const { jobDetail } = useSelector(state => state.users)
  let selectedProjectSetting = allProjectSettings?.find(item => item?.projectId === PorjectSettings?.projectId)

  return (
    <div>
      <div className='px-3 py-3' style={{ background: '#ECE2FF', borderRadius: '10px' }}>
        <h4 className='text-dark'>{jobDetail?.name}</h4>
        <p style={{ fontWeight: '500', color: '#000' }}>{PorjectSettings?.descriptions}</p>
        {userDetails?.userType !== 3 &&
          <p>
            TOTAL BUDGET: <b className='fs-8'>${PorjectSettings?.price}</b>
          </p>
        }

      </div>
      <div className='mt-4 bg-light px-3 py-3' style={{ borderRadius: '10px' }}>
        <div>
          <p className='d-flex align-items-center gap-2' style={{ fontWeight: '500', color: '#000' }}>
            Skills
            <OverlayTrigger placement={'right'} overlay={<Tooltip>Project Skills.</Tooltip>}>
              <div>
                <BsInfoCircle />
              </div>
            </OverlayTrigger>
          </p>
        </div>

        {isProjectSettings ? (
          <div className='d-flex flex-column align-items-center py-3'>
            <Spinner />
          </div>
        ) : (
          <div className='d-flex flex-wrap gap-2'>
            {PorjectSettings &&
              PorjectSettings.skillsDetails &&
              PorjectSettings.skillsDetails.map(item => (
                <h4 key={item?._id}>
                  <Badge bg='secondary' className='fw-normal fs-9'>
                    {item?.name}
                  </Badge>
                </h4>
              ))}
          </div>
        )}
      </div>
      <div className='bg-light px-3 py-3' style={{ borderRadius: '10px' }}>
        <div>
          <p className='d-flex align-items-center gap-2 mb-1' style={{ fontWeight: '500', color: '#000' }}>
            Assigned developers
          </p>
          <div className='d-flex align-items-center gap-3 flex-wrap'>
            {selectedProjectSetting?.assignedDev?.map((item, index) => (
              <div className='d-flex align-items-center gap-2' key={index}>
                <Avatar name={item?.name} />
                <p className='mb-0 fs-10'>{item?.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectInfo
