import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'
import Flex from 'components/common/Flex'

import authConfig from '../utils/authConfig'
import { useNavigate } from 'react-router-dom'
import lowQualityImage from 'assets/dashboard/login-bg.png';
const AuthCardLayout = ({ leftSideContent, children, footer = true }) => {
  const navigate = useNavigate()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    let token = localStorage.getItem(authConfig.storageTokenKeyName)
    if (token) {
      navigate('/')
    }
  }, [])

  return (
    <div className='login-main-div'>
      <Card style={{ height: '100vh' }}>
        <Card.Body className='p-0'>
          <Row className='g-0' style={{ height: '100vh' }}>
            <Col
              md={6}
              sm={0}
              className='text-center d-flex justify-content-center align-items-center flex-column  d-none d-md-flex'
              style={{
                backgroundImage: `url(${lowQualityImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                '@media (max-width: 769px)': {
                  display: 'none !important'
                }
              }}
            >
              <div className='position-relative p-4 pt-md-5 pb-md-2'>
                {/* <Background image={halfCircle} className='bg-auth-card-shape' /> */}

                <Link
                  className='link-light mb-4 font-sans-serif fw-bolder fs-5 d-inline-block'
                  to='https://mytradingcoder.com/'
                  target='_blank'
                >
                  <img
                    className='me-2'
                    src={'https://mytradingcoder.com/wp-content/uploads/2023/12/mtc-logo-mobile.svg'}
                    alt='Logo'
                    width={150}
                    height={100}
                  />
                </Link>
                <h5 style={{ color: '#000', fontWeight: '600' }}>My Trading Coder Portal</h5>
              </div>
              <h6 style={{ color: '#000', textAlign: 'center' }}>
                Custom Trading Development Services Your personal <br />trading programming firm for indicators, strategies,
                <br /> EAs, trading bots, & more

              </h6>
            </Col>
            <Col md={6} as={Flex} alignItems='center' justifyContent='center'>
              <div className='w-75'>
                <div>
                  {width < 769 && (
                    <>
                      {' '}
                      <img
                        style={{ marginBottom: '10px' }}
                        src={'https://mytradingcoder.com/wp-content/uploads/2023/12/mtc-logo-mobile.svg'}
                      />
                    </>
                  )}
                </div>
                {children}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* </Col> */}
    </div>
  )
}

export default AuthCardLayout
