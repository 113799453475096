import React from 'react';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { isAdmin } from 'utils/constants'
import NotificationDropdown from './NotificationDropdown';
const TopNavRightSideNavItem = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {isAdmin() && currentUrl !== '/' &&
        <p className="mb-0 d-flex align-items-center gap-1 me-3 cursor-pointer" onClick={() => navigate('/')}>
          <FaArrowLeftLong />
          Go to Dashboard
        </p>
      }

      {/* <ThemeControlDropdown /> */}
      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
