import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Spinner from 'react-bootstrap/Spinner'
import { postWithoutToken } from 'utils/constants'
import { apis } from 'utils/Apis'
import { FaChevronLeft } from 'react-icons/fa6'

const ForgetPasswordForm = () => {
  // State
  // const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // Handler
  // const handleSubmit = e => {
  //   e.preventDefault()
  //   if (email) {
  //     toast.success(`An email is sent to ${email} with password reset link`, {
  //       theme: 'colored'
  //     })
  //   }
  // }

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(' Please enter a valid email address')
        .matches(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
        .min(5, 'Email must be at least 5 characters')
        .max(100, 'Email must not exceed 100 characters')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true)
      let params = {
        email: values.email
      }
      const res = await postWithoutToken(apis.user_forgot_password, params)
      if (res?.data?.status_code == 200 || res?.data?.status_code == 201) {
        formik.resetForm()
        setIsLoading(false)
        toast.success(res?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      } else {
        setIsLoading(false)
        toast.error(res?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    }
  })

  return (
    <Form className='mt-4' noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className='mb-3'>
        <Form.Control
          name='email'
          type='email'
          placeholder={'Email address'}
          style={{ backgroundColor: '#FFFFFF', borderRadius: '50px', padding: '10px 20px', boxShadow: 'none' }}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched.email && formik.errors.email}
        />
        <Form.Control.Feedback type='invalid'>{formik.errors.email}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className='mb-3'>
        <Button
          className='w-100'
          type='submit'
          disabled={!formik.values.email || isLoading}
          style={{
            backgroundColor: '#682FCC',
            border: 'none',
            borderRadius: '50px',
            padding: '10px'
          }}
        >
          {isLoading ? <Spinner animation='border' size='sm' /> : ' Send reset link'}
        </Button>

        <Link
          className='fs-10 mt-3'
          to='/mtc/auth/login'
          style={{
            color: 'rgb(104, 47, 204)',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FaChevronLeft style={{ color: 'rgb(104, 47, 204)', fontSize: '12px' }} />
          <span>Back to login</span>
        </Link>
      </Form.Group>
    </Form>
  )
}

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
}

ForgetPasswordForm.defaultProps = { layout: 'simple' }

export default ForgetPasswordForm
