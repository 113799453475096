import paths, { rootPaths } from './paths';


export const appRoutes = {
  label: 'MyTradingCoder Portal',
  children: [
    {
      icon: 'chart-pie',
      name: 'Dashboard',
      to: rootPaths.root,
      exact: true,
      active: true
    },
    {
      name: 'Projects',
      icon: ['fab', 'trello'],
      to: paths.kanban,
      active: true
    },
    {
      name: 'Users',
      icon: 'users',
      to: paths.users,
      active: true
    },


  ]
};


export default [
  appRoutes
];
