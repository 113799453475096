import { ReducerType } from './type'

const InitialState = {
  success: false,
  isClients: false,
  clientList: [],
  error: null
}

const client = (state = InitialState, action) => {
  switch (action?.type) {
    case ReducerType.GET_ALL_CLIENT_ACTION_START:
      return {
        ...state,
        isClients: true,
        succes: false
      }
    case ReducerType.GET_ALL_CLIENT:
      return {
        ...state,
        clientList: action.payload,
        success: true,
        isClients: false
      }

    case ReducerType.GET_ALL_CLIENT_ERROR:
      return {
        ...state,
        clientList: [],
        success: false,
        isClients: false
      }
    case ReducerType.CLIENT_UPDATE_STATUS:
      const { _id, isActive } = action.payload
      const updatedClientList = state.clientList.map(client => {
        if (client._id === _id) {
          return { ...client, status: isActive }
        }
        return client
      })
      return {
        ...state,
        clientList: updatedClientList
      }
    default:
      return state
  }
}
export default client
