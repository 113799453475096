import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Notes from './projectDetailViews/Notes'
import Appointments from './projectDetailViews/Appointments'
import Documents from './projectDetailViews/Documents'
import Videos from './projectDetailViews/Videos'
import ProjectInfo from './projectDetailViews/ProjectInfo'
import ClientDocs from './projectDetailViews/ClientDocs'

const DetailDrawer = ({ openDetail, openDetailTitle, handleCloseDetail }) => {
  return (
    <Offcanvas show={openDetail} onHide={handleCloseDetail} placement='end' style={{ width: '600px' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{openDetailTitle}</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body>
        {(() => {
          switch (openDetailTitle) {
            case 'Information':
              return <ProjectInfo />
            case 'Notes':
              return <Notes />
            case 'Appointments':
              return <Appointments />
            case 'Documents':
              return <Documents />
            case 'Videos':
              return <Videos />
            case 'Client Documents':
              return <ClientDocs />
            default:
          }
        })()}
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default DetailDrawer
