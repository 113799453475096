import { checkResponse, get, post, put } from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'

export const getAllProjectsCount = (params) => async dispatch => {

    dispatch({
        type: ReducerType.GET_PROJECTS_COUNT_START
    })

    const res = await get(apis.projects_count, params)
    const response = await checkResponse(res)
    if (response?.success) {
        dispatch({
            type: ReducerType.GET_PROJECTS_COUNT,
            payload: response.data?.data
        })

        return { data: response.data }
    } else {
        dispatch({
            type: ReducerType.GET_PROJECTS_COUNT_ERROR
        })
    }
}

