import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Button, Form } from 'react-bootstrap'
import classNames from 'classnames'
import { LuEyeOff, LuEye } from 'react-icons/lu'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Spinner from 'react-bootstrap/Spinner'
import { putWithOutToken } from 'utils/constants'
import { apis } from 'utils/Apis'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa6'
import OptScreen from './card/Otp'
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux'
import { ssoLogin, resendOtp } from '../../redux/login/action'
import authConfig from 'utils/authConfig'
import { Encrypt } from '../../utils/constants'


const PasswordResetForm = ({ hasLabel, user_id, userEmail }) => {
  // State
  const navigate = useNavigate()

  const { isLogin, loading, userDetails } = useSelector(state => state.login)

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [isSsoSignUp, setIsSsoSignUp] = useState(false)
  const [ssoMail, setSSOMail] = useState('')
  const [ssoId, setSSOId] = useState('')
  const dispatch = useDispatch()
  // Handler
  // const handleSubmit = e => {
  //   e.preventDefault()
  //   toast.success('Login with your new password', {
  //     theme: 'colored'
  //   })
  // }

  // const handleFieldChange = e => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   })
  // }

  const handletoggleNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  }

  const handletoggleConfirmPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword)
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(/[A-Za-z]/, 'Password must contain at least one letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[0-9]/, 'Password must contain at least one digit')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .matches(/^\S*$/, 'Password must not contain spaces')
        // .notOneOf([' '], 'Password must not contain spaces')
        .required('Please enter a valid password')
        .min(8, 'Password must be at least 8 characters')
        .max(128, 'Password must not exceed 128 characters'),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Please ensure password and re-enter password fields should be the same')
        .required('Please enter a valid confirm password')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true)
      let params = {
        _id: user_id,
        password: values.password
      }
      const res = await putWithOutToken(apis.user_reset_password, params)
      if (res?.data?.status_code == 200 || res?.data?.status_code == 201) {
        formik.resetForm()
        setIsLoading(false)
        toast.success(res?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
        navigate('/mtc/auth/login')
      } else {
        setIsLoading(false)
        toast.error(res?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    }
  })


  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { access_token } = tokenResponse;
        const profileResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        const profileData = await profileResponse.json();
        let res = await dispatch(ssoLogin({ email: userEmail, ssoEmail: profileData.email, googleId: profileData.id }))
        if (res?.data?.data?.status_code == 200 || res?.data?.data?.status_code == 201) {

          dispatch({
            type: 'OTP_VERIFIED',
            payload: res?.data?.data?.data
          })
        } else {
          setSSOId(profileData.id)
          setSSOMail(profileData.email)
          await resendOtp({
            email: userEmail, type: 2, ssoEmail: profileData.email
          })
          setIsSsoSignUp(true)
          setModalShow(true)

          // toast.error(response?.data?.data?.message, {
          //   position: 'top-right',
          //   theme: 'colored'
          // })
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    },
  });


  useEffect(() => {
    if (userDetails && !isLogin) {
      const role = Encrypt(userDetails?.userType)
      const IncrypedToken = Encrypt(userDetails?.token)
      if (userDetails?.token) {
        window.localStorage.setItem(authConfig.rolesKey, role)
        window.localStorage.setItem(authConfig.storageTokenKeyName, IncrypedToken)
        if (userDetails?.userType == 1) {
          navigate('/')
        } else {
          navigate('/app/projects')
        }
      }
    }
  }, [userDetails, isLogin])




  return (
    <>
      <Form className={classNames('mt-3', { 'text-left': hasLabel })} noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className='mb-3 position-relative'>
          {/* {hasLabel && <Form.Label>New Password</Form.Label>} */}
          <Form.Control
            style={{ backgroundColor: '#FFFFFF', borderRadius: '50px', padding: '10px 20px', boxShadow: 'none' }}
            placeholder={'New Password'}
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.password && formik.errors.password}
            type={showNewPassword ? 'text' : 'password'}
          />
          <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>
            {formik.touched.password && formik.errors.password ? null : showNewPassword ? (
              <LuEye
                onClick={handletoggleNewPassword}
                style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
              />
            ) : (
              <LuEyeOff
                onClick={handletoggleNewPassword}
                style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
              />
            )}
          </div>
          <Form.Control.Feedback type='invalid'>{formik.errors.password}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3 position-relative'>
          {/* {hasLabel && <Form.Label>Confirm Password</Form.Label>} */}
          <Form.Control
            style={{ backgroundColor: '#FFFFFF', borderRadius: '50px', padding: '10px 20px', boxShadow: 'none' }}
            placeholder={'Confirm Password'}
            name='confirmPassword'
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
            type={showConfirmNewPassword ? 'text' : 'password'}
          />
          <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? null : showConfirmNewPassword ? (
              <LuEye
                onClick={handletoggleConfirmPassword}
                style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
              />
            ) : (
              <LuEyeOff
                onClick={handletoggleConfirmPassword}
                style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
              />
            )}
          </div>
          <Form.Control.Feedback type='invalid'>{formik.errors.confirmPassword}</Form.Control.Feedback>
        </Form.Group>

        <Button
          type='submit'
          className='w-100'
          disabled={!formik.values.password || !formik.values.confirmPassword || isLoading}
          style={{
            backgroundColor: '#682FCC',
            border: 'none',
            borderRadius: '50px',
            padding: '10px'
          }}
        >
          {isLoading ? <Spinner animation='border' size='sm' /> : 'Set password'}
        </Button>
        <Link
          className='fs-10 mt-3'
          to='/mtc/auth/login'
          style={{
            color: 'rgb(104, 47, 204)',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FaChevronLeft style={{ color: 'rgb(104, 47, 204)', fontSize: '12px' }} />
          <span>Back to login</span>
        </Link>
        <OptScreen show={modalShow} onHide={() => setModalShow(false)} ssoId={ssoId} email={userEmail} ssoEmail={ssoMail} isSsoSignUp={isSsoSignUp} />

      </Form>
      <div className="d-flex flex-column justify-content-center align-items-center mt-4 w-100">
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ flexGrow: 1, height: '1px', backgroundColor: 'rgb(215 215 215)', width: '100%' }}></div>
          <p style={{ margin: '0 10px', width: '90%', textAlign: 'center' }}>Connect with Google</p>
          <div style={{ flexGrow: 1, height: '1px', backgroundColor: 'rgb(215 215 215)', width: '100%' }}></div>
        </div>        <div className='mt-3'><FcGoogle onClick={() => login()} size={40} style={{ cursor: 'pointer' }} /></div>
      </div>
    </>
  )
}

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
}

export default PasswordResetForm
