import React, { useEffect, useState, useRef } from 'react'
import { Card, Placeholder } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDevOpportunities, updateOpportunities, getAllProjectsSettings } from '../../../../redux/projects/action'
import AssignedJobs from './AssignedJobs'
import { DragDropContext } from 'react-beautiful-dnd'
import is from 'is_js'
import { toast } from 'react-toastify'
import PageHeader from '../ProjectsTittle'
import _ from 'lodash'
import emptyScreen from '../../../../assets/dashboard/empty.svg'
import { fetchOpportunityEvents, userAddEditListion } from 'utils/socket'
import { getALLUnreadCount } from '../../../../redux/groupChat/action'

const Projects = () => {
  const dispatch = useDispatch()
  const { userDetails } = useSelector(state => state.login)
  const { isDevJobs, DevJobs, pipelines, isOpportunities, allProjectSettings } = useSelector(state => state.projects)
  const { savedPipleline } = useSelector(state => state.calenders)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const { usersList } = useSelector(state => state.users)
  const [updatedPipeline, setUpdatedPipeline] = useState(null)
  const [updatedDevJobs, setUpdatedDevJobs] = useState([])
  const containerRef = useRef(null)

  let selectedPipeLine = pipelines?.find(item => item?.id == savedPipleline?.pipelineId)

  useEffect(() => {
    if (selectedPipeLine && DevJobs) {
      const updatedStages = selectedPipeLine.stages.map(stage => ({
        ...stage,
        headOpportunities: DevJobs.filter(opportunity => opportunity?.pipelineStageId === stage?.id)
      }))

      // Remove objects at index 0 and 5
      // const filteredStages = updatedStages.filter((stage, index) => index !== 0 && index !== 5)
      const filteredStages = updatedStages.filter((stage, index) => index !== 5)


      setUpdatedPipeline({ ...selectedPipeLine, stages: filteredStages })
    }
  }, [selectedPipeLine, DevJobs])
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (savedPipleline && selectedPipeLine) {
      dispatch(
        getAllDevOpportunities({
          pipeline_id: savedPipleline?.pipelineId,
          limit: 100
        })
      )
      dispatch(getALLUnreadCount())

    }
  }, [savedPipleline, selectedPipeLine])

  useEffect(() => {
    if (DevJobs && selectedPipeLine) {
      const updatedJobs = DevJobs.filter(
        job => job?.pipelineStageId != selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 2]?.id
      )
      setUpdatedDevJobs(updatedJobs)
    }
  }, [DevJobs, selectedPipeLine])

  const getColumn = id => {
    return updatedPipeline?.stages?.find(item => item?.id == id)
  }

  const move = (source, destination) => {
    const sourceItemsClone = [...getColumn(source.droppableId).headOpportunities]
    const destItemsClone = [...getColumn(destination.droppableId).headOpportunities]
    const [removedItem] = sourceItemsClone.splice(source.index, 1)
    destItemsClone.splice(destination.index, 0, removedItem)

    return {
      updatedDestItems: destItemsClone,
      updatedSourceItems: sourceItemsClone
    }
  }

  const handleDragEnd = async result => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = getColumn(source.droppableId)
      const column = getColumn(source.droppableId)
    } else {
      const sourceColumn = getColumn(source.droppableId)
      const destColumn = getColumn(destination.droppableId)
      const movedItems = move(source, destination)
      let draggedCardId = result?.draggableId
      const index = movedItems?.updatedDestItems.findIndex(item => item.id === draggedCardId)
      const updatedMovedItems = {
        ...movedItems,
        updatedDestItems: movedItems.updatedDestItems.map(item =>
          item.id === draggedCardId ? { ...item, assignedTo: userDetails?.id } : item
        )
      }


      if (sourceColumn?.id === selectedPipeLine?.stages[0]?.id && destColumn?.id !== selectedPipeLine?.stages[1]?.id) {
        return toast.error(`You do not have permissions to move to ${destColumn?.name}`, {
          theme: 'colored',
          position: 'top-right'
        })
      } else if (sourceColumn?.id === selectedPipeLine?.stages[1]?.id && destColumn?.id !== selectedPipeLine?.stages[0]?.id) {
        return toast.error(`You do not have permissions to move to ${destColumn?.name}`, {
          theme: 'colored',
          position: 'top-right'
        })
      } else if (destColumn.id === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 1]?.id) {
        return toast.error(`You do not have permissions to move to ${destColumn?.name}`, {
          theme: 'colored',
          position: 'top-right'
        })
      } else if (destColumn.id === selectedPipeLine?.stages[1]?.id && sourceColumn?.id !== selectedPipeLine?.stages[0]?.id) {
        return toast.error(`You do not have permissions to move to ${destColumn?.name}`, {
          theme: 'colored',
          position: 'top-right'
        })
      } else if (destColumn.id === selectedPipeLine?.stages[0]?.id && sourceColumn?.id !== selectedPipeLine?.stages[1]?.id) {
        return toast.error(`You do not have permissions to move to ${destColumn?.name}`, {
          theme: 'colored',
          position: 'top-right'
        })
      }


      dispatch({
        type: 'DEV_UPDATE_DUAL_COLUMN',
        payload: {
          sourceColumn,
          updatedSourceItems: updatedMovedItems.updatedSourceItems,
          destColumn,
          updatedDestItems: updatedMovedItems.updatedDestItems
        }
      })

      dispatch({
        type: 'IS_CARD_MOVING_START',
        payload: movedItems?.updatedDestItems[index]?.id
      })
      let params = {
        opportunities_id: movedItems?.updatedDestItems[index]?.id,
        pipelineId: movedItems?.updatedDestItems[index]?.pipelineId,
        pipelineStageId: destColumn?.id
      }
      let res = await updateOpportunities(params)
      if (res?.data?.status_code == 200) {
        dispatch({
          type: 'IS_CARD_MOVING'
        })
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      } else {
        dispatch({
          type: 'IS_CARD_MOVING_ERROR'
        })
      }
    }
  }
  useEffect(() => {
    if (is.ipad()) {
      containerRef.current.classList.add('ipad')
    }

    if (is.mobile()) {
      containerRef.current.classList.add('mobile')
      if (is.safari()) {
        containerRef.current.classList.add('safari')
      }
      if (is.chrome()) {
        containerRef.current.classList.add('chrome')
      }
    }
  }, [])
  useEffect(() => {
    if (pipelines.length > 0) {
      fetchOpportunityEvents(dispatch)
      userAddEditListion(dispatch)
    }
  }, [pipelines])


  useEffect(() => {
    // Disable scrolling
    document.body.style.overflowY = 'hidden';

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <>
      <PageHeader title={selectedPipeLine?.name} className='mb-3 '></PageHeader>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className='kanban-container me-n3' ref={containerRef}>
          {isDevJobs ? (
            <>
              <div
                className=' d-flex align-items-center  justify-content-between p-4 gap-3'
                style={{ flexWrap: windowSize < 1000 ? 'wrap' : '' }}
              >
                {new Array(4).fill(null).map((item, index) => (
                  <Card
                    style={{
                      width: windowSize <= 500 ? '100%' : windowSize < 1000 ? '45%' : '28rem'
                    }}
                    key={index}
                  >
                    <Card.Body>
                      <Placeholder as={Card.Title} animation='glow'>
                        <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation='glow'>
                        <Placeholder xs={6} /> <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation='glow'>
                        <Placeholder xs={6} /> <Placeholder xs={6} />
                      </Placeholder>
                    </Card.Body>
                  </Card>
                ))}
              </div>

              <div
                className=' d-flex align-items-center justify-content-between p-4 gap-3 '
                style={{ flexWrap: windowSize < 1000 ? 'wrap' : '' }}
              >
                {new Array(4).fill(null).map((item, index) => (
                  <Card
                    style={{
                      width: windowSize <= 500 ? '100%' : windowSize < 1000 ? '45%' : '28rem'
                    }}
                    key={index}
                  >
                    <Card.Body>
                      <Placeholder as={Card.Title} animation='glow'>
                        <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation='glow'>
                        <Placeholder xs={6} /> <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation='glow'>
                        <Placeholder xs={6} /> <Placeholder xs={6} />
                      </Placeholder>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </>
          ) : (
            <>
              {updatedPipeline &&
                updatedPipeline?.stages?.map((kanbanColumnItem, index) => {
                  return (
                    <>
                      <AssignedJobs job={kanbanColumnItem} key={index} />
                    </>
                  )
                })}
              {updatedDevJobs?.length === 0 && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    height: '100%'
                  }}
                >
                  <img src={emptyScreen} alt='empty' />
                  <div style={{ textAlign: 'center' }}>
                    <h5 style={{ color: '#000', fontSize: '18px' }}>No Projects Found</h5>
                    <p style={{ color: '#808080', fontSize: '15px' }}>
                      Currently, here is no projects available for display.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </DragDropContext>
    </>
  )
}

export default Projects
