import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { EditClient } from '../../../../redux/client/action'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const ProfileSettings = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [isLoading, setIsLoading] = useState(false)
  const { userDetails } = useSelector(state => state.login)

  useEffect(() => {
    if (userDetails) {
      setFormData({
        firstName: userDetails?.firstName ?? '',
        lastName: userDetails?.lastName ?? '',
        email: userDetails?.email ?? '',
        number: userDetails?.number ?? '',
        name: userDetails?.name ?? ''
      })
    }
  }, [userDetails])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const [formData, setFormData] = useState({
    firstName: userDetails?.firstName ?? '',
    lastName: userDetails?.lastName ?? '',
    email: userDetails?.email ?? '',
    number: userDetails?.number ?? '',
    name: userDetails?.name ?? ''
  })
  const handleChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    let params = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      number: formData?.number,
      email: formData?.email
    }

    let res = await EditClient(params)
    if (res?.data?.status_code == 200 || res?.data?.status_code == 201) {
      setIsLoading(false)
      toast.success(res?.data?.message, {
        position: 'top-right',
        theme: 'colored'
      })
    } else {
      setIsLoading(false)
      toast.error(res?.data?.message, {
        position: 'top-right',
        theme: 'colored'
      })
    }
  }

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '8px 15px',
          flexWrap: 'wrap',
          gap: '10px'
        }}
      >
        {width > 425 && <p className='mb-0 fw-semibold fs-8'>Profile Settings</p>}
        {userDetails?.noOfProjectAssign && (
          <div className='d-flex align-items-center gap-3'>
            <div
              style={{ background: '#29a429', color: '#fff', padding: '5px 12px', borderRadius: '30px' }}
              className='d-flex align-items-center gap-2'
            >
              <p className='mb-0 fs-10'> Assigned projects :</p>
              <p className='mb-0 fs-9 text-light fw-bolder'>{userDetails?.assignProjects}</p>
            </div>
            <div
              style={{ background: '#682FCC', color: '#fff', padding: '5px 12px', borderRadius: '30px' }}
              className='d-flex align-items-center gap-2'
            >
              <p className='mb-0 fs-10'> Total assignable projects :</p>
              <p className='mb-0 fs-9 text-light fw-bolder'>{userDetails?.noOfProjectAssign}</p>
            </div>
          </div>
        )}
      </div>
      <Card.Body className='bg-body-tertiary'>
        <Form>
          <Row className='mb-3 g-3'>
            <Form.Group as={Col} lg={6} controlId='firstName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='First Name'
                value={formData?.firstName}
                name='firstName'
                onChange={event => handleChange(event.target.value, 'firstName')}
                disabled={userDetails?.userType == 3 ? false : true}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId='lastName'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Last Name'
                value={formData?.lastName}
                name='lastName'
                onChange={event => handleChange(event.target.value, 'lastName')}
                disabled={userDetails?.userType == 3 ? false : true}
              />
            </Form.Group>
          </Row>
          <Row className='mb-3 g-3'>
            <Form.Group as={Col} lg={6} controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                value={formData?.email}
                name='email'
                onChange={event => handleChange(event.target.value, 'email')}
                disabled={userDetails?.userType === 3 ? false : true}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId='phone'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                placeholder='Phone'
                value={formData?.number}
                name='phone'
                onChange={event => handleChange(event.target.value, 'number')}
                disabled={userDetails?.userType == 3 ? false : true}
              />
            </Form.Group>
          </Row>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
            <Form.Group
              className='mb-3'
              controlId='heading'
              style={{ width: userDetails?.userType === 3 ? '50%' : '100%' }}
            >
              <Form.Label>User Role</Form.Label>
              <Form.Control
                type='text'
                placeholder='Heading'
                value={userDetails?.userType === 1 ? 'Admin' : userDetails?.userType === 2 ? 'Developer' : userDetails?.userType === 4 ? "Sales" : 'Client'}
                name='heading'
                onChange={handleChange}
                disabled={true}
              />
            </Form.Group>
            {userDetails?.userType === 3 && (
              <Form.Group className='mb-3' controlId='heading' style={{ width: '50%' }}>
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Heading'
                  value={formData?.name}
                  name='heading'
                  onChange={event => handleChange(event.target.value, 'name')}
                  disabled={true}
                />
              </Form.Group>
            )}
          </div>

          {userDetails?.userType === 3 && (
            <div className='text-end'>
              <Button variant='primary' type='submit' onClick={e => handleSubmit(e)}>
                {isLoading ? <Spinner size='sm' /> : 'Update'}
              </Button>
            </div>
          )}
        </Form>
      </Card.Body>
    </Card>
  )
}

export default ProfileSettings
