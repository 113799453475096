export const apis = {
  check_user_exist: 'auth/check-user',
  login: 'auth/login',
  verifyOtp: 'auth/opt-verify',
  pipelines: 'pipeline/all',
  opportunities: 'opportunities/all',
  opportunitiesUpdate: 'opportunities/update',
  sso_login: 'auth/social-login',
  update_sso: 'auth/update-email',
  get_all_staffs: 'mtc-users/all',
  Add_User: 'user/add',
  edit_user: 'user/edit',
  get_all_users: 'user/all',
  get_user_detail: 'user/details',
  get_all_events: 'calendars/events',
  get_jobs_details: 'opportunities',
  get_clients_all: 'contacts/all',
  get_tags: 'tags/all',
  resend_otp: 'auth/resend-otp',
  add_remove_followers: 'opportunities/followers',
  add_remove_tags: 'contacts/tags',
  get_all_skills: 'skills/all',
  add_project_settings: 'project-settings/add',
  edit_project_settings: 'project-settings/edit',
  project_settings_detail: 'project-settings/details',
  get_all_project_settings: 'project-settings/all',
  assign_project: 'project-settings/assign-me',
  delete_opportunities: 'opportunities/delete',
  dashboard_client_count: 'client/count',
  //project settings assets
  projects_count: 'counts/all',
  add_projects_assets: 'project-settings/addAssets',
  calender_generate_link: 'calendars-settings/generate-calenderLink',
  delete_project_assets: 'project-settings/deleteAssets',
  edit_project_assets: 'project-settings/editAssets',
  client_add_docs: 'project-settings/addEditClientDocument',
  //calenders
  get_all_calenders: 'calendars/all',
  get_all_calenders_settings: 'calendars-settings/all',

  //sms and email
  get_all_conversations: 'conversations/all',
  get_conversation_messages: 'conversations/conversation-message',
  get_message_detail: 'conversations/message-detail',
  get_email_detail: 'conversations/email-detail',
  send_sms: 'conversations/conversation-message',
  calender_webhook: 'webhook',
  chat_uread_count: 'chat/unreadCont',
  //notes
  add_notes: 'project-settings/addNotes',

  upload_file: 'file-upload',
  uplaod_sms_email_files: 'conversations/messages-upload',

  //reset-password
  user_change_password: 'user/change-password',

  //forget-password
  user_forgot_password: 'user/forgot-password',

  //user-profile
  user_profile: 'user/profile',
  // user/reset-password
  user_reset_password: 'user/reset-password',

  //globel settinfs
  get_saved_pipeline: 'global-settings/all',
  update_pipeline: 'global-settings/addUpdate',
  update_calenders_settings: 'calendars-settings/add-update',
  disable_2FA_Auth: 'auth/two-step-verify',

  //user delete
  user_delete: 'user/delete',
  //user Status Change
  user_status_change: 'user/change-status',

  //sales user
  add_sales_user: "sales/add",
  edit_sales_user: 'sales/edit',

  //group chat apis
  group_messages_list: 'chat/messages',
  //add skill
  add_skill: 'skills/add',
  //delete skill
  delete_skill: 'skills/delete',
  //Client list
  get_all_client_list: 'client/all',
  //add project bugs

  add_project_bugs: 'project-bugs/add',
  project_bugs_list: 'project-bugs/all',
  project_bug_status_change: 'project-bugs/change-status',
  //client_edit
  client_edit: 'client/edit',
  get_all_notifications: 'notifications/all',
  read_notifications: 'notifications/read-unread',
  notification_settings: 'notifications/settings',
  notification_settings_edit: 'notifications/settings/edit',
  logout: 'auth/logout'
}
