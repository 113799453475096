import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { getRandomColor } from 'utils/constants';

const Avatar = ({
    size,
    rounded,
    name,
    emoji,
    className,
    mediaClass,
    isExact,
}) => {
    const classNames = ['avatar', `avatar-${size}`, className].join(' ');
    const mediaClasses = [
        rounded ? `rounded-${rounded}` : 'rounded',
        mediaClass
    ].join(' ');
    const randomColor = useMemo(() => getRandomColor(), []);

    const getAvatar = () => {

        if (name) {
            return (
                <div className={`avatar-name ${mediaClasses}`} style={{ background: randomColor }}>
                    <span>{isExact ? name : name.match(/\b\w/g).join('')}</span>
                </div>
            );
        }

        return (
            <div className={`avatar-emoji ${mediaClasses}`}>
                <span role="img" aria-label="Emoji">
                    {emoji}
                </span>
            </div>
        );
    };

    return <div className={classNames}>{getAvatar()}</div>;
};

export const AvatarGroup = ({ children, dense, className }) => {
    return (
        <div
            className={classNames(className, 'avatar-group', {
                'avatar-group-dense': dense
            })}
        >
            {children}
        </div>
    );
};

Avatar.propTypes = {
    size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
    rounded: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string,
    emoji: PropTypes.string,
    className: PropTypes.string,
    mediaClass: PropTypes.string,
    isExact: PropTypes.bool,
    icon: PropTypes.string
};

Avatar.defaultProps = {
    size: 'xl',
    rounded: 'circle',
    emoji: '😊',
    isExact: false
};

AvatarGroup.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    dense: PropTypes.bool
};

export default Avatar;
