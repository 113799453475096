import React from 'react'
import { isAdmin } from 'utils/constants'
import { BsInfoCircle } from 'react-icons/bs'

const KanbanColumnHeader = ({ id, title, itemCount }) => {
  let color = '#000'
  if (title === 'Discovery') {
    color = '#006699'
  } else if (title === 'Post Discovery') {
    color = '#ff9900'
  } else if (title === 'In-Progress') {
    color = '#3FB0FF'
  } else if (title === 'In-Review') {
    color = '#cc66ff'
  } else if (title === 'Complete') {
    color = '#3EC852'
  } else if (title === 'Maintainance') {
    color = '#ff0000'
  } else {
    color = '#000'
  }

  return (
    <div className='kanban-column-header' style={{ borderTop: `2px solid ${color}`, background: '#fff' }}>
      <h5 className='fs-9 mb-0'>
        {title === 'Maintainance' && !isAdmin() ? 'Bug Tickets' : title} <span className='text-500'>({itemCount})</span>
      </h5>
      <BsInfoCircle style={{ cursor: 'pointer', fontSize: '13px', color: '#575757' }} />
    </div>
  )
}

export default KanbanColumnHeader
