import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LoginForm from 'components/authentication/LoginForm'
import AuthCardLayout from 'layouts/AuthCardLayout'
import VerifyEmail from './VirfyEmail'
const Login = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [email, setEmail] = useState('')
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <AuthCardLayout
      leftSideContent={
        <p className='text-white'>
          Don't have an account?
          <br />
          <Link className='text-white text-decoration-underline' to='https://mytradingcoder.com/' target='_blank'>
            Get started!
          </Link>
        </p>
      }
    >
      {isValidEmail ?
        <>

          <h1 style={{ color: '#000', fontWeight: '600', fontSize: width > 769 ? '30px' : '35px' }}>
            Welcome
            <br /> Back
          </h1>
          <p style={{ color: '#808080', fontSize: '15px', fontWeight: '500', marginBottom: '15px' }}>Sign in to continue</p>
          <LoginForm email={email.toLowerCase()} layout='card' hasLabel />

        </> :
        <>
          <h1 style={{ color: '#000', fontWeight: '600', fontSize: width > 769 ? '30px' : '35px' }}>
            Welcome
            <br /> Back
          </h1>
          <p style={{ color: '#808080', fontSize: '15px', fontWeight: '500', marginBottom: '15px' }}>Please verify your email first</p>
          <VerifyEmail setEmail={setEmail} setIsValidEmail={setIsValidEmail} layout='card' hasLabel />
        </>

      }

    </AuthCardLayout>
  )
}

export default Login
