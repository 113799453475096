import React from 'react'
import { Button } from 'react-bootstrap'

const Appointments = () => {
    return (
        <div className='mt-3'>

        </div>
    )
}

export default Appointments