import React, { useEffect, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/plugins/video'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import Download from 'yet-another-react-lightbox/plugins/download'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/plugins/counter.css'
import 'yet-another-react-lightbox/styles.css'
import { Modal } from 'react-bootstrap'
import { useRef } from 'react'
import { IoMdClose } from 'react-icons/io'

const DocumentLightBox = ({ isOpenLightBox, toggleHandler, documentImage }) => {
  const [isPdf, setIsPdf] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  let transformedData = null

  transformedData = [
    {
      src: documentImage,
      description: ''
    }
  ]
  useEffect(() => {
    if (documentImage && !['jpeg', 'png', 'jpg', 'webp'].some(ext => documentImage?.toLowerCase()?.endsWith(ext))) {
      setIsPdf(true)
      setIsOpen(true)
    }
  }, [documentImage])

  const handleClose = () => {
    toggleHandler()
    setIsPdf(false)
    setIsOpen(false)
  }

  const thumbnailsRef = useRef(null)
  const fullscreenRef = useRef(null)
  const slideshowRef = useRef(null)
  const zoomRef = useRef(null)

  return (
    <>
      {!isPdf ? (
        <Lightbox
          plugins={[Video, Thumbnails, Counter, Download, Fullscreen, Slideshow, Zoom]}
          thumbnails={{ ref: thumbnailsRef }}
          zoom={{ ref: zoomRef }}
          slideshow={{ ref: slideshowRef }}
          fullscreen={{ ref: fullscreenRef }}
          counter={{ container: { style: { top: 0 } } }}
          open={isOpenLightBox}
          close={handleClose}
          on={{
            click: () => {
              ;(thumbnailsRef.current?.visible ? thumbnailsRef.current?.hide : thumbnailsRef.current?.show)?.()
              fullscreenRef.current?.enter()
              ;(slideshowRef.current?.playing ? slideshowRef.current?.pause : slideshowRef.current?.play)?.()
              zoomRef.current?.zoomIn()
            }
          }}
          slides={transformedData}

          // ...
        />
      ) : (
        <Modal fullscreen={true} show={isOpen} onHide={handleClose}>
          <div style={{ height: '100%', width: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                backgroundColor: '#323639',
                paddingRight: '13px',
                height: '40px',
                padding: '5px 20px'
              }}
            >
              <IoMdClose onClick={handleClose} size={25} style={{ color: 'white', cursor: 'pointer' }} />
            </div>
            {['doc', 'docx', 'xls', 'xlsx'].some(ext => documentImage?.toLowerCase()?.endsWith(ext)) ? (
              <>
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(documentImage)}`}
                  width='100%'
                  height='100%'
                  style={{
                    border: 'none'
                  }}
                  allowFullScreen
                ></iframe>
              </>
            ) : (
              <iframe
                src={documentImage}
                width='100%'
                height='98%'
                style={{
                  border: 'none'
                }}
                allowFullScreen
              ></iframe>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

export default DocumentLightBox
