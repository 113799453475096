import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, ListGroup, Spinner } from 'react-bootstrap';
import EmptyImg from 'assets/dashboard/empty.svg'
import FalconCardHeader from 'components/common/FalconCardHeader';
import NotificationComponent from './NotificationComponent';
import { getAllNotifications, getOldNotifications } from '../../../redux/notifications/action'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Encrypt } from 'utils/constants';
import { readNotification } from '../../../redux/notifications/action'
import { Loader } from 'react-bootstrap-typeahead';

const notifications = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [page, setPage] = useState(1)
    const { savedPipleline } = useSelector(state => state.calenders)

    const { notificationsList, isNotifications, moreNotifications, isOldNotifications } = useSelector((state) => state.notifications)
    const markAsRead = async (item) => {
        await readNotification({ _id: item?._id })

        dispatch({
            type: "READ_SINGLE_NOTIFICATION",
            payload: { notification_id: item?._id }
        })
        switch (item?.request_type) {
            case 1:
                navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
                break;
            case 2:
                //not redirect
                break;
            case 3:
                dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: 'Notes' } })
                navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
                break;
            case 4:
                dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: 'Documents' } })
                navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
                break;
            case 5:
                dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: 'Videos' } })
                navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
                break;

            case 6:
                //not redirect
                break;
            case 7:
                //not redirect
                break;
            case 8:
                //not redirect
                break;
            case 9:
                dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: 'Client Documents' } })
                navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
                break;
            case 10:
                //not redirect
                break;
            case 11:
                navigate(`/app/projects/${Encrypt(item?.request_id)}/detail?isOpen=true`)
                break;
            case 12:
                navigate(`/app/projects/${Encrypt(item?.request_id)}/detail?isOpen=true`)
                break;
            case 13:
                navigate(`/app/projects`)
                break;
            case 14:
                navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
                break;

        }
    };

    useEffect(() => {
        if (savedPipleline) {
            dispatch(getAllNotifications({ page: page, limit: 20, pipelineId: savedPipleline?.pipelineId }))
            setPage(page + 1)
        }
    }, [savedPipleline])

    const markAllRead = () => {
        dispatch({ type: "READ_ALL_NOTIFICATIONS" })
        readNotification()
    }
    const handleLoadMore = () => {
        dispatch(getOldNotifications({ page: page, limit: 20, pipelineId: savedPipleline?.pipelineId }))
        setPage(page + 1)
    }


    return (
        <Card
            className="dropdown-menu-notification dropdown-menu-end shadow"
            style={{ maxWidth: '100%' }}
        >
            <FalconCardHeader
                className="card-header"
                title="Notifications"
                titleTag="h4"
                light={false}
                endEl={
                    <Link
                        className="card-link fw-normal"
                        to="#!"
                        onClick={markAllRead}
                    >
                        Mark all as read
                    </Link>
                }
            />
            {moreNotifications &&
                <div className="d-flex justify-content-center mb-3">
                    {isOldNotifications ?
                        <Loader /> :
                        <p onClick={handleLoadMore} className="fw-bolder text-decoration-underline cursor-pointer">
                            Load More
                        </p>
                    }
                </div>
            }

            <ListGroup
                variant="flush"
                className="fw-normal fs-10 scrollbar"
                style={{ maxHeight: '80vh', minHeight: '80vh' }}
            >
                {isNotifications ?
                    <div style={{ width: '100%' }}>
                        <div
                            style={{
                                height: '80vh',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Spinner />
                        </div>
                    </div>
                    : <>

                        {notificationsList.length > 0 ?
                            notificationsList.map(notification => (
                                <div className="mx-3" key={notification._id} >
                                    <ListGroup.Item key={notification._id} onClick={() => markAsRead(notification)} >
                                        <NotificationComponent notification={notification} />

                                    </ListGroup.Item>
                                </div>

                            )) : <div style={{ width: '100%' }}>
                                <div
                                    style={{
                                        height: '80vh',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <img src={EmptyImg} alt='empty' />
                                    <p className='fw-bolder mt-2 mb-1'>No Available Notifications</p>
                                    <p className='fs-10 mt-2 mb-1'>Currently here is no notifications for display</p>
                                </div>
                            </div>}
                    </>
                }
            </ListGroup>

        </Card>
    )
}

export default notifications