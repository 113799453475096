export const ReducerType = {
  // basic Details
  GET_ALL_DEV: 'GET_ALL_DEV',
  GET_ALL_CONTACTS_DEV_ERROR: 'GET_ALL_CONTACTS_DEV_ERROR',
  GET_ALL_USERS_ACTION_START: 'GET_ALL_USERS_ACTION_START',
  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_ALL_USERS_ERROR: 'GET_ALL_USERS_ERROR',
  GET_ALL_USERS_LIST: "GET_ALL_USERS_LIST",
  GET_ALL_JOBS_DETAIL_START: 'GET_ALL_JOBS_DETAIL_START',
  GET_ALL_JOBS_DETAIL: 'GET_ALL_JOBS_DETAIL',
  GET_ALL_JOBS_DETAIL_ERROR: 'GET_ALL_JOBS_DETAIL_ERROR',

  GET_ALL_CLIENTS_START: 'GET_ALL_CLIENTS_START',
  GET_ALL_CLIENTS: 'GET_ALL_CLIENTS',
  GET_ALL_CLIENTS_ERROR: 'GET_ALL_CLIENTS_ERROR',

  GET_ALL_TAGS_START: 'GET_ALL_TAGS_START',
  GET_ALL_TAGS: 'GET_ALL_TAGS',
  GET_ALL_TAGS_ERROR: 'GET_ALL_TAGS_ERROR',

  GET_ALL_SKILLS: 'GET_ALL_SKILLS',

  USER_UPDATE_STATUS: 'USER_UPDATE_STATUS',
  GET_ALL_SKILL_START: 'GET_ALL_SKILL_START',
  DELETE_SKILL_ACTION: 'DELETE_SKILL_ACTION',
  CLEAR_JOB_DETAIL: 'CLEAR_JOB_DETAIL'
}
