import React from 'react'
import SettingIcon from 'assets/dashboard/setting-icon.svg'
import { useNavigate } from 'react-router-dom'

const Settings = ({ data, radius }) => {
  const navigate = useNavigate()
  return (
    <div className='profile-tile p-3 pt-4 cursor-pointer' onClick={() => navigate('/app/settings')}>
      <div className='d-flex flex-column'>
        <img src={SettingIcon} alt='dmd' style={{ width: '64px', height: '64px' }} />
        <p style={{ fontSize: '20px', color: '#fff', fontWeight: '600', marginTop: '20px', marginBottom: '0px' }}>
          Portal Settings
        </p>
        <p style={{ fontSize: '30px', color: '#fff', fontWeight: 'bold', marginTop: '0px', marginBottom: '4px' }}></p>
      </div>
    </div>
  )
}

export default Settings
