import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import KanbanColumnHeader from './KanbanColumnHeader'
import TaskCard from './TaskCard'
import classNames from 'classnames'
import StrictModeDroppable from './StrictModeDroppable'
import { useDispatch, useSelector } from 'react-redux'
import { getScrollOpportunities } from '../../../redux/projects/action'
import { Spinner } from 'react-bootstrap'

const KanbanColumn = ({ kanbanColumnItem, OpportunitiesAllStagesArr, savedPipleline }) => {
  const { id, name, headOpportunities } = kanbanColumnItem
  const { isStageOpportunities, pipelines } = useSelector(state => state.projects)
  const [showForm, setShowForm] = useState(false)
  const formViewRef = useRef(null)
  const containerRef = useRef(null)
  const [scrollingId, setScrollingId] = useState('')
  const dispatch = useDispatch()
  let selectedPipeline = pipelines?.find(item => item?.id === savedPipleline?.pipelineId)


  useEffect(() => {
    const timeout = setTimeout(() => {
      formViewRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)

    return clearTimeout(timeout)
  }, [showForm])
  useEffect(() => {
    const handleScroll = event => {
      const scrolledElementId = event.target.id
      setScrollingId(scrolledElementId)
      let selectedStage = OpportunitiesAllStagesArr?.find(item => item?.meta?.pipeline_stage_id === scrolledElementId)

      if (containerRef?.current) {
        const { scrollTop, clientHeight, scrollHeight } = containerRef?.current
        if (
          scrollTop + clientHeight >= scrollHeight - 1 &&
          !isStageOpportunities &&
          selectedStage?.meta?.pipeline_stage_id &&
          selectedStage?.meta?.startAfterId
        ) {
          dispatch(
            getScrollOpportunities({
              pipeline_id: savedPipleline?.pipelineId,
              limit: 20,
              startAfter: selectedStage?.meta?.startAfter,
              startAfterId: selectedStage?.meta?.startAfterId,
              pipeline_stage_id: selectedStage?.meta?.pipeline_stage_id
            })
          )
          console.log('Reached near end of scroll. Call your API here.')
        }
      }
    }
    containerRef?.current?.addEventListener('scroll', handleScroll)

    return () => {
      containerRef?.current?.removeEventListener('scroll', handleScroll)
      setTimeout(() => setScrollingId(''), 1000)
    }
  }, [kanbanColumnItem, isStageOpportunities])

  const draggableColor = (id) => {
    let color = '#000'
    if (id === selectedPipeline?.stages[0]?.id) {
      color = '#006699'
    } else if (id === selectedPipeline?.stages[1]?.id) {
      color = '#ff9900'
    } else if (id === selectedPipeline?.stages[2]?.id) {
      color = '#3FB0FF'
    } else if (id === selectedPipeline?.stages[3]?.id) {
      color = '#cc66ff'
    } else if (id === selectedPipeline?.stages[4]?.id) {
      color = '#3EC852'
    } else if (id === selectedPipeline?.stages[5]?.id) {
      color = '#ff0000'
    } else {
      color = '#000'
    }
    return color
  }




  return (
    <div className={classNames('kanban-column', { 'form-added': showForm })}>
      <KanbanColumnHeader id={id} title={name} itemCount={headOpportunities ? headOpportunities?.length : 0} selectedPipeline={selectedPipeline} />
      <StrictModeDroppable droppableId={`${id}`} type='KANBAN'>
        {(provided, snapshot) => {
          return (
            <>
              <div
                ref={ref => {
                  containerRef.current = ref
                  provided.innerRef(ref) // Maintain the existing reference
                }}
                {...provided.droppableProps}
                id={`${id}`}
                className='kanban-items-container scrollbar'
                style={{
                  background: 'transparent', borderLeft: snapshot.isDraggingOver ? `2px solid ${draggableColor(provided?.droppableProps['data-rbd-droppable-id'])}` : 'none',
                  borderBottom: snapshot.isDraggingOver ? `2px solid ${draggableColor(provided?.droppableProps['data-rbd-droppable-id'])}` : 'none',
                  borderRight: snapshot.isDraggingOver ? `2px solid ${draggableColor(provided?.droppableProps['data-rbd-droppable-id'])}` : 'none',
                  borderRadius: snapshot.isDraggingOver ? '0px 0px 8px 8px' : '',
                }}
              >
                {headOpportunities &&
                  headOpportunities?.map((task, index) => <TaskCard key={task?.id} index={index} task={task} />)}

                {provided.placeholder}
                <div ref={formViewRef}></div>
              </div>
            </>
          )
        }}
      </StrictModeDroppable>
      {isStageOpportunities && scrollingId === kanbanColumnItem?.id && (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner size='sm' />
        </div>
      )}
    </div>
  )
}

KanbanColumn.propTypes = {
  kanbanColumnItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string,
    items: PropTypes.array
  })
}

export default KanbanColumn
