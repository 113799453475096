import React, { useState, useEffect, useMemo } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'
import { getAllDevelopers, AddUser, getAllUsers, getAllSkills, EditUser, AddSalesUser, EditSalesUser } from '../../../redux/users/action'
import { useDispatch, useSelector } from 'react-redux'
import { getRandomColor } from 'utils/constants'
import { toast } from 'react-toastify'
import * as _ from 'lodash'
import Select from 'react-select'
import { getAllCalenders, getSettingsCalenders } from '../../../redux/calender/action'
import { EditorState, Modifier, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import { userAddEdit } from 'utils/socket'

function AddEdit({ open, handleClose, userDetail, selectedFilter }) {
  const dispatch = useDispatch()
  const { develoeprs, allUsers, skills } = useSelector(state => state.users)
  const { isCalenders, calenders, savedCalenders } = useSelector(state => state.calenders)
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const randomColor = useMemo(() => getRandomColor(), [])
  const [editorUpIntro, setEditorUpIntro] = useState(() => EditorState.createEmpty())
  const [editorUpIntroSms, setEditorUpIntroSms] = useState(() => EditorState.createEmpty())
  const [openSkills, setOpenSkills] = useState(false)
  const [openCalender, setOpenCalender] = useState(false)
  const [selectedOption, setSelectedOption] = useState('developer');


  const handleChangeType = (event) => {

    setSelectedOption(event.target.value);


  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      zIndex: openCalender ? 99 : 0 // Change the background color of the control
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: openCalender ? 99 : 0 // Change the background color of the dropdown menu
    })
  }

  const customCssStyle = {
    control: (provided, state) => ({
      ...provided,
      zIndex: openSkills ? 99 : 0 // Change the background color of the control
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: openSkills ? 99 : 0 // Change the background color of the dropdown menu
    })
  }

  const handleEditorChange = editorState => {
    setEditorUpIntro(editorState)
  }

  const handleEditorChangeSms = editorState => {
    setEditorUpIntroSms(editorState)
  }

  useEffect(() => {
    if (open) {
      dispatch(getAllDevelopers({ limit: 100 }))
      dispatch(getAllSkills())
      dispatch(getAllCalenders())
      // dispatch(getSettingsCalenders())
    }
  }, [open])

  useEffect(() => {
    if (develoeprs?.length > 0) {
      const result = _.differenceWith(develoeprs, allUsers, (obj1, obj2) => obj1.id === obj2.id)
      setOptions(result)
    } else {
      setOptions([])
    }
  }, [develoeprs])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      phone: '',
      noOfProjectAssign: 10000,
      skills: [],
      calendars: []
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      username: Yup.string().required('Required'),
      email: Yup.string().required('Required'),
      noOfProjectAssign: userDetail?.userType == 2 ? Yup.number()
        .min(userDetail?.assignProjects || 0, `Number of assignable projects must be at least equal of Assigned Projects (${userDetail?.assignProjects})`)
        .required('Number of assignable projects is required') : ""
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true)

      const contentState = editorUpIntro.getCurrentContent()
      const htmlContent = draftToHtml(convertToRaw(contentState))
      const modifiedHtmlContent = `<div style="margin-top: 10px;">${htmlContent}</div>`
      const contentStateSms = editorUpIntroSms.getCurrentContent()
      const htmlContentSms = draftToHtml(convertToRaw(contentStateSms))
      const plainTextSms = htmlContentSms.replace(/<\/?[^>]+(>|$)/g, '').replace(/\n/g, '')
      const modifiedHtmlContentSms = plainTextSms
      let params = {
        id: selectedContact[0]?.id,
        name: values?.username,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        noOfProjectAssign: values?.noOfProjectAssign,
        skills: values?.skills,
        permissions: selectedContact[0]?.permissions,
        roles: selectedContact[0]?.roles,
        scopes: selectedContact[0]?.scopes,
        scopesAssignedToOnly: selectedContact[0]?.scopesAssignedToOnly,
        calendars: values?.calendars,
        emailSignature: modifiedHtmlContent,
        smsSignature: modifiedHtmlContentSms
      }
      if (values.phone) {
        params.number = values?.phone
      }
      if (userDetail) {
        let res = null
        delete params.id
        params._id = userDetail?._id
        if (selectedOption === 'developer') {
          params.status = userDetail?.status
          res = await EditUser(params)
        } else {
          delete params.scopes
          delete params.noOfProjectAssign
          delete params.permissions
          delete params.roles
          delete params.scopesAssignedToOnly
          delete params.skills
          res = await EditSalesUser(params)
        }

        if (res?.data?.status_code == 200 || res?.data?.status_code == 201) {
          userAddEdit({ isUserAction: true })
          if (selectedFilter) {
            dispatch(getAllUsers({ type: selectedFilter }))
          } else {
            dispatch(getAllUsers())

          }
          formik.resetForm()
          setSelectedContact(null)
          setIsLoading(false)
          setEditorUpIntro(EditorState.createEmpty())
          setEditorUpIntroSms(EditorState.createEmpty())
          handleClose()
          toast.success(res?.data?.message, {
            position: 'top-right',
            theme: 'colored'
          })
        } else {
          setIsLoading(false)
          toast.error(res?.data?.data?.msg, {
            position: 'top-right',
            theme: 'colored'
          })
        }
      } else {
        let res = null

        if (selectedOption === 'developer') {
          res = await AddUser(params)

        } else {
          delete params.scopes
          delete params.noOfProjectAssign
          delete params.permissions
          delete params.roles
          delete params.scopesAssignedToOnly
          delete params.skills
          res = await AddSalesUser(params)
        } if (res?.data?.status_code == 200 || res?.data?.status_code == 201) {
          userAddEdit({ isUserAction: true })

          if (selectedFilter) {
            dispatch(getAllUsers({ type: selectedFilter }))
          } else {
            dispatch(getAllUsers())

          }
          dispatch(getAllDevelopers({ limit: 100 }))

          formik.resetForm()
          setSelectedContact(null)
          setIsLoading(false)
          setEditorUpIntro(EditorState.createEmpty())
          setEditorUpIntroSms(EditorState.createEmpty())
          handleClose()
          toast.success(res?.data?.message, {
            position: 'top-right',
            theme: 'colored'
          })
        } else {
          setIsLoading(false)
          toast.error(res?.data?.message, {
            position: 'top-right',
            theme: 'colored'
          })
        }
      }
    }
  })
  const handleChange = selected => {
    if (selected?.length > 0) {
      let obj = selected[0]
        ; (formik.values.firstName = obj?.firstName),
          (formik.values.lastName = obj?.lastName),
          (formik.values.email = obj?.email),
          (formik.values.username = obj?.name),
          (formik.values.phone = obj?.phone)
    } else {
      ; (formik.values.firstName = ''),
        (formik.values.lastName = ''),
        (formik.values.email = ''),
        (formik.values.username = ''),
        (formik.values.phone = '')
    }
  }

  useEffect(() => {
    if (userDetail) {
      if (userDetail?.smsSignature) {
        const blocksFromHTML = convertFromHTML(userDetail?.smsSignature)
        const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        const initialEditorState = EditorState.createWithContent(contentState)
        setEditorUpIntroSms(initialEditorState)
      } else {
        setEditorUpIntroSms(EditorState.createEmpty())
      }

      if (userDetail?.emailSignature) {
        const blocksFromHTML = convertFromHTML(userDetail?.emailSignature)
        const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        const initialEditorState = EditorState.createWithContent(contentState)
        setEditorUpIntro(initialEditorState)
      } else {
        setEditorUpIntro(EditorState.createEmpty())
      }

      setSelectedContact([userDetail])
        ; (formik.values.firstName = userDetail?.firstName),
          (formik.values.lastName = userDetail?.lastName),
          (formik.values.email = userDetail?.email),
          (formik.values.username = userDetail?.name),
          (formik.values.phone = userDetail?.number),
          (formik.values.skills = userDetail?.skills),
          (formik.values.noOfProjectAssign = userDetail?.noOfProjectAssign),
          (formik.values.calendars = userDetail?.calendars)

      setSelectedOption(userDetail?.userType === 2 ? "developer" : 'sales')
    } else {
      setSelectedOption("developer")

      setSelectedContact([])
        ; (formik.values.firstName = ''),
          (formik.values.lastName = ''),
          (formik.values.email = ''),
          (formik.values.username = ''),
          (formik.values.phone = ''),
          (formik.values.skills = []),
          (formik.values.noOfProjectAssign = 10000),
          (formik.values.calendars = [])
    }
  }, [userDetail])

  const handleCloseAddEdit = () => {
    setOpenSkills(false)
    setEditorUpIntro(EditorState.createEmpty())
    setEditorUpIntroSms(EditorState.createEmpty())
    handleClose()
    formik.resetForm()
  }

  // const matchingCalendars = calenders?.filter(calendar =>
  //     savedCalenders?.some(savedCalendar => savedCalendar?.calendarId === calendar?.id)
  // );
  return (
    <Offcanvas style={{ width: '500px' }} placement={'end'} show={open} onHide={handleCloseAddEdit}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{userDetail ? 'Edit' : 'Create'} User</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom02'>
              <Form.Label>Select Contact</Form.Label>
              <Typeahead
                clearButton
                disabled={userDetail ? true : false}
                id='basic-example'
                onChange={option => {
                  setSelectedContact(option), handleChange(option)
                }}
                options={options}
                labelKey='name'
                placeholder='Choose a contact...'
                selected={selectedContact}
                renderMenuItemChildren={option => (
                  <div key={option.id}>
                    <div className='d-flex align-items-center gap-2'>
                      <div
                        className='rounded-circle  d-flex justify-content-center align-items-center '
                        style={{
                          width: '30px',
                          height: '30px',
                          backgroundColor: randomColor,
                          color: '#fff'
                        }}
                        alt='Avatar'
                      >
                        <span style={{ textTransform: 'capitalize' }}>{option?.firstName?.charAt(0)}</span>
                      </div>
                      <div className='d-flex flex-column '>
                        <p className='mb-0'>{option?.name}</p>
                        <p className='mb-0 fs-11'>{option?.email}</p>
                      </div>
                    </div>
                  </div>
                )}
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.lastName}</Form.Control.Feedback>
            </Form.Group>
            <div className='d-flex mt-3 align-items-center justify-content-between'>
              <Form.Label>Select Type</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Developer"
                  type="radio"
                  disabled={userDetail ? true : false}
                  id="developer-radio"
                  name="radio-group"
                  value="developer"
                  checked={selectedOption === 'developer'}
                  onChange={handleChangeType}
                />
                {' '}
                {/* Adding space between buttons */}
                <Form.Check
                  inline
                  label="Sales"
                  type="radio"
                  id="sales-radio"
                  name="radio-group"
                  disabled={userDetail ? true : false}

                  value="sales"
                  checked={selectedOption === 'sales'}
                  onChange={handleChangeType}
                />
              </div>

            </div>


            <Form.Group as={Col} md='12' controlId='validationCustom01'>
              <Form.Label>First name</Form.Label>
              <Form.Control
                type='text'
                name='firstName'
                disabled
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik?.touched?.firstName && formik?.errors?.firstName}
                placeholder='First name'
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.firstName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='12' controlId='validationCustom02'>
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type='text'
                name='lastName'
                disabled
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik?.touched?.lastName && formik?.errors?.lastName}
                placeholder='Last name'
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.lastName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='12' controlId='validationCustomUsername'>
              <Form.Label>Username</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id='inputGroupPrepend'>@</InputGroup.Text>
                <Form.Control
                  type='text'
                  name='username'
                  disabled
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik?.touched?.username && formik?.errors?.username}
                  placeholder='Username'
                  aria-describedby='inputGroupPrepend'
                />
                <Form.Control.Feedback type='invalid'>{formik?.errors?.username}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom03'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='text'
                name='email'
                disabled
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik?.touched?.email && formik?.errors?.email}
                placeholder='email'
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.email}</Form.Control.Feedback>
            </Form.Group>
            {/* Repeat the pattern for other form fields */}
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom03'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                name='phone'
                disabled
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik?.touched?.phone && formik?.errors?.phone}
                placeholder='Phone'
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.phone}</Form.Control.Feedback>
            </Form.Group>
            {/* Repeat the pattern for other form fields */}
          </Row>

          {selectedOption === 'developer' &&

            <>


              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='validationCustom03'>
                  <Form.Label>No. of assignable projects</Form.Label>
                  <Form.Control
                    type='number'
                    name='noOfProjectAssign'
                    min='0'
                    value={formik.values.noOfProjectAssign}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik?.touched?.noOfProjectAssign && formik?.errors?.noOfProjectAssign}
                    placeholder='No. of Assign Projects'
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Control.Feedback type='invalid'>{formik?.errors?.noOfProjectAssign}</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group
                  as={Col}
                  md='12'
                  controlId='validationCustom03'
                  onClick={() => {
                    setOpenCalender(false), setOpenSkills(true)
                  }}
                >
                  <Form.Label>Select Skills</Form.Label>
                  <Select
                    isMulti
                    name='skills'
                    getOptionLabel={option => `${option?.name}`}
                    getOptionValue={option => option?._id}
                    isClearable={true}
                    placeholder='Unassigned'
                    options={skills}
                    value={skills.filter(skill => formik.values.skills?.includes(skill?._id))}
                    onChange={selectedOptions => {
                      const selectedIds = selectedOptions.map(option => option._id)
                      formik.setFieldValue('skills', selectedIds)
                    }}
                    onBlur={formik.handleBlur('skills')}
                    styles={customCssStyle}
                  />
                </Form.Group>
              </Row>
            </>}
          <Row className='mb-3'>
            <Form.Group
              as={Col}
              md='12'
              controlId='validationCustom03'
              onClick={() => {
                setOpenCalender(true), setOpenSkills(false)
              }}
            >
              <Form.Label>Add Calendar's</Form.Label>
              <Select
                isMulti
                name='calendars'
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
                isClearable={true}
                placeholder='choose calenders'
                options={calenders ?? []}
                value={calenders.filter(skill => formik.values.calendars?.includes(skill?.id))}
                onChange={selectedOptions => {
                  const selectedIds = selectedOptions.map(option => option.id)
                  formik.setFieldValue('calendars', selectedIds)
                }}
                onBlur={formik.handleBlur('calendars')}
                styles={customStyles}
              />
            </Form.Group>
          </Row>

          <Row className='mb-3 '>
            <Form.Group as={Col} md='12' controlId='validationCustom03'>
              <Form.Label>Add SMS Signature</Form.Label>
              <div className='border' style={{ margin: '0px 0px', padding: '5px' }}>
                <Editor
                  editorState={editorUpIntroSms}
                  onEditorStateChange={handleEditorChangeSms}
                  placeholder='Add SMS Signature'
                  wrapperClassName='wrapper-class'
                  editorClassName='editor-class'
                  toolbarClassName='toolbar-class'
                  toolbar={{
                    options: ['inline'],
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough']
                    },

                    list: {
                      inDropdown: false,
                      options: ['unordered', 'ordered']
                    }
                  }}
                  toolbarHidden={false}
                />
              </div>
            </Form.Group>
          </Row>

          <Row className='mb-3 '>
            <Form.Group as={Col} md='12' controlId='validationCustom03'>
              <Form.Label>Add Email Signature</Form.Label>
              <div className='border' style={{ margin: '0px 0px', padding: '5px' }}>
                <Editor
                  editorState={editorUpIntro}
                  onEditorStateChange={handleEditorChange}
                  placeholder='Add Email Signature'
                  wrapperClassName='wrapper-class'
                  editorClassName='editor-class'
                  toolbarClassName='toolbar-class'
                  toolbar={{
                    options: ['inline'],
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough']
                    },

                    list: {
                      inDropdown: false,
                      options: ['unordered', 'ordered']
                    }
                  }}
                  toolbarHidden={false}
                />
              </div>
            </Form.Group>
          </Row>

          <Button type='submit' disabled={isLoading}>
            {' '}
            {isLoading ? <Spinner animation='border' size='sm' /> : userDetail ? 'Update' : 'Create'}
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default AddEdit
