import React, { useState, useEffect } from 'react'
import { Modal, Table, Badge, Button, Spinner } from 'react-bootstrap'
import { MdOutlineClose } from 'react-icons/md'
import Avatar from '../Avatar'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { renderDocsView } from '../../../../utils/constants'
import DocumentLightBox from '../devProjects/DocumentLightBox'
import { changeProjectBugStatus, getAllProjectsSettings, getAllProjectBugList } from '../../../../redux/projects/action'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { updateOpportunities } from '../../../../redux/projects/action'
import { FaArrowLeftLong } from 'react-icons/fa6'

export const AdminInfoModal = ({ openInfoBug, handleCloseInfoBug, projectDetail, bugDetail }) => {
  const [isOpenLightBox, setIsOpenLightBox] = useState(false)
  const [documentImage, setDocumentImage] = useState('')
  const [isLoader, setIsLoader] = useState(false)
  const { pipelines } = useSelector(state => state.projects)
  const { savedPipleline } = useSelector(state => state.calenders)
  let selectedPipeLine = pipelines?.find(item => item?.id == savedPipleline?.pipelineId)
  const dispatch = useDispatch()

  const closeDialog = () => {
    setDocumentImage('')
    setIsOpenLightBox(false)
  }

  const openDialog = item => {
    setDocumentImage(item?.url)
    setIsOpenLightBox(true)
  }

  const handleProjectBugStatusChange = async () => {
    const payload = {
      _id: bugDetail?._id,
      status: 3 // 1. Pending, 2. In-Progress, 3. Fixed
    }
    const opportunitesParams = {
      opportunities_id: projectDetail?.projectId,
      pipelineId: savedPipleline?.pipelineId,
      pipelineStageId: selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 2]?.id
    }
    const bugParams = {
      projectId: projectDetail?.projectId ? projectDetail?.projectId : projectDetail?.id,
      page: 1,
      limit: 10
    }

    setIsLoader(true)
    const response = await changeProjectBugStatus(payload)
    if (response?.data?.status_code == 201 || response?.data?.status_code == 200) {
      dispatch(getAllProjectBugList(bugParams))
      if (response?.data?.data?.raiseCount === 0) {
        const res = await updateOpportunities(opportunitesParams)
        if (res?.data?.status_code == 201 || res?.data?.status_code == 200) {
          toast.success(res?.data.message, {
            position: 'top-right',
            theme: 'colored'
          })
        } else {
          toast.error(res?.data?.message, {
            position: 'top-right',
            theme: 'colored'
          })
        }
      }
      dispatch(getAllProjectsSettings({ pipelineId: savedPipleline?.pipelineId }))
      setIsLoader(false)
      toast.success(response?.data.message, {
        position: 'top-right',
        theme: 'colored'
      })
      handleCloseInfoBug()
    } else {
      setIsLoader(false)
      toast.error(response?.data?.message, {
        position: 'top-right',
        theme: 'colored'
      })
      handleCloseInfoBug()
    }
  }
  return (
    <>
      {' '}
      <Modal
        show={openInfoBug}
        onHide={handleCloseInfoBug}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div
          className='py-2 px-3'
          style={{
            background:
              'rgb(236,246,255) linear-gradient(180deg, rgba(236,246,255,1) 0%, rgba(248,248,255,1) 25%, rgba(237,235,255,1) 100%, rgba(239,239,246,1) 75%, rgba(249,249,249,1) 100%)',
            borderRadius: '10px',
            border: 'none',
            // height: `${height - 70}px`,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          <div className='d-flex justify-content-between w-100 pb-2 border-bottom'>
            <div className='d-flex gap-1'>
              <FaArrowLeftLong
                onClick={() => handleCloseInfoBug()}
                size={21}
                style={{ cursor: 'pointer', color: '#000', marginTop: '2px' }}
              />
              <p style={{ color: '#000', fontWeight: '600' }}>Bug Detail</p>
            </div>

            <MdOutlineClose onClick={handleCloseInfoBug} size={25} style={{ cursor: 'pointer' }} />
          </div>
          <div className='px-3 py-3' style={{ backgroundColor: '#ECE2FF', borderRadius: '10px' }}>
            <div className='d-flex justify-content-between w-100'>
              <h4 className='text-dark'>{bugDetail?.title}</h4>
              <Badge
                bg={bugDetail?.status == 1 ? 'info' : bugDetail?.status == 2 ? 'warning' : 'success'}
                style={{ borderRadius: '50px', height: '20px', fontWeight: '400' }}
              >
                {bugDetail?.status == 1 ? 'Bug Raised' : bugDetail?.status == 2 ? 'In-progress' : 'Bug Fixed'}
              </Badge>
            </div>

            <p style={{ color: '#000', fontSize: '14px', marginBottom: '10px' }}>{bugDetail?.description}</p>

            <div className='d-flex justify-content-between w-100'>
              <div className='d-flex gap-3 '>
                <div>
                  <p style={{ color: '#000', fontSize: '12px', fontWeight: '600' }}>Date Add on</p>
                  <p style={{ color: '#808080', fontSize: '12px', fontWeight: '500' }}>
                    {' '}
                    {dayjs(bugDetail?.createAt).format('DD-MM-YYYY')}
                  </p>
                </div>
                {bugDetail?.closedDate ? (
                  <div>
                    <p style={{ color: '#000', fontSize: '12px', fontWeight: '600' }}>Date Closed on</p>
                    <p style={{ color: '#808080', fontSize: '12px', fontWeight: '500' }}>
                      {' '}
                      {dayjs(bugDetail?.closedDate).format('DD-MM-YYYY')}
                    </p>
                  </div>
                ) : null}
              </div>

              <div>
                <p style={{ color: '#000', fontSize: '12px', fontWeight: '600' }}>Created By:</p>
                <p style={{ color: '#808080', fontSize: '12px', fontWeight: '500' }}>{bugDetail?.createByName}</p>
              </div>
            </div>
          </div>

          <div className='px-3 py-3' style={{ backgroundColor: '#FFFFFF', borderRadius: '10px', marginBottom: '10px' }}>
            <p style={{ color: '#000', fontSize: '14px', fontWeight: '600' }}>Documents:</p>
            <div className='d-flex  gap-3 align-items-center mt-2'>
              {bugDetail?.documents?.map((item, index) => {
                return (
                  <div
                    className={'d-flex  align-items-center cursor-pointer flex-column'}
                    key={index}
                    onClick={() => openDialog(item)}
                  >
                    {renderDocsView(item?.url)}
                    <p style={{ fontSize: '11px' }}>{item?.name}</p>
                  </div>
                )
              })}
            </div>
          </div>

          <div className='px-3 py-3' style={{ backgroundColor: '#FFFFFF', borderRadius: '10px', marginBottom: '10px' }}>
            <p style={{ color: '#000', fontSize: '14px', fontWeight: '600' }}>Assigned To:</p>
            <div className='d-flex  gap-3 align-items-center mt-2'>
              {projectDetail?.assignedDev?.map((item, index) => {
                return (
                  <div
                    style={{ background: '#F5F1EE', borderRadius: '50px', marginBottom: '20px' }}
                    className={'d-flex  gap-2 align-items-center'}
                    key={index}
                  >
                    <Avatar name={item.name} size='xl' />
                    <p
                      style={{
                        color: '#000',
                        fontSize: '11px',
                        fontWeight: '500',
                        textAlign: 'center',
                        paddingRight: '10px'
                      }}
                    >
                      {' '}
                      {item.name}
                    </p>
                  </div>
                )
              })}
              {/* <div
                style={{ background: '#F5F1EE', borderRadius: '50px', marginBottom: '20px' }}
                className={'d-flex  gap-3 align-items-center'}
              >
                <Avatar name='Rajesh Thakur' size='xl' />
                <p
                  style={{
                    color: '#000',
                    fontSize: '12px',
                    fontWeight: '500',
                    textAlign: 'center',
                    paddingRight: '10px'
                  }}
                >
                  {' '}
                  Rajesh Thakur
                </p>
              </div>
              <div
                style={{ background: '#F5F1EE', borderRadius: '50px', marginBottom: '20px' }}
                className={'d-flex  gap-3 align-items-center'}
              >
                <Avatar name='Rajesh Thakur' size='xl' />
                <p
                  style={{
                    color: '#000',
                    fontSize: '12px',
                    textAlign: 'center',
                    fontWeight: '500',
                    paddingRight: '10px'
                  }}
                >
                  Rajesh Thakur
                </p>
              </div> */}
            </div>
          </div>

          <div className='d-flex justify-content-end pb-1'>
            {bugDetail?.status != 3 && (
              <Button style={{ borderRadius: '50px' }} onClick={handleProjectBugStatusChange} disabled={isLoader}>
                {isLoader ? <Spinner size='sm' /> : 'Fix Bug'}
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <DocumentLightBox isOpenLightBox={isOpenLightBox} toggleHandler={closeDialog} documentImage={documentImage} />
    </>
  )
}
