import React from 'react'
import { useNavigate } from 'react-router-dom'
import ClientImg from 'assets/dashboard/client-icon.svg'
import { Spinner } from 'react-bootstrap'


const TotalClients = ({ isProjectsCount, clients }) => {
    const navigate = useNavigate()
    return (
        <div className='client-tile p-3 pt-4 cursor-pointer' onClick={() => navigate('/app/clients')}>
            <div className='d-flex flex-column'>
                <img src={ClientImg} alt='dmd' style={{ width: '64px', height: '64px' }} />
                <p style={{ fontSize: '20px', color: '#fff', fontWeight: '600', margin: '15px 0px' }}>Clients</p>
                {/* <p style={{ fontSize: '30px', color: '#fff', fontWeight: 'bold', marginTop: '0px', marginBottom: '4px' }}>24,455</p> */}
                <div
                    style={{
                        color: '#FFFFFF',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: '20px'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', borderRight: '1px solid #FFF' }}>
                        <span style={{ marginRight: '10px' }}>Active:</span>
                        <div style={{ fontWeight: '600', marginRight: '6px' }}>{isProjectsCount ? <Spinner size='sm' /> : clients?.active ?? 0}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px' }}>In-active:</span>
                        <div style={{ fontWeight: '600', marginRight: '6px' }}>{isProjectsCount ? <Spinner size='sm' /> : clients?.inactive ?? 0}</div>
                    </div>


                </div>
            </div>
        </div>
    )
}


export default TotalClients
