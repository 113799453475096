import { checkResponse, get, post, put } from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'

export const getAllDevelopers = params => async dispatch => {
  const res = await get(apis.get_all_staffs, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_DEV,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_CONTACTS_DEV_ERROR
    })
  }
}

export const getAllUsers = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_USERS_ACTION_START
  })
  const res = await get(apis.get_all_users, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_USERS,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_USERS_ERROR
    })
  }
}
export const getAllUsersList = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_USERS_ACTION_START
  })
  const res = await get(apis.get_all_users, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_USERS_LIST,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_USERS_ERROR
    })
  }
}
export const AddUser = async data => {
  const res = await post(apis.Add_User, data)
  const response = await checkResponse(res)
  if (response?.success) {
    return { data: response.data }
  } else {
    console.log('rrr')
  }
}
export const EditUser = async data => {
  const res = await put(apis.edit_user, data)
  const response = await checkResponse(res)
  if (response?.success) {
    return { data: response.data }
  } else {
    return { data: res }
  }
}


export const AddSalesUser = async data => {
  const res = await post(apis.add_sales_user, data)
  const response = await checkResponse(res)
  if (response?.success) {
    return { data: response.data }
  } else {
    console.log('rrr')
  }
}
export const EditSalesUser = async data => {
  const res = await put(apis.edit_sales_user, data)
  const response = await checkResponse(res)
  if (response?.success) {
    return { data: response.data }
  } else {
    return { data: res }
  }
}



export const getAllClients = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_CLIENTS_START
  })
  const res = await get(`${apis.get_clients_all}`, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_CLIENTS,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_CLIENTS_ERROR
    })
  }
}
export const getSingleClinet = async params => {
  const res = await get(`${apis.get_clients_all}`, params)
  const response = await checkResponse(res)
  if (response?.success) {
    return { data: response.data }
  }
}

export const getAllTags = () => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_TAGS_START
  })
  const res = await get(`${apis.get_tags}`)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_TAGS,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_TAGS_ERROR
    })
  }
}

export const getJobDetail = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_JOBS_DETAIL_START
  })
  const res = await get(`${apis.get_jobs_details}/${params?.id}`)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_JOBS_DETAIL,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_JOBS_DETAIL_ERROR
    })
  }
}

export const getAllSkills = () => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_SKILL_START
  })
  const res = await get(`${apis.get_all_skills}`)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_SKILLS,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    console.log('skils error')
  }
}
