import React from 'react';
import dayjs from 'dayjs';
import { IoIosNotifications } from "react-icons/io";

const Notification = ({ notification }) => (
    <div style={{
        background: notification?.is_read === 1 ? '#fff' : '#EFEDFF',
        cursor: 'pointer',
        transition: 'background 0.3s'
    }}>
        <div className="p-2">
            <div className="d-flex gap-2">
                <div style={{ width: '40px' }}>
                    <div style={{ background: '#A072F0', borderRadius: '50%', width: '35px', height: '35px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <IoIosNotifications size={20} color='#fff' />
                    </div>
                </div>
                <div className="w-100">
                    <div className="w-100 d-flex justify-content-between ">
                        <p style={{ fontWeight: '600' }} className=" text-break fs-10">{notification?.content?.title}</p>

                        <p style={{ fontWeight: '600' }} className="text-break  fs-11">
                            {dayjs(notification?.createdAt).format('MMMM D, YYYY h:mm A') // '25/01/2019'
                            }
                        </p>
                    </div>

                    <p className='text-break'>{notification?.content?.message}</p>
                </div>
            </div>

        </div>

    </div>
);




export default Notification;
