export const ReducerType = {
    // basic Details
    GET_GROUP_CHAT_LIST_START: 'GET_GROUP_CHAT_LIST_START',
    GET_GROUP_CHAT_LIST: 'GET_GROUP_CHAT_LIST',
    GET_GROUP_CHAT_LIST_ERROR: 'GET_GROUP_CHAT_LIST_ERROR',

    GET_SENDED_MESSAGE: "GET_SENDED_MESSAGE",

    GET_OLDER_GROUP_MESSAGES: "GET_OLDER_GROUP_MESSAGES",
    REMOVE_ALL_GROUP_CHAT: "REMOVE_ALL_GROUP_CHAT",



    GET_CLIENT_CHAT_LIST_START: 'GET_CLIENT_CHAT_LIST_START',
    GET_CLIENT_CHAT_LIST: 'GET_CLIENT_CHAT_LIST',
    GET_CLIENT_CHAT_LIST_ERROR: 'GET_CLIENT_CHAT_LIST_ERROR',

    GET_CLIENT_SENDED_MESSAGE: "GET_CLIENT_SENDED_MESSAGE",

    GET_OLDER_CLIENT_MESSAGES: "GET_OLDER_CLIENT_MESSAGES",
    GET_UNREAD_COUNT: "GET_UNREAD_COUNT",
    INCREASE_COUNT: "INCREASE_COUNT",
    DECREASE_COUNT: "DECREASE_COUNT",
    GET_ALL_UNREAD_COUNT: 'GET_ALL_UNREAD_COUNT',
    INCREASE_COUNT_PER_PROJECT: 'INCREASE_COUNT_PER_PROJECT',
    DECREASE_COUNT_PER_PROJECT: 'DECREASE_COUNT_PER_PROJECT'


}