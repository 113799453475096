import React, { useEffect } from 'react'
import Email from './email'
import SMS from './sms'
import { useSelector, useDispatch } from 'react-redux'
import { MdZoomOutMap } from 'react-icons/md'
import { isAdmin, isSales } from 'utils/constants'

function TextArea() {
  const dispatch = useDispatch()
  const [key, setKey] = React.useState('sms')
  const [isAccordionOpen, setIsAccordionOpen] = React.useState(true)
  const { sendThreadMessage } = useSelector(state => state.smsEmail)
  const { PorjectSettings } = useSelector(state => state.projects)
  const handleSelect = eventKey => {
    dispatch({
      type: 'SEND_THREAD_MESSAGE',
      payload: {
        threadId: '',
        isOpen: true,
        threadSubject: '',
        selectedTab: eventKey
      }
    })

    setKey(eventKey)
    setIsAccordionOpen(true)
  }

  const toggleCloseAccordion = () => {
    setKey('sms')
    setIsAccordionOpen(!isAccordionOpen)

    dispatch({
      type: 'SEND_THREAD_MESSAGE',
      payload: {
        threadId: '',
        isOpen: !isAccordionOpen,
        threadSubject: '',
        selectedTab: 'sms'
      }
    })
  }
  useEffect(() => {
    if (sendThreadMessage?.threadId) {
      setKey('email')
      setIsAccordionOpen(true)
    }
  }, [sendThreadMessage])

  useEffect(() => {
    if (PorjectSettings && !isAdmin() && !isSales()) {
      if (PorjectSettings?.sms_chat_enabled === 1) {
        setKey('sms')
      } else {
        setKey('email')
      }
    }
  }, [PorjectSettings])

  return (
    <>
      <div
        style={{
          border: '1px solid #F1F1F1',
          padding: '5px 10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: isAccordionOpen ? '10px 10px 0px 0px' : '10px'
        }}
      >
        <div className='d-flex  rounded'>
          {isAdmin() || isSales() || PorjectSettings?.sms_chat_enabled === 1 ? (
            <div
              onClick={() => handleSelect('sms')}
              style={{
                border: '1px solid #F1F1F1',
                display: 'inline-block',
                backgroundColor: key == 'sms' ? '#682FCC' : '#fff',
                padding: '8px 20px',
                color: key == 'sms' ? '#fff ' : '#000',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px'
              }}
            >
              <p className='mb-0 fw-semibold cursor-pointer'>SMS</p>
            </div>
          ) : null}
          {isAdmin() || isSales() || PorjectSettings?.email_chat_enabled === 1 ? (
            <div
              onClick={() => handleSelect('email')}
              style={{
                border: '1px solid #F1F1F1',
                display: 'inline-block',
                backgroundColor: key == 'email' ? '#682FCC' : '#fff',
                padding: '8px 20px',
                color: key == 'email' ? '#fff ' : '#000',
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px'
              }}
            >
              <p className='mb-0 fw-semibold cursor-pointer'>Email</p>
            </div>
          ) : null}
        </div>
        <MdZoomOutMap onClick={toggleCloseAccordion} style={{ cursor: 'pointer' }} />
      </div>
      {isAdmin() || isSales() || PorjectSettings?.sms_chat_enabled == 1
        ? key === 'sms' && isAccordionOpen && <SMS toggleCloseAccordion={toggleCloseAccordion} />
        : null}
      {isAdmin()
        || isSales() || PorjectSettings?.email_chat_enabled == 1
        ? key === 'email' &&
        isAccordionOpen && <Email sendThreadMessage={sendThreadMessage} toggleCloseAccordion={toggleCloseAccordion} />
        : null}
      {/* {key === 'sms' && !isAccordionOpen && <SmallSMs toggleCloseAccordion={toggleCloseAccordion} />} */}
    </>
  )
}

export default TextArea
