import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import ProjectImg from 'assets/dashboard/project-icon.svg'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'

const Projects = ({ Opportunities, isProjectsCount }) => {
  const navigate = useNavigate()
  const { isPipelines } = useSelector(state => state.projects)

  return (
    <div className='project-tile p-3 pt-4 cursor-pointer' onClick={() => navigate('/app/projects')}>
      <div className='d-flex flex-column'>
        <img src={ProjectImg} alt='dmd' style={{ width: '64px', height: '64px' }} />
        <p style={{ fontSize: '20px', color: '#fff', fontWeight: '600', margin: '15px 0px' }}>Projects</p>
        <div
          style={{
            color: '#FFFFFF',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '10px'
          }}
        >
          {!isProjectsCount &&
            Opportunities?.stages?.map((item, index) => (
              <div style={{ display: 'flex', alignItems: 'center', borderRight: '1px solid #FFF' }} key={index}>
                <span style={{ marginRight: '10px' }}>{item?.name}:</span>
                {/* <Spinner style={{ color: '#fff', marginRight: '10px' }} size='sm' /> */}
                <div style={{ fontWeight: '600', marginRight: '6px' }}>{isProjectsCount ? <Spinner /> : item?.total ?? 0}</div>
              </div>
            ))}
          {(isProjectsCount || isPipelines) && (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  )
}

Projects.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.string,
  amountClassName: PropTypes.string
}

export default Projects
