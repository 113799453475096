import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Spinner } from 'react-bootstrap'
import Avatar from 'components/common/Avatar'
import classNames from 'classnames'
import { disable2Fa } from '../../../redux/login/action'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const ProfileBannerHeader = ({ avatar, coverSrc, className }) => {
  const { userDetails } = useSelector(state => state.login)
  const [is2Fa, setIs2Fa] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  const handleDisable = async () => {
    setIsLoading(true)
    let res = await disable2Fa({ twoFactorEnabled: is2Fa ? 0 : 1 })
    if (res?.data?.status_code === 201) {
      setIs2Fa(is2Fa ? false : true)
      toast.success(res?.data.message, {
        position: 'top-right',
        theme: 'colored'
      })
      setIsLoading(false)
    } else {
      setIsLoading(false)

      toast.error('Something went wrong !', {
        position: 'top-right',
        theme: 'colored'
      })
    }
  }

  useEffect(() => {
    setIs2Fa(userDetails?.is2FA === 1 ? true : false)
  }, [userDetails])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <Card.Header className={classNames(className, 'position-relative min-vh-25 mb-7')}>
      <Avatar size='4xl' className='avatar-profile' name={avatar} mediaClass='img-thumbnail shadow-sm' />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: windowSize < 600 ? '10px' : '70px',
          flexDirection: windowSize < 600 ? 'column' : 'row'
        }}
      >
        <div style={{ display: windowSize < 600 ? 'flex' : '', justifyContent: windowSize < 600 ? 'flex-end' : '' }}>
          <span style={{ fontWeight: 'bold', color: '#808080', fontSize: windowSize < 400 ? '11px' : '' }}>
            2FA Authentication
          </span>
          <span
            style={{
              marginLeft: '5px',
              padding: '3px 10px',
              backgroundColor: '#5db55d',
              borderRadius: '50px',
              color: '#FFF',
              fontSize: windowSize < 400 ? '10px' : '12px',
              fontWeight: '600'
            }}
          >
            {is2Fa ? 'Enabled' : 'Disabled'}
          </span>
          <br />
          {windowSize > 600 && (
            <span style={{ color: 'rgb(187 187 187)', fontWeight: '400', fontSize: '14px' }}>
              this is for authentication purpose
            </span>
          )}
        </div>
        <div style={{ display: windowSize < 600 ? 'flex' : '', justifyContent: windowSize < 600 ? 'flex-end' : '' }}>
          <Button
            onClick={handleDisable}
            size='medium'
            variant='primary'
            style={{
              height: windowSize < 400 ? '25px' : '35px',
              fontSize: windowSize < 400 ? '12px' : '',
              textAlign: 'center'
            }}
            disabled={isLoading}
          >
            {isLoading ? <Spinner size='sm' /> : is2Fa ? 'Disable' : 'Enable'}
          </Button>
        </div>
      </div>
    </Card.Header>
  )
}

const ProfileBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>
}

const ProfileBanner = ({ children }) => {
  return (
    <Card className='mb-3 mt-6' style={{ height: '100px' }}>
      {children}
    </Card>
  )
}

ProfileBanner.Header = ProfileBannerHeader
ProfileBanner.Body = ProfileBannerBody

ProfileBannerHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  coverSrc: PropTypes.string.isRequired,
  className: PropTypes.string
}

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
}

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
}

export default ProfileBanner
