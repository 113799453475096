import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import Avatar from 'components/common/Avatar'
import paths from 'routes/paths'
import { Decrypt, RemoveAllTokens } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isAdmin } from '../../../utils/constants'
import { getUserDetail, logoutFn } from '../../../redux/login/action'
import { unsubscribeFromFirebaseMessaging } from '../../../firebase/firebase'

const ProfileDropdown = () => {
  const { userDetails } = useSelector(state => state.login)
  const dispatch = useDispatch()
  let fcmToken = localStorage.getItem('fcmToken') ?? ''
  const handleLogout = async () => {
    let decryptedToken = fcmToken ? Decrypt(fcmToken) : ""

    await logoutFn({ device_token: decryptedToken })
    await unsubscribeFromFirebaseMessaging()
    dispatch({
      type: 'REMOVE_USER'
    })
    dispatch({
      type: 'REMOVE_ALL_PROJECTS_SETTINGS'
    })
    RemoveAllTokens()
    window.location.reload()
  }

  return (
    <Dropdown navbar={true} as='li'>
      <Dropdown.Toggle bsPrefix='toggle' as={Link} to='#!' className='pe-0 ps-2 nav-link'>
        <Avatar
          name={
            userDetails
              ? userDetails?.name
                ? userDetails?.name
                : `${userDetails?.firstName}  ${userDetails?.lastName}`
              : ''
          }
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
        <div className='bg-white rounded-2 py-2 dark__bg-1000'>
          <Dropdown.Item as={Link} to={paths.profile} onClick={() => dispatch(getUserDetail())}>
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Divider />
          {isAdmin() && (
            <Dropdown.Item as={Link} to={paths.settings}>
              Settings
            </Dropdown.Item>
          )}

          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
