import { ReducerType } from './type'
import _ from 'lodash'
const InitialState = {
  isConversations: false,
  Conversations: [],
  isConvMessages: false,
  isEmailDetail: false,
  userSendMessage: '',
  porjectInProgressTime: '',
  sendThreadMessage: {
    isOpen: true,
    threadId: '',
    threadSubject: '',
    selectedTab: 'sms'
  },
  ConvMessages: {
    lastMessageId: null,
    nextPage: null,
    messages: [] // Initialize as an empty array
  }
}

const smsEmail = (state = InitialState, action, globalState) => {
  switch (action?.type) {
    case ReducerType.GET_ALL_CONVERSATION_START:
      return {
        ...state,
        isConversations: true,
        Conversations: []
      }

    case ReducerType.GET_ALL_CONVERSATION:
      return {
        ...state,
        isConversations: false,
        Conversations: action.payload
      }
    case ReducerType.GET_ALL_CONVERSATION_ERROR:
      return {
        ...state,
        isConversations: false,
        Conversations: []
      }
    //messages

    case ReducerType.GET_CONVERSATION_MESSAGE_START:
      return {
        ...state,
        isConvMessages: true
      }

    case ReducerType.GET_CONVERSATION_MESSAGE:
      return {
        ...state,
        ConvMessages: {
          lastMessageId: action.payload?.lastMessageId,
          nextPage: action.payload?.nextPage,
          messages: action.payload?.messages // Initialize as an empty array
        },
        isConvMessages: false,

      }
    //develoepr convesation

    case ReducerType.GET_PROJECT_INPROGRESS_TIME:
      return {
        ...state,
        porjectInProgressTime: action.payload
      }

    case ReducerType.GET_DEV_CONVERSATION_MESSAGE:
      const devcomparisonTimestamp = state.porjectInProgressTime ? new Date(state.porjectInProgressTime) : new Date() // Convert to Unix timestamp
      // Filter the data array
      const devfilteredData = action.payload?.messages.filter(item => {
        const itemTimestamp = new Date(item.dateAdded) // Convert array element to Unix timestamp
        return itemTimestamp > devcomparisonTimestamp
      })

      return {
        ...state,
        ConvMessages: {
          lastMessageId: devfilteredData?.length > 19 ? action.payload?.lastMessageId : '',
          nextPage: devfilteredData?.length > 19 ? action.payload?.nextPage : false,
          messages: devfilteredData ?? [] // Initialize as an empty array
        },
        isConvMessages: false,

      }
    case ReducerType.GET_DEV_OLDER_MESSAGES:
      const devOldcomparisonDate = state.porjectInProgressTime ? new Date(state.porjectInProgressTime) : new Date()
      // Filter the data array
      const devOldfilteredData = action.payload?.messages.filter(item => {
        const itemDate = new Date(item.dateAdded)
        return itemDate > devOldcomparisonDate
      })

      return {
        ...state,
        ConvMessages: {
          ...state.ConvMessages,
          lastMessageId: action.payload?.lastMessageId || state.ConvMessages.lastMessageId,
          nextPage: action.payload?.nextPage,
          messages: Array.isArray(devOldfilteredData)
            ? [...state.ConvMessages.messages, ...devOldfilteredData]
            : [...state.ConvMessages.messages] // Ensure to spread the existing messages array
        },
        isConvMessages: false,

      }

    //***************************** */

    case ReducerType.GET_CONVERSATION_MESSAGE_ERROR:
      return {
        ...state,
        ConvMessages: {
          lastMessageId: null,
          nextPage: null,
          messages: [] // Initialize as an empty array
        },
        isConvMessages: false,

      }
    case ReducerType.GET_SMS_DETAIL:
      const newMessage = action.payload
      const isUnique = !state.ConvMessages.messages.some(message => message.id === newMessage.id)
      if (isUnique) {
        return {
          ...state,
          ConvMessages: {
            ...state.ConvMessages,
            messages: [newMessage, ...state.ConvMessages.messages]
          }
        }
      } else {
        return state
      }

    case ReducerType.GET_OLDER_MESSAGES:
      return {
        ...state,
        ConvMessages: {
          ...state.ConvMessages,
          lastMessageId: action.payload?.lastMessageId || state.ConvMessages.lastMessageId,
          nextPage: action.payload?.nextPage,
          messages: Array.isArray(action.payload?.messages)
            ? [...state.ConvMessages.messages, ...action.payload.messages]
            : [...state.ConvMessages.messages] // Ensure to spread the existing messages array
        },
        isConvMessages: false,

      }

    //get email details

    case ReducerType.GET_EMAIL_DETAIL_STRAT:
      return {
        ...state,
        isEmailDetail: true
      }

    case ReducerType.GET_EMAIL_DETAIL:
      const updatedMessages = state.ConvMessages.messages.map(message => {
        if (message.id === action.payload.threadId) {
          // If emailThreads is initially not present, initialize it as an empty array
          const emailThreads = message.emailThreads || []
          // Ensure uniqueness based on the 'id' property of the objects in emailThreads
          const uniqueEmailThreads = _.uniqBy([...emailThreads, action.payload], 'id')
          return {
            ...message,
            emailThreads: uniqueEmailThreads
          }
        }
        return message
      })

      return {
        ...state,
        isEmailDetail: false,
        ConvMessages: {
          ...state.ConvMessages,
          messages: updatedMessages
        }
      }

    case ReducerType.GET_EMAIL_DETAIL_UNSHIFT:
      const updatedMessagesUnshift = state.ConvMessages.messages.map(message => {
        if (message.id === action.payload.threadId) {
          // If emailThreads is initially not present, initialize it as an empty array
          const emailThreads = message.emailThreads || []
          // Ensure uniqueness based on the 'id' property of the objects in emailThreads
          const uniqueEmailThreads = _.uniqBy([action.payload, ...emailThreads], 'id')
          return {
            ...message,
            emailThreads: uniqueEmailThreads
          }
        }
        return message
      })

      return {
        ...state,
        isEmailDetail: false,
        ConvMessages: {
          ...state.ConvMessages,
          messages: updatedMessagesUnshift
        }
      }


    case ReducerType.GET_EMAIL_DETAIL_ERROR:
      return {
        ...state,
        isEmailDetail: false
      }

    case ReducerType.SEND_THREAD_MESSAGE:
      return {
        ...state,
        sendThreadMessage: action.payload
      }

    case ReducerType.REMOVE_STATES:
      return {
        ...state,
        Conversations: [],
        ConvMessages: {
          lastMessageId: null,
          nextPage: null,
          messages: [] // Initialize as an empty array
        }
      }

    case ReducerType.USER_SEND_MESSAGE:
      return {
        ...state,
        userSendMessage: action.payload
      }

    default:
      return state
  }
}
export default smsEmail
