import React, { useEffect, useRef, useState } from 'react'
import KanbanColumnHeader from '../KanbanColumnHeader'
import TaskCard from './AssignedCard'
import classNames from 'classnames'
import StrictModeDroppable from '../StrictModeDroppable'
import { useSelector } from 'react-redux'
import AvailableJobs from './AvailableJobs'
import MaintenanceJobs from './Maintenance'

const KanbanColumn = ({ job }) => {
  const { id, name, headOpportunities } = job
  const { pipelines } = useSelector(state => state.projects)
  const [showForm, setShowForm] = useState(false)
  const formViewRef = useRef(null)
  const { savedPipleline } = useSelector(state => state.calenders)
  let selectedPipeline = pipelines?.find(item => item?.id == savedPipleline?.pipelineId)

  const containerRef = useRef(null)
  useEffect(() => {
    const timeout = setTimeout(() => {
      formViewRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)

    return clearTimeout(timeout)
  }, [showForm])


  const draggableColor = (id) => {
    let color = '#000'
    if (id === selectedPipeline?.stages[0]?.id) {
      color = '#006699'
    } else if (id === selectedPipeline?.stages[1]?.id) {
      color = '#ff9900'
    } else if (id === selectedPipeline?.stages[2]?.id) {
      color = '#3FB0FF'
    } else if (id === selectedPipeline?.stages[3]?.id) {
      color = '#cc66ff'
    } else if (id === selectedPipeline?.stages[4]?.id) {
      color = '#3EC852'
    } else if (id === selectedPipeline?.stages[5]?.id) {
      color = '#ff0000'
    } else {
      color = '#000'
    }
    return color
  }


  return (
    <div className={classNames('kanban-column', { 'form-added': showForm })}>
      <KanbanColumnHeader id={id} title={name} itemCount={headOpportunities ? headOpportunities?.length : 0} />
      <StrictModeDroppable droppableId={`${id}`} type='KANBAN'>
        {(provided, snapshot) => (
          <>
            <div
              ref={ref => {
                containerRef.current = ref
                provided.innerRef(ref) // Maintain the existing reference
              }}
              {...provided.droppableProps}
              id={`${id}`}
              className='kanban-items-container scrollbar'
              style={{
                background: 'transparent', borderLeft: snapshot.isDraggingOver ? `2px solid ${draggableColor(provided?.droppableProps['data-rbd-droppable-id'])}` : 'none',
                borderBottom: snapshot.isDraggingOver ? `2px solid ${draggableColor(provided?.droppableProps['data-rbd-droppable-id'])}` : 'none',
                borderRight: snapshot.isDraggingOver ? `2px solid ${draggableColor(provided?.droppableProps['data-rbd-droppable-id'])}` : 'none',
                borderRadius: snapshot.isDraggingOver ? '0px 0px 8px 8px' : '',
              }}
            >
              {headOpportunities &&
                headOpportunities?.map((task, index) => {
                  // if (task?.pipelineStageId === selectedPipeline?.stages[1]?.id) {
                  //   return <AvailableJobs job={task} key={task?.id} />

                  // } else 
                  if (task?.pipelineStageId === selectedPipeline?.stages[selectedPipeline?.stages?.length - 1]?.id) {
                    return <MaintenanceJobs job={task} key={task?.id} />

                  } else {
                    return <TaskCard key={task?.id} index={index} job={task} />

                  }
                })}

              {provided.placeholder}
              <div ref={formViewRef}></div>
            </div>
          </>
        )}
      </StrictModeDroppable>


    </div>
  )
}



export default KanbanColumn
