import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import NotesIcon from 'assets/chat/notes.svg'
import DocIcon from 'assets/chat/doc.svg'
import InfoIcon from 'assets/chat/info.svg'
import VideoIcon from 'assets/chat/video.svg'
import GroupChat from 'assets/chat/group-chat.svg'
import GroupChatWhite from 'assets/chat/Group-chat-white.svg'
import EmailChat from 'assets/chat/email-chat.svg'
import EmailChatWhite from 'assets/chat/email-chat-white.svg'
import { isAdmin, isSales } from 'utils/constants'
import { useSelector } from 'react-redux'
import ClientWhite from 'assets/chat/client-white.svg'
import ClientBlack from 'assets/chat/client-black.svg'
import BugIcon from 'assets/chat/bug-white.svg'
import { useDispatch } from 'react-redux'
export const ButtonDrawer = ({ openButtonDrawer, handleCloseHamBurger, handleOpenDetail, activeTab, setActiveTab, clentChatUnreadCount, teamChatUnreadCount }) => {
  const { pipelines, PorjectSettings } = useSelector(state => state.projects)
  const { userDetails } = useSelector(state => state.login)
  const dispatch = useDispatch()
  return (
    <>
      <Offcanvas show={openButtonDrawer} onHide={handleCloseHamBurger} placement='end' style={{ width: '75px' }}>
        <Offcanvas.Header closeButton></Offcanvas.Header>

        <Offcanvas.Body style={{ padding: '9px' }}>
          {/* {!isAdmin() &&
          PorjectSettings?.sms_chat_enabled === 0 &&
          PorjectSettings?.email_chat_enabled === 0 &&
          PorjectSettings?.team_chat_enabled === 0 ? null : ( */}
          <div
            style={{ background: '#E6DEF4', borderRadius: '50px', padding: '3px', marginBottom: '20px' }}
            className={'d-flex  flex-column  gap-3 align-items-center'}
          >

            <div
              onClick={() => {
                setActiveTab('clientChat'),
                  dispatch({
                    type: 'DECREASE_COUNT',
                    payload: 'client'
                  })
              }

              }
              style={{
                position: 'relative',
                background: activeTab === 'clientChat' ? '#682FCC' : '#fff',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '50px',
                width: '50px',
                borderRadius: '50px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
              }}
            >
              {activeTab === 'clientChat' ? <img src={ClientWhite} /> : <img src={ClientBlack} />}
              {clentChatUnreadCount > 0 &&
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {clentChatUnreadCount < 100 ? clentChatUnreadCount : "99+"}
                </span>
              }
            </div>



            {(isAdmin() || isSales() || PorjectSettings?.team_chat_enabled === 1) && userDetails?.userType !== 3 ? (
              <div
                onClick={() => {
                  setActiveTab('groupChat'),
                    dispatch({
                      type: 'DECREASE_COUNT',
                      payload: 'team'
                    })
                }

                }
                style={{
                  position: 'relative',
                  background: activeTab === 'groupChat' ? '#682FCC' : '#fff',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '50px',
                  width: '50px',
                  borderRadius: '50px',
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                }}
              >
                {activeTab === 'groupChat' ? <img src={GroupChatWhite} /> : <img src={GroupChat} />}
                {teamChatUnreadCount > 0 &&
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {teamChatUnreadCount < 100 ? teamChatUnreadCount : "99+"}
                  </span>}
              </div>
            ) : null}
            {((isAdmin() || isSales()) || (userDetails?.userType === 2 && PorjectSettings?.sms_chat_enabled === 1 || PorjectSettings?.email_chat_enabled === 1)) &&

              <div
                onClick={() => setActiveTab('chat')}
                style={{
                  background: activeTab === 'chat' ? '#682FCC' : '#fff',

                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '50px',
                  width: '50px',
                  borderRadius: '50px',
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                }}
              >
                {activeTab === 'chat' ? <img src={EmailChatWhite} /> : <img src={EmailChat} />}
              </div>
            }

          </div>
          {/* )} */}



          <div
            style={{ background: '#E6DEF4', borderRadius: '50px', padding: '3px' }}
            className={'d-flex  flex-column  gap-3 align-items-center'}
          >
            <div
              onClick={() => handleOpenDetail('Information')}
              style={{
                cursor: 'pointer',
                backgroundColor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '50px',
                width: '50px',
                borderRadius: '50px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
              }}
            >
              <img src={InfoIcon} />
            </div>
            {activeTab === 'clientChat' ? (
              <>
                <div
                  onClick={() => handleOpenDetail('Client Documents')}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50px',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                  }}
                >
                  <img src={DocIcon} />
                </div>

              </>
            ) : (
              <>
                {userDetails?.userType !== 3 && (
                  <div
                    onClick={() => handleOpenDetail('Notes')}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                    }}
                  >
                    <img src={NotesIcon} />
                  </div>
                )}

                <div
                  onClick={() => handleOpenDetail('Documents')}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50px',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                  }}
                >
                  <img src={DocIcon} />
                </div>
                {userDetails?.userType !== 3 && (
                  <div
                    onClick={() => handleOpenDetail('Videos')}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                    }}
                  >
                    <img src={VideoIcon} />
                  </div>
                )}
              </>
            )}
          </div>

          <div
            style={{ background: '#E6DEF4', borderRadius: '50px', padding: '3px', marginTop: '20px' }}
            className={'d-flex  flex-column  gap-3 align-items-center'}
          >
            <div
              onClick={() => handleOpenDetail('Bugs')}
              style={{
                cursor: 'pointer',
                backgroundColor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '50px',
                width: '50px',
                background: '#F55734',
                borderRadius: '50px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
              }}
            >
              <img src={BugIcon} />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
