import React, { useEffect, useRef, useState } from 'react'
import KanbanColumn from './KanbanColumn'
import { DragDropContext } from 'react-beautiful-dnd'
import is from 'is_js'
import { useSelector, useDispatch } from 'react-redux'
import { updateOpportunities } from '../../../redux/projects/action'
import authConfig from 'utils/authConfig'
import { Decrypt } from 'utils/constants'
import Placeholder from 'react-bootstrap/Placeholder'
import Card from 'react-bootstrap/Card'
import { getAllPipelines, getAllOpportunities, getAllProjectsSettings } from '../../../redux/projects/action'
import { getALLUnreadCount } from '../../../redux/groupChat/action'

import { getAllUsers } from '../../../redux/users/action'
import PageHeader from 'components/app/projects/ProjectsTittle'
import { toast } from 'react-toastify'
import { fetchOpportunityEvents } from 'utils/socket'
import _ from 'lodash'
import { useLocation } from 'react-router-dom';

const KanbanContainer = () => {
  const { userDetails } = useSelector(state => state.login)
  const { Opportunities, isOpportunities, pipelines, OpportunitiesAllStagesArr, allProjectSettings } = useSelector(
    state => state.projects
  )
  const { savedPipleline } = useSelector(state => state.calenders)
  const { usersList } = useSelector(state => state.users)
  let selectedPipeline = pipelines?.find(item => item?.id === savedPipleline?.pipelineId)
  const [OpportunitiesArr, setOpportunitiesArr] = useState([])
  const dispatch = useDispatch()
  let IncrypteduserType = localStorage.getItem(authConfig.rolesKey)
  let userType = Decrypt(IncrypteduserType)
  const containerRef = useRef(null)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const { pathname } = useLocation();

  useEffect(() => {
    if (savedPipleline && userDetails) {
      dispatch(getAllUsers())
      const fetchDataForPipelines = async () => {
        try {
          // Create an array of promises for fetching data for each pipeline
          const promises = selectedPipeline?.stages?.map(stage => {
            // Dispatch the action to fetch data for each pipeline
            dispatch(
              getAllOpportunities({ pipeline_id: savedPipleline?.pipelineId, limit: 20, pipeline_stage_id: stage?.id })
            )
          })
          dispatch(getALLUnreadCount())

          // Wait for all promises to resolve
          await Promise.all(promises)
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
      fetchDataForPipelines()
    }
  }, [savedPipleline, selectedPipeline, userDetails])


  useEffect(() => {
    setOpportunitiesArr(Opportunities)
  }, [Opportunities])



  useEffect(() => {
    if (is.ipad()) {
      containerRef.current.classList.add('ipad')
    }

    if (is.mobile()) {
      containerRef.current.classList.add('mobile')
      if (is.safari()) {
        containerRef.current.classList.add('safari')
      }
      if (is.chrome()) {
        containerRef.current.classList.add('chrome')
      }
    }
  }, [])

  const getColumn = id => {
    return OpportunitiesArr.find(item => item?.id == id)
  }

  const reorderArray = (array, fromIndex, toIndex) => {
    let arr = array.headOpportunities
    const newArr = arr.length > 0 ? [...arr] : []

    const chosenItem = newArr.splice(fromIndex, 1)[0]
    newArr.splice(toIndex, 0, chosenItem)

    return newArr
  }

  const move = (source, destination) => {
    const sourceItemsClone = [...getColumn(source.droppableId).headOpportunities]
    const destItemsClone = [...getColumn(destination.droppableId).headOpportunities]
    const [removedItem] = sourceItemsClone.splice(source.index, 1)
    destItemsClone.splice(destination.index, 0, removedItem)

    return {
      updatedDestItems: destItemsClone,
      updatedSourceItems: sourceItemsClone
    }
  }

  const handleDragEnd = async result => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = getColumn(source.droppableId)
      const column = getColumn(source.droppableId)
      const reorderedItems = reorderArray(items, source.index, destination.index)
      dispatch({
        type: 'UPDATE_SINGLE_COLUMN',
        payload: { column, reorderedItems }
      })
    } else {
      const sourceColumn = getColumn(source.droppableId)
      const destColumn = getColumn(destination.droppableId)
      const movedItems = move(source, destination)
      let draggedCardId = result?.draggableId
      let selectedCard = sourceColumn?.headOpportunities?.find(item => item?.id === draggedCardId)
      const index = movedItems?.updatedDestItems.findIndex(item => item?.id === draggedCardId)
      const updatedMovedItems = {
        ...movedItems,
        updatedDestItems: movedItems.updatedDestItems.map(item =>
          item.id === draggedCardId && userType !== 1 ? { ...item, assignedTo: userDetails?.id } : item
        )
      }

      if (
        (sourceColumn?.id === selectedPipeline?.stages[0]?.id && destColumn?.id !== selectedPipeline?.stages[1]?.id)
      ) {
        return toast.error(`You can't move directly to ${destColumn?.name}; you need to move to post-discovery first.
        `, {
          theme: 'colored',
          position: 'top-right'
        })
      } else if (sourceColumn?.id === selectedPipeline?.stages[1]?.id && (destColumn?.id !== selectedPipeline?.stages[0]?.id && destColumn?.id !== selectedPipeline?.stages[2]?.id)) {
        return toast.error(`You can't move directly to ${destColumn?.name}; you need to move to In-Progress first.

          `, {
          theme: 'colored',
          position: 'top-right'
        })
      }


      dispatch({
        type: 'UPDATE_DUAL_COLUMN',
        payload: {
          sourceColumn,
          updatedSourceItems: updatedMovedItems.updatedSourceItems,
          destColumn,
          updatedDestItems: updatedMovedItems.updatedDestItems
        }
      })

      dispatch({
        type: 'IS_CARD_MOVING_START',
        payload: movedItems?.updatedDestItems[index]?.id
      })
      let params = {
        opportunities_id: movedItems?.updatedDestItems[index]?.id,
        pipelineId: movedItems?.updatedDestItems[index]?.pipelineId,
        pipelineStageId: destColumn?.id
      }
      let res = await updateOpportunities(params)
      if (res?.data?.status_code == 200) {
        dispatch({
          type: 'IS_CARD_MOVING'
        })
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      } else {
        dispatch({
          type: 'IS_CARD_MOVING_ERROR'
        })
      }
    }
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])



  useEffect(() => {
    if (pipelines.length > 0) {
      fetchOpportunityEvents(dispatch)

    }


  }, [pipelines])





  useEffect(() => {
    // Disable scrolling
    document.body.style.overflowY = 'hidden';

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <>
      <PageHeader title={selectedPipeline?.name} className='mb-3 '></PageHeader>

      <DragDropContext onDragEnd={handleDragEnd} >
        <div
          className='kanban-container me-n3'
          ref={containerRef}


        >
          {isOpportunities ? (
            <>
              <div
                className=' d-flex align-items-center  justify-content-between p-4 gap-3'
                style={{ flexWrap: windowSize < 1000 ? 'wrap' : '' }}
              >
                {new Array(4).fill(null).map((item, index) => (
                  <Card
                    style={{
                      width: windowSize <= 500 ? '100%' : windowSize < 1000 ? '45%' : '28rem'
                    }}
                    key={index}
                  >
                    <Card.Body>
                      <Placeholder as={Card.Title} animation='glow'>
                        <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation='glow'>
                        <Placeholder xs={6} /> <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation='glow'>
                        <Placeholder xs={6} /> <Placeholder xs={6} />
                      </Placeholder>
                    </Card.Body>
                  </Card>
                ))}
              </div>

              <div
                className=' d-flex align-items-center justify-content-between p-4 gap-3 '
                style={{ flexWrap: windowSize < 1000 ? 'wrap' : '' }}
              >
                {new Array(4).fill(null).map((item, index) => (
                  <Card
                    style={{
                      width: windowSize <= 500 ? '100%' : windowSize < 1000 ? '45%' : '28rem'
                    }}
                    key={index}
                  >
                    <Card.Body>
                      <Placeholder as={Card.Title} animation='glow'>
                        <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation='glow'>
                        <Placeholder xs={6} /> <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation='glow'>
                        <Placeholder xs={6} /> <Placeholder xs={6} />
                      </Placeholder>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </>
          ) : (
            <>
              {OpportunitiesArr &&
                OpportunitiesArr.slice(userType === 2 ? 1 : 0)?.map(kanbanColumnItem => (
                  <KanbanColumn
                    savedPipleline={savedPipleline}
                    key={kanbanColumnItem.id}
                    kanbanColumnItem={kanbanColumnItem}
                    OpportunitiesAllStagesArr={OpportunitiesAllStagesArr}
                  />
                ))}
            </>
          )}
        </div>
      </DragDropContext>
    </>
  )
}

export default KanbanContainer
