import { checkResponse, get, post, put } from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'

export const getAllClientList = (params, type) => async dispatch => {
  if (type != 'search') {
    dispatch({
      type: ReducerType.GET_ALL_CLIENT_ACTION_START
    })
  }
  const res = await get(apis.get_all_client_list, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_CLIENT,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_CLIENT_ERROR
    })
  }
}

export const EditClient = async data => {
  const res = await put(apis.client_edit, data)
  const response = await checkResponse(res)
  if (response?.success) {
    return { data: response.data }
  } else {
    return { data: res }
  }
}
