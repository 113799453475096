import React, { useState } from 'react'

import { toast } from 'react-toastify'
import { Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Spinner from 'react-bootstrap/Spinner'
import VerifyOtp from './VerifyOtp'
import { checkUserExist, resendOtp } from '../../../redux/login/action'

const VerifyEmail = ({ setIsValidEmail, setEmail }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [otpErrorMessage, setOtpErrorMessage] = useState('')

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(' Please enter a valid email address')
        .matches(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
        .min(5, 'Email must be at least 5 characters')
        .max(100, 'Email must not exceed 100 characters')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true)
      let params = {
        email: values.email
      }
      const res = await checkUserExist(params)
      if (res?.data?.data?.status_code == 200 || res?.data?.data?.status_code == 201) {
        setEmail(values.email)
        // formik.resetForm()
        setIsLoading(false)
        setIsValidEmail(true)
      } else if (res?.data?.data?.status_code == 422) {
        setOtpErrorMessage(res?.data?.data?.message)
        await resendOtp({ email: values.email, type: 1 })
        setModalShow(true)
        setIsLoading(false)
        toast.error(res?.data?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      } else {
        setIsLoading(false)

        toast.error(res?.data?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    }
  })

  return (
    <Form className='mt-4' noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className='mb-3'>
        <Form.Control
          name='email'
          type='email'
          placeholder={'Email address'}
          style={{ backgroundColor: '#FFFFFF', borderRadius: '50px', padding: '10px 20px', boxShadow: 'none' }}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched.email && formik.errors.email}
        />
        <Form.Control.Feedback type='invalid'>{formik.errors.email}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className='mb-3'>
        <Button
          className='w-100'
          type='submit'
          disabled={!formik.values.email || isLoading}
          style={{
            backgroundColor: '#682FCC',
            border: 'none',
            borderRadius: '50px',
            padding: '10px'
          }}
        >
          {isLoading ? <Spinner animation='border' size='sm' /> : 'Verify'}
        </Button>
      </Form.Group>

      <VerifyOtp
        show={modalShow}
        onHide={() => setModalShow(false)}
        email={formik?.values.email}
        otpErrorMessage={otpErrorMessage}
        setOtpErrorMessage={setOtpErrorMessage}
      />
    </Form>
  )
}

export default VerifyEmail
