import React from 'react';
import classNames from 'classnames';
import { Card, Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import corner4 from 'assets/img/illustrations/corner-4.png';
import createMarkup from 'helpers/createMarkup';


const PageHeader = ({
    title,
    preTitle,
    TitleTag = 'h3',
    description,
    image = corner4,
    col = { lg: 8 },
    children,
    ...rest
}) => (
    <>

        <Card {...rest} style={{ position: 'fixed', width: '98.5%', zIndex: 1 }}>
            <Background
                image={image}
                className="bg-card d-none d-sm-block"
                style={{
                    borderTopRightRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem'
                }}
            />
            <Card.Body className="position-relative">
                <Row>
                    <Col {...col}>
                        {preTitle && <h6 className="text-600">{preTitle}</h6>}
                        <TitleTag className="mb-0 fs-8">{title}</TitleTag>
                        {description && (
                            <p
                                className={classNames('mt-2', { 'mb-0': !children })}
                                dangerouslySetInnerHTML={createMarkup(description)}
                            />
                        )}
                        {children}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        <div style={{ backgroundColor: '#EFF6FF', height: '55px', marginBottom: '5px', borderRadius: '10px' }}>

        </div>
    </>


);



export default PageHeader;
