import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import UserImg from 'assets/dashboard/user-icon.svg'
import { Spinner } from 'react-bootstrap'

const TotalUsers = ({ users, isProjectsCount }) => {


  const navigate = useNavigate()
  return (
    <div className='user-tile p-3 pt-4 cursor-pointer' onClick={() => navigate('/app/users')}>
      <div className='d-flex flex-column'>
        <img src={UserImg} alt='dmd' style={{ width: '64px', height: '64px' }} />
        <p style={{ fontSize: '20px', color: '#fff', fontWeight: '600', margin: '15px 0px' }}>Users</p>
        {/* <p style={{ fontSize: '30px', color: '#fff', fontWeight: 'bold', marginTop: '0px', marginBottom: '4px' }}>24,455</p> */}
        <div
          style={{
            color: '#FFFFFF',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '20px'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', borderRight: '1px solid #FFF' }}>
            <span style={{ marginRight: '10px' }}>Active:</span>
            <div style={{ fontWeight: '600', marginRight: '6px' }}>{isProjectsCount ? <Spinner size='sm' /> : users?.active ?? 0}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>In-active:</span>
            <div style={{ fontWeight: '600', marginRight: '6px' }}>{isProjectsCount ? <Spinner size='sm' /> : users?.inactive ?? 0}</div>
          </div>


        </div>
      </div>
    </div>
  )
}

TotalUsers.propTypes = { data: PropTypes.array.isRequired }

export default TotalUsers
