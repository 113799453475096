import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import PageHeader from 'components/common/PageHeader'
import { useSelector, useDispatch } from 'react-redux'
import {
  getAllCalenders,
  getSettingsCalenders,
  getSavedPipeline,
  updatePipeline,
  saveCalenders
} from '../../../redux/calender/action'
import { getAllPipelines } from '../../../redux/projects/action'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { LuEyeOff, LuEye } from 'react-icons/lu'
import { base64Decoded, base64Encoded, deleteFn, post } from 'utils/constants'
import { getAllSkills } from '../../../redux/users/action'
import SubtleBadge from 'components/common/SubtleBadge'
import { RxCross2 } from 'react-icons/rx'
import { apis } from 'utils/Apis'
import { DeleteSkillModal } from './DeleteSkillModal'

const Settings = () => {
  const dispatch = useDispatch()
  const { isCalenders, calenders, savedCalenders, savedPipleline } = useSelector(state => state.calenders)
  const { skills, isSkill } = useSelector(state => state.users)
  const { pipelines } = useSelector(state => state.projects)
  const [selectedPipelineId, setSelectedPipelineId] = useState('')
  const [pipelineLoader, setPipelineLoder] = useState(false)
  const [calLoader, setCalLoader] = useState(false)
  const [conversationLoader, setConversationLoader] = useState(false)
  const [calendersList, setCalendersList] = useState([])
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [mailSettings, setMailSettings] = useState({
    userMail: '',
    userPassword: ''
  })
  const [mailSettignLoader, setMailSettingLoader] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [skillName, setSkillName] = useState('')
  const [skillLoader, setSkillLoader] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [skillId, setSkillId] = useState('')
  const [skillError, setSkillError] = useState('')
  const [statusVal, setStatusVal] = useState(1)
  let fakeArr = new Array(3).fill(0)
  // Initialize the calendersList state based on calenders length
  useEffect(() => {
    // Initialize calendersList state with calenderId from calenders array and URL from savedCalenders array
    const initializedCalendersList = calenders.map(calender => {
      const savedCalender = savedCalenders.find(savedCal => savedCal.calendarId === calender.id)
      return {
        calendarId: calender.id,
        url: savedCalender ? savedCalender.url : ''
      }
    })
    setCalendersList(initializedCalendersList)
  }, [calenders, savedCalenders])

  useEffect(() => {
    dispatch(getAllCalenders())
    dispatch(getSettingsCalenders())
    dispatch(getSavedPipeline())
    dispatch(getAllSkills())
  }, [])

  const handleChange = (index, name, value) => {
    const updatedCalendersList = [...calendersList]
    updatedCalendersList[index] = { ...updatedCalendersList[index], [name]: value }
    setCalendersList(updatedCalendersList)
  }

  useEffect(() => {
    setSelectedPipelineId(savedPipleline?.pipelineId ?? '')
    setEmail(savedPipleline?.chatEmail)
    setName(savedPipleline?.chatName)
    if (savedPipleline?.userMail && savedPipleline?.userPassword) {
      setMailSettings({
        userMail: savedPipleline?.userMail,
        userPassword: base64Decoded(savedPipleline?.userPassword)
        // userPassword: savedPipleline?.userPassword

      })
    }
    setStatusVal(savedPipleline?.isEnableClientWelcomeMail ?? 1)
  }, [savedPipleline])

  const handleSelectChange = event => {
    setSelectedPipelineId(event.target.value)
  }
  const handleUpdatePipeline = async () => {
    setPipelineLoder(true)
    try {
      let res = await updatePipeline({ pipelineId: selectedPipelineId, type: 1 })
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        setPipelineLoder(false)
        dispatch(getSavedPipeline())
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      }
    } catch (err) {
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-right'
      })
      setPipelineLoder(false)
    }
  }

  const handleConversationUpdate = async () => {
    setConversationLoader(true)
    try {
      let res = await updatePipeline({ chatName: name, chatEmail: email, type: 2 })
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        setConversationLoader(false)
        dispatch(getSavedPipeline())
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      }
    } catch (err) {
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-right'
      })
      setConversationLoader(false)
    }
  }

  const handleSaveCalenders = async () => {
    setCalLoader(true)
    try {
      let res = await saveCalenders({ calendars: calendersList })
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        setCalLoader(false)
        dispatch(getSavedPipeline())
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      }
    } catch (err) {
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-right'
      })
      setCalLoader(false)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleMailSetting = async () => {
    setMailSettingLoader(true)
    let encryptedData = base64Encoded(mailSettings.userPassword)

    const encryptedParams = {
      ...mailSettings,
      userPassword: encryptedData
    }
    try {
      let res = await updatePipeline({ ...encryptedParams, type: 3 })
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        setMailSettingLoader(false)
        dispatch(getSavedPipeline())
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      }
    } catch (err) {
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-right'
      })
      setMailSettingLoader(false)
    }
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const handleAddSkill = async () => {
    setSkillLoader(true)
    let params = {
      name: skillName
    }
    if (skillName != '') {
      const response = await post(apis.add_skill, params)
      if (response?.data?.status_code == 200 || response?.data?.status_code == 201) {
        setSkillLoader(false)
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        })
        setSkillName('')
        setSkillError('')
        dispatch(getAllSkills())
      } else {
        setSkillLoader(false)
        setSkillName('')
        setSkillError('')
        toast.error(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    } else {
      setSkillError('Name is Required.')
      setSkillLoader(false)
    }
  }

  const handleSkill = async _id => {
    setSkillId(_id)
    setIsOpenDeleteModal(true)
  }
  const handleCloseModal = () => {
    setIsOpenDeleteModal(false)
    setSkillId('')
  }

  const handleStatusModal = async (status) => {

    setStatusVal(status == true ? 1 : 0)
    try {
      let res = await updatePipeline({ isEnableClientWelcomeMail: status == true ? 1 : 0, type: 4 })
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        dispatch(getSavedPipeline())
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      }
    } catch (err) {
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-right'
      })
    }
  }


  return (
    <div>
      <PageHeader title='Global settings' className='mb-3 '></PageHeader>
      <Form className='py-3 px-2'>
        <Card className='mt-3 px-3 py-5'>
          <>
            <Row>
              <Col xs={12}>
                <p className='fw-bolder'> Pipeline Settings</p>
                <Form>
                  <div className='mb-3 g-2 d-flex align-items-center gap-2 w-100'>
                    <Form.Group as={Col} lg={3} controlId='formGroupPipeline' className='w-50'>
                      <Form.Select value={selectedPipelineId} onChange={handleSelectChange}>
                        <option value=''>Select Pipeline</option>
                        {pipelines.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Col lg={4}>
                      <Button variant='primary' onClick={handleUpdatePipeline} disabled={pipelineLoader}>
                        {pipelineLoader ? <Spinner size='sm' /> : 'Change Pipeline'}
                      </Button>
                    </Col>
                  </div>
                </Form>
              </Col>

            </Row>

          </>

          <Row className='mt-5 px-2'>
            <hr className='hr' />
          </Row>
          <>
            <div className='mt-2' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <p className='fw-bolder'>Skills</p>
              <div style={{ display: 'flex', flexWrap: 'wrap', width: windowSize < 800 ? '100%' : '40%' }}>
                {skills.length > 0 &&
                  skills.map((skill, index) => {
                    return (
                      <>
                        <span>
                          {' '}
                          <SubtleBadge key={index} className='py-2 me-1 mb-1 text-dark d-flex gap-1 align-items-center'>
                            {skill?.name}

                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement='top'
                              overlay={<Tooltip>Delete {skill?.name}</Tooltip>}
                            >
                              <div>
                                <RxCross2 className='crossIcon' onClick={() => handleSkill(skill?._id)} />
                              </div>
                            </OverlayTrigger>
                          </SubtleBadge>
                        </span>
                      </>
                    )
                  })}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                  {isSkill &&
                    fakeArr.map((item, index) => {
                      return (
                        <p
                          key={index}
                          style={{
                            width: '100px',
                            height: '26px',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '5px'
                          }}
                        ></p>
                      )
                    })}
                </div>
              </div>
              <div className='d-flex gap-2 flex-wrap'>
                <Form.Group controlId='formGroupPipeline' style={{ width: windowSize < 800 ? '100%' : '' }}>
                  <Form.Control
                    name='skill'
                    style={{ height: '38px' }}
                    type='text'
                    value={skillName}
                    placeholder='Skill name'
                    onChange={event => setSkillName(event.target.value)}
                  />
                  {skillError && skillName == '' ? (
                    <div>
                      <span
                        style={{
                          color: '#e63757',
                          fontSize: '12px',
                          fontWeight: '600'
                        }}
                      >
                        {' '}
                        {skillError}
                      </span>
                    </div>
                  ) : null}
                </Form.Group>
                <Button onClick={handleAddSkill} disabled={skillLoader} style={{ height: '37px' }}>
                  {skillLoader ? <Spinner size='sm' /> : '+ Add Skill'}
                </Button>
              </div>

            </div>
          </>

          <Row className='mt-5 px-2'>
            <hr className='hr' />
          </Row>

          <>
            <Row>
              <p className='fw-bolder'> Conversation Settings</p>
            </Row>
            <Form style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
              <div className='d-flex gap-2 flex-wrap'>
                <Form.Group controlId='formGroupPipeline' style={{ width: windowSize < 800 ? '100%' : '' }}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{ height: '38px' }}
                    type='text'
                    placeholder='Webhook Url'
                  />
                </Form.Group>

                <Form.Group controlId='formGroupPipeline' style={{ width: windowSize < 800 ? '100%' : '' }}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name='name'
                    onChange={e => setName(e.target.value)}
                    value={name}
                    style={{ height: '38px' }}
                    type='text'
                    placeholder='Username'
                  />
                </Form.Group>
              </div>
              <div style={{ marginTop: '30px' }}>
                <Button variant='primary' onClick={handleConversationUpdate} disabled={conversationLoader}>
                  {conversationLoader ? <Spinner size='sm' /> : 'Save Conversations Settings'}
                </Button>
              </div>
            </Form>
          </>
          <Row className='mt-5 px-2'>
            <hr className='hr' />
          </Row>
          <Row>
            <p className='fw-bolder mb-2'>Email Notifications</p>

            <div className="d-flex justify-content-start gap-5">
              <p style={{ marginTop: '-2px', fontWeight: '500' }}>Enable or Disable the Client Welcome Email for new client creation in goHighLevel
                <span>({statusVal ? "Active" : "In-active"}) </span></p>
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={`toggleSwitch`}
                  checked={statusVal}
                  onChange={event => {
                    handleStatusModal(event.target.checked)
                  }}
                />

              </div>
            </div>
          </Row>



          <Row className='mt-5 px-2'>
            <hr className='hr' />
          </Row>
          <>
            <Row className='mt-2'>
              <p className='fw-bolder'> Calendar's Settings</p>
            </Row>
            <div>
              <div>
                {calenders?.map((item, index) => (
                  <Row key={index}>
                    <Form.Group as={Col} lg={4} className='mb-3' controlId={`formGroupGender_${index}`}>
                      <Select
                        isDisabled={true}
                        name='calendars'
                        getOptionLabel={option => `${option?.name}`}
                        getOptionValue={option => option?.id}
                        isClearable={true}
                        placeholder='choose calenders'
                        options={calenders}
                        value={calenders.filter(cal => calendersList[index]?.calendarId?.includes(cal?.id))}
                        onChange={selectedOptions => handleChange(index, 'calendarId', selectedOptions?.id)}
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={8} className='mb-3' controlId={`formGroupName_${index}`}>
                      <div className=''>
                        <Form.Control
                          name='url'
                          style={{ height: '38px' }}
                          value={calendersList[index]?.url}
                          onChange={e => handleChange(index, 'url', e.target.value)}
                          type='text'
                          placeholder='Webhook Url'
                        />
                      </div>
                    </Form.Group>
                  </Row>
                ))}
              </div>
              <div className='d-flex justify-content-end w-100'>
                <Button onClick={handleSaveCalenders} disabled={calLoader}>
                  {calLoader ? <Spinner size='sm' /> : 'Save'}
                </Button>
              </div>
            </div>
          </>

          <Row className='mt-5 px-2'>
            <hr className='hr' />
          </Row>
          <>
            <Row className='mt-2'>
              <p className='fw-bolder'>
                Mail Settings {windowSize < 400 && <br />}
                <span style={{ color: '#b6c1d2' }} className='fw-bolder fs-10'>
                  (Use Only GoDaddy Credentials)
                </span>
              </p>
            </Row>
            <Row className='mb-3 g-3 d-flex align-items-center'>
              <Form.Group as={Col} lg={4}>
                <Form.Control
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={mailSettings.userMail}
                  onChange={event =>
                    setMailSettings(prevSettings => ({
                      ...prevSettings,
                      userMail: event.target.value
                    }))
                  }
                />
              </Form.Group>

              <Form.Group as={Col} lg={4} className='position-relative'>
                <Form.Control
                  placeholder={'Password'}
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  value={mailSettings.userPassword}
                  onChange={event =>
                    setMailSettings(prevSettings => ({
                      ...prevSettings,
                      userPassword: event.target.value
                    }))
                  }
                />
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)' }}>
                  {showPassword ? (
                    <LuEye
                      onClick={togglePasswordVisibility}
                      style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
                    />
                  ) : (
                    <LuEyeOff
                      onClick={togglePasswordVisibility}
                      style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
                    />
                  )}
                </div>
              </Form.Group>

              <Col lg={4}>
                <Button onClick={handleMailSetting} disabled={mailSettignLoader}>
                  {' '}
                  {mailSettignLoader ? <Spinner size='sm' /> : 'Submit'}
                </Button>
              </Col>
            </Row>
          </>
        </Card>
      </Form>

      <DeleteSkillModal isOpenDeleteModal={isOpenDeleteModal} skillId={skillId} handleCloseModal={handleCloseModal} />
    </div>
  )
}

export default Settings
