import React, { useState, useRef, useEffect } from 'react'
import { Button, Form, Spinner, Overlay } from 'react-bootstrap'
import 'tinymce/skins/ui/oxide/skin.css'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import { toast } from 'react-toastify'
import { EditorState, Modifier, convertToRaw, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Picker from '@emoji-mart/react'
import { UplaodSMSEmailFile } from '../../../../redux/smsMail/action'
import { useSelector } from 'react-redux'
import { IoMdClose } from 'react-icons/io'
import { IoDocumentAttach } from 'react-icons/io5'
import draftToHtml from 'draftjs-to-html'
import { sendMessage } from 'utils/socket'
import { UplaodFile } from '../../../../redux/projects/action'

const Email = () => {
  const [editorUpIntro, setEditorUpIntro] = useState(() => EditorState.createEmpty())
  const [previewEmoji, setPreviewEmoji] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { Conversations } = useSelector(state => state.smsEmail)
  const { jobDetail } = useSelector(state => state.users)
  const emojiPickerRef = useRef(null)
  const [Files, setFiles] = useState([])
  const { hasCommandModifier } = KeyBindingUtil;
  const [plainText, SetPlainText] = useState('')



  const isWhitespaceString = str => !str.replace(/\s/g, '').length

  const handleEditorChange = editorState => {
    const contentState = editorState.getCurrentContent()
    const htmlContent = draftToHtml(convertToRaw(contentState))
    let div = document.createElement('div')
    div.innerHTML = htmlContent
    let text = div.textContent || div.innerText || ''
    SetPlainText(text)
    setEditorUpIntro(editorState)
  }
  const handleAddAttachment = async files => {


    if (!files || Object.keys(files).length === 0) return;
    setIsUploading(true);

    const formData = new FormData();
    const fileInfo = Object.values(files).map(file => ({
      filename: file.name,
      size: file.size,
      type: file.type
    }));
    Object.values(files).forEach(file => {
      formData.append('file', file);
    });
    formData.append('conversationId', Conversations[0]?.id);

    try {
      // Upload the files using the 'uploadInnerDocuments' API.
      const response = await UplaodFile(formData);
      if (response?.data?.status_code === 201 || response?.data?.status_code === 200) {
        const responseData = response?.data?.data || {};
        setIsUploading(false);

        const fileList = Object.entries(responseData).map(([filename, url]) => {

          return {
            name: fileInfo[0]?.filename,
            url,
            size: fileInfo[0]?.size ?? 0,
          };
        });
        // Append the uploaded files to the existing files state.
        setFiles(prevFiles => [...prevFiles, ...fileList]);
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        });
      } else {
        setIsUploading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsUploading(false);
    }
  };

  const handleRemoveAttachment = urlToRemove => {
    const updatedFiles = Files.filter(file => file.url !== urlToRemove)
    setFiles(updatedFiles)
  }

  const addEmoji = e => {
    const emoji = e.native
    const contentState = editorUpIntro.getCurrentContent()
    const selectionState = editorUpIntro.getSelection()
    const newContentState = Modifier.insertText(contentState, selectionState, emoji)
    const newEditorState = EditorState.push(editorUpIntro, newContentState, 'insert-characters')
    setEditorUpIntro(newEditorState)
    setPreviewEmoji(false)
  }

  const handleClickOutside = event => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setPreviewEmoji(false)
    }
  }
  const sendMessageFn = async () => {
    const contentState = editorUpIntro.getCurrentContent()
    const htmlContent = draftToHtml(convertToRaw(contentState))

    try {
      setIsSubmitting(true)
      let data = {
        project_id: jobDetail?.id,
        media: Files,
        message: htmlContent,
        type: 'team'
      }
      sendMessage(data)
      setIsSubmitting(false)
      setEditorUpIntro(() => EditorState.createEmpty())
      setFiles([])
    } catch (error) {
      setIsSubmitting(false)

      console.error('Error:', error)
    }
  }


  const handleKeyCommand = (command) => {
    if (command === 'submit-message') {
      if ((!editorUpIntro.getCurrentContent().hasText() && Files.length === 0) || isSubmitting || isWhitespaceString(plainText)) {
        console.log("")

      } else {
        sendMessageFn();

      }

    }
  };

  const keyBindingFn = (e) => {
    if (e.keyCode === 13 /* `Enter` key */ && !hasCommandModifier(e)) {
      let isFireFox = navigator.userAgent?.includes('Firefox')
      if (isFireFox) {
        return 'no-submit-message';
      } else {
        return 'submit-message';
      }
    }
    return getDefaultKeyBinding(e);
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid rgb(239 233 233)',
        borderTop: 'none',
        padding: '10px',
        borderRadius: '0px 0px 10px 10px'
      }}
    >
      <div className=' border-0 ' style={{ height: '140px' }}>
        <Editor
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          editorState={editorUpIntro}
          onEditorStateChange={handleEditorChange}
          placeholder='Type a message'
          wrapperClassName='wrapper-class'
          editorClassName='editor-class'
          toolbarClassName='toolbar-class'
          toolbar={{
            options: ['inline', 'blockType', 'list'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough']
            },

            list: {
              inDropdown: false,
              options: ['unordered', 'ordered']
            }
          }}
          wrapperStyle={{ height: window.innerWidth > 400 ? '100px' : '40px' }}
        />
      </div>

      <>
        <Flex alignItems='center' justifyContent='between'>
          <div className='d-flex flex-wrap gap-2'>
            {Files?.map((item, index) => (
              <div className='bg-200 text-dark py-1 px-2 d-flex gap-1 position-relative' key={index}>
                <IoDocumentAttach size={20} />
                <p className='mb-0'>{item?.name}</p>
                <p
                  onClick={() => handleRemoveAttachment(item?.url)}
                  className='mb-0'
                  style={{
                    position: 'absolute',
                    right: -20,
                    top: -10,
                    background: 'rgb(190 190 190)',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 1,
                    justifyContent: 'center',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
            ))}
          </div>
          <Flex>
            <Form.Group controlId='email-attachment'>
              <Form.Control
                type='file'
                multiple
                className='d-none'
                onChange={({ target }) => handleAddAttachment(target.files)}
              />

              {isUploading ? (
                <Spinner size='sm' />
              ) : (
                <IconButton
                  as={Form.Label}
                  className='me-2 mb-0'
                  variant='tertiary'
                  size='sm'
                  icon='paperclip'
                  iconClassName='fs-8'
                  transform='down-2'
                />
              )}
            </Form.Group>
            <Form.Group controlId='email-image'>
              <Form.Control
                type='file'
                className='d-none'
                accept='.jpg, .jpeg, .png, .xls, .xlsx, .doc, .docx, .pdf'
                multiple
                onChange={({ target }) => handleAddAttachment(target.files)}
              />
              <IconButton
                className='me-2 mb-0'
                variant='tertiary'
                onClick={e => setPreviewEmoji(!previewEmoji)}
                size='sm'
                icon={['far', 'laugh-beam']}
                iconClassName='fs-8'
                transform='down-2'
              />
              <Overlay target={emojiPickerRef?.current} show={previewEmoji} placement='top'>
                {({
                  placement: _placement,
                  arrowProps: _arrowProps,
                  show: _show,
                  popper: _popper,
                  hasDoneInitialMeasure: _hasDoneInitialMeasure
                }) => (
                  // {previewEmoji && (
                  <div className='chat-emoji-picker' dir='ltr' ref={emojiPickerRef}>
                    <Picker set='google' onEmojiSelect={addEmoji} previewPosition='none' skinTonePosition='none' />
                  </div>
                )}
              </Overlay>
            </Form.Group>
            <Button
              disabled={
                (!editorUpIntro.getCurrentContent().hasText() && Files.length === 0) || isSubmitting || isWhitespaceString(plainText)
                  ? true : false
              }
              onClick={() => sendMessageFn()}
              variant='primary'
              size='sm'

              style={{ cursor: 'pointer' }}
              className='px-4 px-sm-5 me-2 '
            >
              {isSubmitting ? <Spinner size='sm' /> : 'Send'}
            </Button>
          </Flex>
        </Flex>
      </>
    </div>
  )
}

export default Email
