export const ReducerType = {
    // basic Details
    GET_ALL_EVENTS_START: 'GET_ALL_EVENTS_START',
    GET_ALL_EVENTS: "GET_ALL_EVENTS",
    GET_ALL_EVENTS_ERROR: "GET_ALL_EVENTS_ERROR",

    GET_ALL_CALENDERS_START: 'GET_ALL_CALENDERS_START',
    GET_ALL_CALENDERS: "GET_ALL_CALENDERS",
    GET_ALL_CALENDERS_ERROR: "GET_ALL_CALENDERS_ERROR",

    GET_SAVED_CALENDERS: "GET_SAVED_CALENDERS",

    GET_SAVED_PIPELINE: "GET_SAVED_PIPELINE"
}