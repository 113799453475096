import React from 'react'
import { Button, Form, Modal, Spinner, Card } from 'react-bootstrap'
import { IoWarningOutline } from 'react-icons/io5'

export const LoginModal = ({ openLoginModal, handleCloseModal, loginModalError }) => {
  return (
    <Modal
      show={openLoginModal}
      onHide={handleCloseModal}
      aria-labelledby='example-custom-modal-styling-title'
      verticallyCentered
      centered
      size='md'
      style={{
        borderRadius: '20px'
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body
        style={{
          height: 'auto',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <IoWarningOutline style={{ color: '#e63757' }} size={42} />
        <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }}> {loginModalError}</p>

        <Button onClick={() => handleCloseModal()} style={{ height: '35px' }}>
          Back to login
        </Button>
        {/* <Link
          className='fs-10 '
          to='/mtc/auth/login'
          style={{
            color: 'rgb(104, 47, 204)',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FaChevronLeft style={{ color: 'rgb(104, 47, 204)', fontSize: '12px' }} />
          <span>Back to login</span>
        </Link> */}
      </Modal.Body>
    </Modal>
  )
}
