import React, { useState } from 'react'
import { Spinner, Card, Tooltip, OverlayTrigger, Button, Form } from 'react-bootstrap'
import EmptyImg from 'assets/dashboard/empty.svg'
import _ from 'lodash'
import Avatar from '../Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addProjectsAssets, getProjectSettingsDetail, deleteProjectsAssets } from '../../../../redux/projects/action'
import { v4 as uuidv4 } from 'uuid'
import { IoMdClose } from 'react-icons/io'
import dayjs from 'dayjs'
const Videos = () => {
  const { PorjectSettings, isProjectSettings } = useSelector(state => state.projects)
  const { userDetails } = useSelector(state => state.login)
  const [openEditor, setOpenEditor] = useState(false)
  const [loader, setLoader] = useState(false)
  const [urlVal, setUrlVal] = useState('')
  const [title, setTitle] = useState('')
  const { jobDetail, usersList } = useSelector(state => state.users)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const dispatch = useDispatch()
  const handleOpenEditor = () => {
    setOpenEditor(!openEditor)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoader(true)
    let params = {
      id: uuidv4(),
      projectId: jobDetail?.id,
      assetType: 3,
      title: title,
      url: urlVal,
      created_by: userDetails?._id,
      date: dayjs().valueOf(),
      source: 'conversion'
    }
    try {
      let res = await addProjectsAssets(params)
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        setLoader(false)
        dispatch(getProjectSettingsDetail({ projectId: jobDetail?.id }))
        setUrlVal('')
        setTitle('')
        setOpenEditor(false)
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      }
    } catch (err) {
      setTitle('')
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-right'
      })
      setLoader(false)
    }
  }

  const handleDelete = async id => {
    let params = {
      id: id,
      projectId: jobDetail?.id,
      assetType: 3
    }
    try {
      setDeleteLoader(true)
      setDeleteId(id)
      let res = await deleteProjectsAssets(params)
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        dispatch(getProjectSettingsDetail({ projectId: jobDetail?.id }))
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
        setDeleteLoader(false)
        setDeleteId('')
      }
    } catch (err) {
      console.log('err', err)
      setDeleteLoader(false)
      setDeleteId('')

      toast.error(err?.data?.message, {
        theme: 'colored',
        position: 'top-right'
      })
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-end w-100'>
        <Button onClick={handleOpenEditor}>Add Video </Button>
      </div>
      <div className='mb-1'>
        {openEditor && (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Title</Form.Label>
              <Form.Control placeholder='Title...' name='note' onChange={e => setTitle(e.target.value)} />
              <Form.Label>Add url</Form.Label>
              <Form.Control placeholder='https//' name='note' onChange={e => setUrlVal(e.target.value)} />
            </Form.Group>

            <div className='d-flex justify-content-end w-100 mt-2'>
              <Button disabled={loader || !urlVal} type='submit'>
                {loader ? <Spinner size='sm' /> : 'Submit'}
              </Button>
            </div>
          </Form>
        )}
      </div>
      <hr className='hr' />

      <p className='fs-9 fw-bolder'>Videos List</p>
      {PorjectSettings?.videoUrl?.length === 0 ? (
        <>
          <div style={{ width: '100%' }}>
            <div
              style={{
                height: '300px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <img src={EmptyImg} alt='empty' />
              <p className='fw-bolder mt-2 mb-1'>No Available Videos</p>
              <p className='fs-10 mt-2 mb-1'>Currently here is no videos for display</p>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{ width: '100%', overflow: 'hidden' }}
          className='d-flex align-items-center flex-wrap gap-2 align-content-start'
        >
          {!isProjectSettings ? (
            PorjectSettings?.videoUrl?.map((item, index) => {
              const convertedUrl = item?.url?.replace('https://streamable.com/', 'https://streamable.com/e/')
              let user = usersList?.find(user => user._id === item?.created_by)

              return (
                <Card style={{ padding: '10px', marginBottom: '20px', position: 'relative' }} key={index}>
                  <div className='d-flex align-item-center gap-2 justify-content-between w-100'>
                    <div className='d-flex align-item-center gap-2'>
                      <OverlayTrigger placement='top' overlay={<Tooltip>{user?.name ?? 'Admin'}</Tooltip>}>
                        <div>
                          <Avatar name={user?.name ?? 'A'} />
                        </div>
                      </OverlayTrigger>
                      <p className='fw-semibold fs-9'>{item?.title}</p>
                    </div>
                    {item?.created_by === userDetails?._id && (
                      <span
                        className=' d-flex  align-items-center'
                        style={{
                          width: '25px',
                          height: '25px',
                          top: -10,
                          borderRadius: '50%',
                          right: -8,
                          border: 'none',
                          backgroundColor: '#F5F1EE',
                          color: 'red',
                          padding: '5px'
                        }}
                      >
                        {deleteLoader && deleteId == item?.id ? (
                          <Spinner style={{ width: '14px', height: '14px' }} />
                        ) : (
                          <IoMdClose onClick={() => handleDelete(item?.id)} style={{ cursor: 'pointer' }} />
                        )}
                      </span>
                    )}
                  </div>
                  <iframe title='video' allow='fullscreen' allowFullScreen src={convertedUrl} style={{ width: 231 }} />
                </Card>
              )
            })
          ) : (
            <div className='d-flex flex-column align-items-center py-3' style={{ width: '100%' }}>
              <Spinner />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Videos
