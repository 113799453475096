import React from 'react';
import App from 'App';
import paths, { rootPaths } from './paths';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Dashboard from 'components/dashboards/default/index';
import Kanban from 'components/app/projects/Kanban';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import CardLogin from 'components/authentication/card/Login';
import CardLogout from 'components/authentication/card/Logout';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import CardConfirmMail from 'components/authentication/card/ConfirmMail';
import CardPasswordReset from 'components/authentication/card/PasswordReset';
import Users from 'components/app/users/Users'
import Clients from 'components/app/clients/Clients'

import ProjectDetail from 'components/app/projects/ProjectDetail';
import Profile from 'components/pages/user/settings/Settings';
import { isAdmin, isClient, isSales } from 'utils/constants';
import AdminSettings from 'components/app/settings/Settings'
import ClientProjects from 'components/clientLayout/pages/ClientProjects';
import Notification from 'components/app/notifications/notifications';
import SalesProjects from 'components/salesLayout/projects/ProjectsList'
const DashboardComponent = () => {
  return isAdmin() ? <Dashboard /> : <Navigate to={paths.kanban} />
}
const UsersComponent = () => {
  return isAdmin() ? <Users /> : <Navigate to={paths.kanban} />
}
const ClientComponent = () => {
  return isAdmin() ? <Clients /> : <Navigate to={paths.kanban} />
}
const SettingsComponent = () => {
  return isAdmin() ? <AdminSettings /> : <Navigate to={paths.kanban} />
}
const ProjectsComponent = () => {
  return isClient() ? <ClientProjects /> : isSales() ? <SalesProjects /> : <Kanban />
}


const routes = [
  {
    element: <App />,
    children: [
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        path: rootPaths.authRoot,
        children: [
          {
            path: rootPaths.authCardRoot,
            children: [
              {
                path: paths.cardLogin,
                element: <CardLogin />
              },

              {
                path: paths.cardLogout,
                element: <CardLogout />
              },
              {
                path: paths.cardForgotPassword,
                element: <CardForgetPassword />
              },
              {
                path: paths.cardResetPassword,
                element: <CardPasswordReset />
              },
              {
                path: paths.cardConfirmMail,
                element: <CardConfirmMail />
              },

            ]
          },
        ]
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <DashboardComponent />
          },
          {
            path: paths.kanban,
            element: <ProjectsComponent />
          },
          {
            path: paths.project_detail,
            element: <ProjectDetail />

          },
          {
            path: paths.users,
            element: <UsersComponent />
          },
          {
            path: paths.clients,
            element: <ClientComponent />
          },

          {
            path: paths.profile,
            element: <Profile />
          },
          {
            path: paths.notifications,
            element: <Notification />
          },
          {
            path: paths.settings,
            element: <SettingsComponent />
          },
        ]
      },
      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
