export const ReducerType = {
    // basic Details
    GET_ALL_CONVERSATION_START: 'GET_ALL_CONVERSATION_START',
    GET_ALL_CONVERSATION: "GET_ALL_CONVERSATION",
    GET_ALL_CONVERSATION_ERROR: "GET_ALL_CONVERSATION_ERROR",

    GET_CONVERSATION_MESSAGE_START: 'GET_CONVERSATION_MESSAGE_START',
    GET_CONVERSATION_MESSAGE: "GET_CONVERSATION_MESSAGE",
    GET_DEV_CONVERSATION_MESSAGE: "GET_DEV_CONVERSATION_MESSAGE",
    GET_CONVERSATION_MESSAGE_ERROR: "GET_CONVERSATION_MESSAGE_ERROR",

    GET_SMS_DETAIL: "GET_SMS_DETAIL",

    GET_EMAIL_DETAIL_STRAT: "GET_EMAIL_DETAIL_STRAT",
    GET_EMAIL_DETAIL: "GET_EMAIL_DETAIL",
    GET_EMAIL_DETAIL_UNSHIFT: "GET_EMAIL_DETAIL_UNSHIFT",
    GET_EMAIL_DETAIL_ERROR: "GET_EMAIL_DETAIL_ERROR",

    GET_OLDER_MESSAGES: "GET_OLDER_MESSAGES",
    GET_DEV_OLDER_MESSAGES: "GET_DEV_OLDER_MESSAGES",

    SEND_THREAD_MESSAGE: "SEND_THREAD_MESSAGE",

    REMOVE_STATES: "REMOVE_STATES",

    USER_SEND_MESSAGE: "USER_SEND_MESSAGE",

    GET_PROJECT_INPROGRESS_TIME: "GET_PROJECT_INPROGRESS_TIME"
}