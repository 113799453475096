import { apis } from 'utils/Apis'
import { checkResponse, get, put, post, deleteFn, postFileUpload } from 'utils/constants'
import { ReducerType } from './type'
import { ReducerType as CoversationType } from '../smsMail/type'
import { getALLUnreadCount } from '../groupChat/action'
export const getAllPipelines = () => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_PIPELINES_START
  })
  const res = await get(apis.pipelines)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_PIPELINES,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_PIPELINES_ERROR
    })
  }
}

export const getAllOpportunities = data => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_OPPORTUNITIES_START
  })
  const res = await get(apis.opportunities, data)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_OPPORTUNITIES,
      payload: response.data?.data
    })
    dispatch({
      type: ReducerType.MODIFIED_OPPORTUNITIES_ARRAY
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_OPPORTUNITIES_ERROR
    })
  }
}
export const getScrollOpportunities = data => async dispatch => {
  dispatch({
    type: ReducerType.GET_OLDER_PROJECTS_OF_STAGE_START
  })
  const res = await get(apis.opportunities, data)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_OLDER_PROJECTS_OF_STAGE,
      payload: response.data?.data
    })

    dispatch({
      type: ReducerType.MODIFIED_OPPORTUNITIES_ARRAY
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_OLDER_PROJECTS_OF_STAGE_ERROR
    })
  }
}

export const getAllDevOpportunities = data => async dispatch => {
  dispatch({
    type: ReducerType.GET_DEV_JOBS_START
  })
  const res = await get(apis.opportunities, data)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_DEV_JOBS,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_DEV_JOBS_ERROR
    })
  }
}

export const updateOpportunities = async data => {
  const res = await put(apis.opportunitiesUpdate, data)
  const response = await checkResponse(res)
  return response
}

export const addFollowers = async data => {
  const res = await post(apis.add_remove_followers, data)
  const response = await checkResponse(res)
  return response
}

export const removeFollowers = async data => {
  const res = await deleteFn(apis.add_remove_followers, data)
  const response = await checkResponse(res)
  return response
}
export const addTags = async data => {
  const res = await post(apis.add_remove_tags, data)
  const response = await checkResponse(res)
  return response
}

export const removeTags = async data => {
  const res = await deleteFn(apis.add_remove_tags, data)
  const response = await checkResponse(res)
  return response
}

export const addProjectsSettings = async data => {
  const res = await post(apis.add_project_settings, data)
  const response = await checkResponse(res)
  return response
}
export const editProjectsSettings = async data => {
  const res = await put(apis.edit_project_settings, data)
  return res
}

export const getProjectSettingsDetail = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_PROJECT_SETTINGS_DETAIL_START
  })
  dispatch({
    type: CoversationType.GET_PROJECT_INPROGRESS_TIME,
    payload: ''
  })
  const res = await get(apis.project_settings_detail, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_PROJECT_SETTINGS_DETAIL,
      payload: response.data?.data
    })
    dispatch({
      type: CoversationType.GET_PROJECT_INPROGRESS_TIME,
      payload: response.data?.data?.inProgressTime
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_PROJECT_SETTINGS_DETAIL_ERROR
    })
    dispatch({
      type: CoversationType.GET_PROJECT_INPROGRESS_TIME,
      payload: ''
    })
  }
}

export const getAllProjectsSettings = (params) => async dispatch => {
  const res = await get(apis.get_all_project_settings, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_PROJECT_SETTINGS_ALL,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    console.log('err')
  }
}

export const UplaodFile = async data => {
  const res = await postFileUpload(apis.upload_file, data)
  return res
}

export const AssignMe = async data => {
  const res = await put(apis.assign_project, data)
  return res
}

export const addProjectsAssets = async data => {
  const res = await post(apis.add_projects_assets, data)
  return res
}
export const editProjectsAssets = async data => {
  const res = await put(apis.edit_project_assets, data)
  return res
}

export const deleteProjectsAssets = async data => {
  const res = await deleteFn(apis.delete_project_assets, data)
  return res
}
export const deleteOpportunity = async data => {
  const res = await deleteFn(apis.delete_opportunities, data)
  return res
}

export const clientCountDashboard = async data => {
  const res = await get(apis.dashboard_client_count, data)
  return res
}

export const handleAddProjectBugs = async data => {
  const res = await post(apis.add_project_bugs, data)
  const response = await checkResponse(res)
  return response
}

export const getAllProjectBugList = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_PROJECT_BUGS_LIST_START
  })
  const res = await get(apis.project_bugs_list, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_PROJECT_BUGS_LIST,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_PROJECT_BUGS_LIST_ERROR
    })
  }
}

export const changeProjectBugStatus = async data => {
  const res = await put(apis.project_bug_status_change, data)
  const response = await checkResponse(res)
  return response
}

export const addClientDocs = async data => {
  const res = await put(apis.client_add_docs, data)
  return res
}
