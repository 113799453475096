import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import paths from 'routes/paths'
import { useDispatch, useSelector } from 'react-redux'
import { doLogin, ssoLogin, resendOtp } from '../../redux/login/action'
import OptScreen from './card/Otp'
import authConfig from '../../utils/authConfig'
import { Encrypt } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { LuEyeOff, LuEye } from 'react-icons/lu'
import { useGoogleLogin } from '@react-oauth/google'
import { FcGoogle } from 'react-icons/fc'
import { toast } from 'react-toastify'
import { LoginModal } from './LoginModal'

const forgotPasswordPaths = {
  simple: paths.simpleForgotPassword,
  split: paths.splitForgotPassword,
  card: paths.cardForgotPassword
}

const LoginForm = ({ hasLabel, layout, email }) => {
  // State
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { isLogin, loading, userDetails } = useSelector(state => state.login)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  })
  const [ssoMail, setSSOMail] = useState('')
  const [ssoId, setSSOId] = useState('')

  const [modalShow, setModalShow] = React.useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isSsoSignUp, setIsSsoSignUp] = useState(false)
  const [otpErrorMessage, setOtpErrorMessage] = useState('')
  const [loginModalError, setLoginModalError] = useState('')
  const [openLoginModal, setOpenLoginModal] = useState(false)

  useEffect(() => {
    if (email) {
      setFormData({ ...formData, email: email })
    }
  }, [email])
  const handleSubmit = e => {
    e.preventDefault()
    dispatch(doLogin({ email: formData.email, password: formData.password }))
  }
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    if (isLogin && !userDetails) {
      setModalShow(true)
    }
  }, [isLogin, userDetails])

  useEffect(() => {
    if (userDetails && !isLogin) {
      const role = Encrypt(userDetails?.userType)
      const IncrypedToken = Encrypt(userDetails?.token)
      if (userDetails?.token) {
        window.localStorage.setItem(authConfig.rolesKey, role)
        window.localStorage.setItem(authConfig.storageTokenKeyName, IncrypedToken)
        if (userDetails?.userType == 1) {
          navigate('/')
        } else {
          navigate('/app/projects')
        }
      }
    }
  }, [userDetails, isLogin])
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const { access_token } = tokenResponse
        const profileResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        })
        const profileData = await profileResponse.json()
        let res = await dispatch(ssoLogin({ email: email, ssoEmail: profileData.email, googleId: profileData.id }))
        if (res?.data?.data?.status_code == 200 || res?.data?.data?.status_code == 201) {
          dispatch({
            type: 'OTP_VERIFIED',
            payload: res?.data?.data?.data
          })
          toast.success(res?.data?.data?.message, {
            position: 'top-right',
            theme: 'colored'
          })
        } else {
          if (res?.data?.data?.status_code == 408) {
            setLoginModalError(res?.data?.data?.message)
            setOpenLoginModal(true)
            return
          }
          setSSOId(profileData.id)
          setSSOMail(profileData.email)
          await resendOtp({
            email: email,
            type: 2,
            ssoEmail: profileData.email
          })
          setIsSsoSignUp(true)
          setModalShow(true)

          if (res?.data?.data?.status_code == 422) {
            setOtpErrorMessage(res?.data?.data?.message)
          }
        }
      } catch (error) {
        console.error('Error fetching profile data:', error)
      }
    }
  })

  const handleCloseModal = () => {
    setOpenLoginModal(false)
    setLoginModalError('')
    navigate('/mtc/auth/login')
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group className='mb-3'>
          {/* {hasLabel && <Form.Label>Email address</Form.Label>} */}
          <Form.Control
            style={{ backgroundColor: '#FFFFFF', borderRadius: '50px', padding: '10px 20px', boxShadow: 'none' }}
            placeholder={'Email'}
            value={email}
            disabled={true}
            name='email'
            onChange={handleFieldChange}
            type='email'
          />
        </Form.Group>

        <Form.Group className='mb-3 position-relative'>
          {/* {hasLabel && <Form.Label>Password</Form.Label>} */}
          <Form.Control
            style={{ backgroundColor: '#FFFFFF', borderRadius: '50px', padding: '10px 20px', boxShadow: 'none' }}
            placeholder={'Password'}
            value={formData.password}
            name='password'
            onChange={handleFieldChange}
            type={showPassword ? 'text' : 'password'}
          />
          <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>
            {showPassword ? (
              <LuEye
                onClick={togglePasswordVisibility}
                style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
              />
            ) : (
              <LuEyeOff
                onClick={togglePasswordVisibility}
                style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
              />
            )}
          </div>
        </Form.Group>

        <Row className='justify-content-end align-items-center'>
          {/* <Col xs='auto'>
          <Form.Check type='checkbox' id='rememberMe' className='mb-0'>
            <Form.Check.Input
              type='checkbox'
              name='remember'
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className='mb-0 text-700'>Remember me</Form.Check.Label>
          </Form.Check>
        </Col> */}

          <Col xs='auto'>
            <Link className='fs-10 mb-0 text-decoration-none' to={forgotPasswordPaths[layout]}>
              <h6 style={{ color: '#808080', fontWeight: '500' }}>Forgot Password?</h6>
            </Link>
          </Col>
        </Row>

        <Form.Group className='mb-3'>
          <Button
            type='submit'
            color='primary'
            className='mt-3 w-100'
            disabled={!formData.email || !formData.password || loading}
            style={{
              backgroundColor: '#682FCC',
              border: 'none',
              borderRadius: '50px',
              padding: '10px'
            }}
          >
            {loading ? <Spinner animation='border' size='sm' /> : 'Login'}
          </Button>
        </Form.Group>
        {/* <div style={{ width: '100%', display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
        <p style={{ color: '#808080', fontSize: '14px', fontWeight: '400' }}>
          Don't have an account?{' '}
          <Link
            style={{ color: '#682FCC', fontWeight: '400', fontSize: '14px', textDecoration: 'none' }}
            to='https://mytradingcoder.com/'
            target='_blank'
          >
            Signup
          </Link>
        </p>
      </div> */}

        <OptScreen
          show={modalShow}
          onHide={() => setModalShow(false)}
          ssoId={ssoId}
          email={formData.email}
          ssoEmail={ssoMail}
          isSsoSignUp={isSsoSignUp}
          otpErrorMessage={otpErrorMessage}
          setOtpErrorMessage={setOtpErrorMessage}
        />
      </Form>
      <div className='d-flex flex-column justify-content-center align-items-center mt-4 w-100'>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ flexGrow: 1, height: '1px', backgroundColor: 'rgb(215 215 215)', width: '100%' }}></div>
          <p style={{ margin: '0 10px', width: '90%', textAlign: 'center' }}>Connect with Google</p>
          <div style={{ flexGrow: 1, height: '1px', backgroundColor: 'rgb(215 215 215)', width: '100%' }}></div>
        </div>{' '}
        <div className='mt-3'>
          <FcGoogle onClick={() => login()} size={40} style={{ cursor: 'pointer' }} />
        </div>
      </div>

      <LoginModal
        openLoginModal={openLoginModal}
        handleCloseModal={handleCloseModal}
        loginModalError={loginModalError}
      />
    </>
  )
}

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
}

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
}

export default LoginForm
