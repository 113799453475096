import React, { useEffect, useState } from 'react'
import { GiCheckedShield } from 'react-icons/gi'

export const ChatPermissionContent = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 180)
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight - 180)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <>
      <div
        style={{
          height: `${windowHeight}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <GiCheckedShield size={100} />
        <div>
          <h5 style={{ fontSize: '15px', fontWeight: '600', textAlign: 'center', color: '#000' }}>
            {' '}
            Sorry you do not have Chat Permisson
          </h5>
          <p style={{ textAlign: 'center', color: 'rgb(153, 153, 153)' }}>
            Unfortunately, chat permissions are currently restricted.
            <br /> You will need to wait until an administrator grants you permission or <br />
            contact an administrator directly to request access.
          </p>
        </div>
      </div>
    </>
  )
}
