import { ReducerType } from './type'

const InitialState = {
    isNotifications: false,
    isOldNotifications: false,
    notificationsList: [],
    latestNotifications: [],
    moreNotifications: false
}

const notifications = (state = InitialState, action) => {
    switch (action?.type) {
        case ReducerType.GET_ALL_NOTIFICATION_ACTION_START:
            return {
                ...state,
                isNotifications: true,
            }
        case ReducerType.GET_ALL_NOTIFICATION:
            return {
                ...state,
                notificationsList: action.payload,
                isNotifications: false,
                moreNotifications: action.payload?.length > 19 ? true : false
            }
        case ReducerType.GET_LATEST_NOTIFICATION:
            return {
                ...state,
                latestNotifications: action.payload,
                isNotifications: false
            }



        case ReducerType.GET_PUSH_NOTIFICATION:
            return {
                ...state,
                latestNotifications: [action.payload, ...state.latestNotifications],
                isNotifications: false
            }


        case ReducerType.GET_OLD_NOTIFICATIONS_START:
            return {
                ...state,
                isOldNotifications: true,
            }

        case ReducerType.GET_OLD_NOTIFICATIONS:
            return {
                ...state,
                notificationsList: [...state.notificationsList, ...action.payload],
                isOldNotifications: false,
                moreNotifications: action.payload?.length > 19 ? true : false
            }


        case ReducerType.GET_OLD_NOTIFICATIONS_ERROR:
            return {
                ...state,
                notificationsList: [...state.notificationsList],
                isNotifications: false,
                moreNotifications: false,
                isOldNotifications: false,
            }

        case ReducerType.READ_SINGLE_NOTIFICATION:
            const { notification_id } = action.payload;

            const updatedLatestNotifications = state.latestNotifications.map(notification => {
                if (notification._id === notification_id) {
                    return { ...notification, is_read: 1 };
                }
                return notification;
            });

            const updatedNotificationsList = state.notificationsList.map(notification => {
                if (notification._id === notification_id) {
                    return { ...notification, is_read: 1 };
                }
                return notification;
            });

            return {
                ...state,
                latestNotifications: updatedLatestNotifications,
                notificationsList: updatedNotificationsList
            };

        case ReducerType.READ_ALL_NOTIFICATIONS:
            return {
                ...state,
                latestNotifications: state.latestNotifications.map(notification => ({
                    ...notification,
                    is_read: 1
                })),
                notificationsList: state.notificationsList.map(notification => ({
                    ...notification,
                    is_read: 1
                }))
            };

        case ReducerType.GET_ALL_NOTIFICATION_ERROR:
            return {
                ...state,
                notificationsList: [],
                isNotifications: false
            }

        default:
            return state
    }
}
export default notifications
