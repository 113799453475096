import React from 'react'
import CryptoJS from 'crypto-js'
import authConfig from './authConfig'
import axios from 'axios'
import { toast } from 'react-toastify'
import Img from 'assets/dashboard/img.svg'
import Pdf from 'assets/dashboard/pdf.svg'
import Doc from 'assets/dashboard/doc.svg'
import Xls from 'assets/dashboard/xls.svg'
import Video from 'assets/dashboard/video.svg'
import Zip from 'assets/dashboard/zip.svg'
import Code from 'assets/dashboard/code.svg'
import Ppt from 'assets/dashboard/ppt.svg'

import { disconnectSoket } from './socket'
export const constants = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL
}
const cryptoSecret = process.env.REACT_APP_ENCRYPT_SECRET_KEY
export function ShiftToLogin() {
  removeCurrentUser()
  window.location.href = '/mtc/auth/login'
  // window.location.reload()
  isAdmin(true)
}
export function RemoveAllTokens() {
  removeCurrentUser()
  disconnectSoket()
  isAdmin(true)
}

export function base64Encoded(str) {
  try {
    return btoa(unescape(encodeURIComponent(str)));
  } catch (e) {
    return null;
  }
}

// Function to decode a base64 string
export function base64Decoded(base64) {
  try {
    return decodeURIComponent(escape(atob(base64)));
  } catch (e) {
    return null;
  }
}


export function Encrypt(values) {
  const encJson = CryptoJS.AES.encrypt(JSON.stringify(values), cryptoSecret).toString()
  const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
  return encData
}
export function Decrypt(values) {
  try {
    const decData = CryptoJS.enc.Base64.parse(values == null ? '' : values)?.toString(CryptoJS.enc.Utf8)
    const bytes = CryptoJS.AES.decrypt(decData, cryptoSecret).toString(CryptoJS.enc.Utf8)
    return bytes ? JSON.parse(bytes) : ShiftToLogin()
  } catch (err) {
    ShiftToLogin()
  }
}

export const getCurrentUser = () => {
  let token = localStorage.getItem(authConfig.storageTokenKeyName) || null
  let decryptedToken = Decrypt(token)
  return decryptedToken ? (Object.keys(decryptedToken).length > 0 ? decryptedToken : null || null) : null
}

/**
 * Sets the current user data in the local storage after encrypting the provided token.
 * @param data - The user data to be stored, typically containing a token.
 */
export const setCurrentUser = data => {
  let encryptedToken = Encrypt(data?.token)
  localStorage.setItem(authConfig.storageTokenKeyName, encryptedToken)
}
export const removeCurrentUser = () => {
  localStorage.removeItem(authConfig.storageTokenKeyName)
  localStorage.removeItem(authConfig.rolesKey)
  localStorage.removeItem('fcmToken')

}

export const getWithOutBase = async (url, params) => {
  const token = getCurrentUser()
  return await axios({
    method: 'get',
    url: `${url}`,
    headers: { Authorization: `Bearer ${token}` },
    params: params
  })
    .then(response => response)
    .catch(err => err.response)
}

export const get = async (url, params) => {
  const token = getCurrentUser()
  return await axios({
    method: 'get',
    url: `${constants.apiBaseUrl}${url}`,
    headers: { Authorization: `Bearer ${token}` },
    params: params
  })
    .then(response => response)
    .catch(err => err.response)
}

export const post = async (url, data) => {
  const token = getCurrentUser()
  return await axios({
    method: 'post',
    url: `${constants.apiBaseUrl}${url}`,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  })
    .then(response => response)
    .catch(err => {
      toast.error(err?.message, {
        position: 'top-right',
        theme: 'colored'
      })
    })
}
export const postWithoutToken = async (url, data) => {
  return await axios({
    method: 'post',
    url: `${constants.apiBaseUrl}${url}`,
    data: data
  })
    .then(response => response)
    .catch(err => err.response)
}
export const put = async (url, data) => {
  const token = getCurrentUser()
  return await axios({
    method: 'put',
    url: `${constants.apiBaseUrl}${url}`,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
    .then(response => response)
    .catch(err => err.response)
}
export const putWithOutToken = async (url, data) => {
  return await axios({
    method: 'put',
    url: `${constants.apiBaseUrl}${url}`,
    data
  })
    .then(response => response)
    .catch(err => err.response)
}
export const deleteFn = async (url, data) => {
  const token = getCurrentUser()
  return await axios({
    method: 'delete',
    url: `${constants.apiBaseUrl}${url}`,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  })
    .then(response => response)
    .catch(err => err.response)
}

export const checkResponse = async response => {
  let newResponse = {
    code: '',
    success: false,
    data: null,
    message: ''
  }
  try {
    const { data } = response
    if (data.status_code === 200 || data.status_code === 201) {
      newResponse = {
        code: data.status_code,
        success: true,
        data: data || null,
        message: data.message,
        errorMessages: []
      }
    } else if (data?.status_code === 401) {
      ShiftToLogin()
      toast.error(data.message, {
        position: 'top-right',
        theme: 'colored'
      })
    } else if (data?.status_code === 403) {
      window.location.href = '/app/projects'
      toast.error(data.message, {
        position: 'top-right',
        theme: 'colored'
      })
    }
    //  else if (data?.status_code === 404) {
    //   console.log("err")
    // }
    else {
      toast.error(data?.message, {
        position: 'top-right',

        theme: 'colored'
      })
    }
  } catch (error) {
    console.log('errror on common response ==>', error)
    toast.error('Something went wrong !', {
      position: 'top-right',

      theme: 'colored'
    })
  }

  return newResponse
}

export const checkUserDetailResponse = async response => {
  let newResponse = {
    code: '',
    success: false,
    data: null,
    message: ''
  }
  try {
    const { data } = response
    if (data.status_code === 200 || data.status_code === 201) {
      newResponse = {
        code: data.status_code,
        success: true,
        data: data || null,
        message: data.message,
        errorMessages: []
      }
    } else if (data?.status_code === 401) {
      ShiftToLogin()
      toast.error(data.message, {
        position: 'top-right',
        theme: 'colored'
      })
    } else if (data?.status_code === 404) {
      ShiftToLogin()
      toast.error(data.message, {
        position: 'top-right',
        theme: 'colored'
      })
    } else {
      toast.error(data?.message, {
        position: 'top-right',

        theme: 'colored'
      })
    }
  } catch (error) {
    console.log('errror on common response ==>', error)
    toast.error('Something went wrong !', {
      position: 'top-right',

      theme: 'colored'
    })
  }

  return newResponse
}

export const getRandomColor = () => {
  const colors = [
    '#3498db', // Blue
    '#2ecc71', // Green
    '#ffb6c1', // Light Pink (replacing red)
    '#9b59b6', // Purple
    '#f1c40f', // Yellow
    '#1abc9c', // Turquoise
    '#f39c12', // Orange
    '#16a085' // Greenish Blue
  ]

  return colors[Math.floor(Math.random() * colors.length)]
}

export const getKanbanRandomColor = () => {
  const colors = [
    '#3498db', // Blue
    '#2ecc71', // Green
    '#ffb6c1', // Light Pink (replacing red)
    '#9b59b6', // Purple
    '#f1c40f', // Yellow
    '#1abc9c', // Turquoise
    '#f39c12', // Orange
    '#16a085', // Greenish Blue
    '#e74c3c', // Red
    '#34495e', // Dark Blue
    '#95a5a6', // Gray
    '#d35400', // Pumpkin
    '#7f8c8d' // Dark Gray
  ]

  // Ensure no color repeats itself
  const index = Math.floor(Math.random() * colors.length)
  const selectedColor = colors.splice(index, 1)[0]

  return selectedColor
}

export const isAdmin = logout => {
  let IncrypteduserType = localStorage.getItem(authConfig.rolesKey)
  let userToken = localStorage.getItem(authConfig.storageTokenKeyName)
  let userType = ''
  if (IncrypteduserType && userToken) {
    userType = Decrypt(IncrypteduserType)
  }
  if (logout) {
    userType = ''
  }

  return userType === 1 ? true : false
}
export const isDeveloper = logout => {
  let IncrypteduserType = localStorage.getItem(authConfig.rolesKey)
  let userToken = localStorage.getItem(authConfig.storageTokenKeyName)
  let userType = ''
  if (IncrypteduserType && userToken) {
    userType = Decrypt(IncrypteduserType)
  }
  if (logout) {
    userType = ''
  }

  return userType === 2 ? true : false
}
export const isClient = logout => {
  let IncrypteduserType = localStorage.getItem(authConfig.rolesKey)
  let userToken = localStorage.getItem(authConfig.storageTokenKeyName)
  let userType = ''
  if (IncrypteduserType && userToken) {
    userType = Decrypt(IncrypteduserType)
  }
  if (logout) {
    userType = ''
  }

  return userType === 3 ? true : false
}
export const isSales = logout => {
  let IncrypteduserType = localStorage.getItem(authConfig.rolesKey)
  let userToken = localStorage.getItem(authConfig.storageTokenKeyName)
  let userType = ''
  if (IncrypteduserType && userToken) {
    userType = Decrypt(IncrypteduserType)
  }
  if (logout) {
    userType = ''
  }

  return userType === 4 ? true : false
}

export const renderDocsView = file => {
  if (file?.type?.toLowerCase()?.startsWith('image') || ['.png', '.jpeg', '.jpg', '.svg'].some(extension => file?.toLowerCase()?.endsWith(extension))) {
    return <img src={Img} style={{ width: '30px', height: '50px' }} />
  } else if (['.pdf'].some(extension => file?.toLowerCase()?.endsWith(extension))) {
    return <img src={Pdf} style={{ width: '30px', height: '50px' }} />
  } else if (['.doc', '.docx'].some(extension => file?.toLowerCase()?.endsWith(extension))) {
    return <img src={Doc} style={{ width: '30px', height: '50px' }} />
  } else if (['.xls', '.xlsx'].some(extension => file?.toLowerCase()?.endsWith(extension))) {
    return <img src={Xls} style={{ width: '30px', height: '50px' }} />
  }
  else if (['.ppt', '.pptx', '.pptm', '.pot', '.potx', '.potm', '.pps', '.ppsx', '.ppsm'].some(extension => file?.toLowerCase()?.endsWith(extension))) {
    return <img src={Ppt} style={{ width: '30px', height: '50px' }} />
  }

  else if (['.webm', '.mkv', '.gif', '.avi', '.mov', '.amv', '.mp4', '.m4p', '.m4v', '.3gp'].some(extension => file?.toLowerCase()?.endsWith(extension))) {
    return <img src={Video} style={{ width: '30px', height: '50px' }} />
  } else if (['.zip', '.7z', '.rar', '.tar', '.gz', '.bz2', '.xz', '.tgz', '.tbz', '.txz', '.z', '.lz', '.cab', '.iso', '.jar', '.deb', '.rpm', '.sit', '.sea'].some(extension => file?.toLowerCase()?.endsWith(extension))) {
    return <img src={Zip} style={{ width: '30px', height: '50px' }} />
  } else if (['.c', '.cpp', '.pine', '.java', '.py', '.html', '.css', '.js', '.php', '.rb', '.swift', '.go', '.pl', '.sql', '.xml', '.json', '.yaml', '.yml', '.ini', '.cfg', '.conf'].some(extension => file?.toLowerCase()?.endsWith(extension))) {
    return <img src={Code} style={{ width: '30px', height: '50px' }} />
  }
  else {
    return <img src={Doc} style={{ width: '30px', height: '50px' }} />
  }
}

export const getWithoutToken = async (url, params) => {
  return await axios({
    method: 'get',
    url: `${constants.apiBaseUrl}${url}`,
    params: params
  })
    .then(response => response)
    .catch(err => err.response)
}

export function formatBudget(num, precision = 1) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 }
    // { suffix: '', threshold: 1 }
  ]
  const found = map.find(x => Math.abs(num) >= x.threshold)
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + ' ' + found.suffix
    return formatted
  }

  return num ?? 0
}

export const postFileUpload = async (url, data) => {
  const token = getCurrentUser()
  return await axios({
    method: 'post',
    url: `${constants.apiBaseUrl}${url}`,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  })
    .then(response => response)
    .catch(err => {
      toast.error(err?.message, {
        position: 'top-right',
        theme: 'colored'
      })

      return { message: err?.message, status_code: 413 }
    })
}


export const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));
  return parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const getDeviceId = () => {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const randomString = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14)
  const deviceID = `${userAgent}-${platform}-${randomString}`
  return deviceID
}


export const notificationsArr = [
  {
    id: 111, name: 'Media Notifications', child: [
      { id: 1, name: 'Note Added', value: 'noteAdd' },
      { id: 2, name: 'Document Added', value: 'documentAdd' },
      { id: 3, name: 'Video Added', value: 'videoAdd' },
      { id: 4, name: 'Note Deleted', value: 'noteDelete' },
      { id: 5, name: 'Document Deleted', value: 'documentDelete' },
      { id: 6, name: 'Video Deleted', value: 'videoDelete' },

    ]
  },
  {
    id: 222, name: "Client Chat Doc's Notifications", child: [
      { id: 7, name: "Client Chat Doc's Added", value: 'clientDocumentAdd' },
      { id: 8, name: "Client Chat Doc's Deleted", value: 'clientDocumentDelete' },
    ]
  },
  {
    id: 333, name: 'Bugs Notifications', child: [
      { id: 9, name: 'Bug Raised', value: 'bugRaise' },
      { id: 10, name: 'Bug Fixed', value: 'bugFix' },
    ]
  },
  {
    id: 444, name: 'Stage Change Notifications', child: [
      { id: 11, name: 'Stage Updated', value: 'stageUpdate' },
    ]
  },
  {
    id: 555, name: 'Messages Notifications', child: [
      { id: 12, name: 'Chat Messages', value: 'sendMessage' },
    ]
  },

]

export const clientNotificationsArr = [

  {
    id: 222, name: "Document's Notifications", child: [
      { id: 7, name: "Document's Added", value: 'clientDocumentAdd' },
      { id: 8, name: "Document's Deleted", value: 'clientDocumentDelete' },
    ]
  },
  {
    id: 333, name: 'Bugs Notifications', child: [
      { id: 9, name: 'Bug Raised', value: 'bugRaise' },
      { id: 10, name: 'Bug Fixed', value: 'bugFix' },
    ]
  },
  {
    id: 444, name: 'Stage Change Notifications', child: [
      { id: 11, name: 'Stage Updated', value: 'stageUpdate' },
    ]
  },
  {
    id: 555, name: 'Messages Notifications', child: [
      { id: 12, name: 'Chat Messages', value: 'sendMessage' },
    ]
  },

]
