import {
  postWithoutToken,
  checkResponse,
  putWithOutToken,
  get,
  put,
  checkUserDetailResponse
} from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'

export const doLogin = data => async dispatch => {
  dispatch({ type: ReducerType.LOGIN_ACTION_START })

  try {
    const res = await postWithoutToken(apis.login, data)
    const response = await checkResponse(res)

    if (response?.success) {
      if (response?.data?.data) {
        dispatch({
          type: ReducerType.OTP_VERIFIED,
          payload: response.data.data
        })
      } else {
        dispatch({
          type: ReducerType.LOGGED_IN,
          payload: response.data
        })
      }
      return { data: response.data }
    } else {
      dispatch({
        type: ReducerType.LOGIN_ERROR,
        payload: response?.errorMessages
      })
    }
  } catch (error) {
    console.error('An unexpected error occurred during login:', error)
    dispatch({
      type: ReducerType.LOGIN_ERROR,
      payload: 'An unexpected error occurred. Please try again later.'
    })
  }
}
export const VerifyOtp = data => async dispatch => {
  dispatch({
    type: ReducerType.LOGIN_ACTION_START
  })
  const res = await putWithOutToken(apis.verifyOtp, data)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.OTP_VERIFIED,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.LOGIN_ERROR,
      payload: response?.errorMessages
    })
  }
}

export const ssoLogin = data => async dispatch => {
  dispatch({
    type: ReducerType.LOGIN_ACTION_START
  })
  const res = await postWithoutToken(apis.sso_login, data)
  if (res?.data?.data?.status_code == 201 || res?.data?.data?.status_code == 200) {
    return { data: res }
  } else {
    dispatch({
      type: ReducerType.LOGIN_ERROR,
      payload: res?.data?.data?.message
    })
    return { data: res }
  }
  // const response = await checkResponse(res)
  // if (response?.success) {
  //   dispatch({
  //     type: ReducerType.OTP_VERIFIED,
  //     payload: response.data?.data
  //   })
  //   return { data: response.data }
  // } else {
  //   dispatch({
  //     type: ReducerType.LOGIN_ERROR,
  //     payload: response?.errorMessages
  //   })
  // }
}

export const getUserDetail = () => async dispatch => {
  dispatch({
    type: ReducerType.LOGIN_ACTION_START
  })
  const res = await get(apis.get_user_detail)
  const response = await checkUserDetailResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.OTP_VERIFIED,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.LOGIN_ERROR,
      payload: response?.errorMessages
    })
  }
}

export const resendOtp = async data => {
  const res = await postWithoutToken(apis.resend_otp, data)
  const response = await checkResponse(res)
  if (response?.success) {
    return { data: response.data }
  }
}

export const disable2Fa = async data => {
  const res = await put(apis.disable_2FA_Auth, data)
  const response = await checkResponse(res)
  if (response?.success) {
    return { data: response.data }
  }
}

export const checkUserExist = async data => {
  const res = await postWithoutToken(apis.check_user_exist, data)
  return { data: res }
}

export const updateSSO = async data => {
  const res = await postWithoutToken(apis.update_sso, data)
  return { data: res }
}

export const logoutFn = async (data) => {
  const res = await put(apis.logout, data)
  return { data: res }

}