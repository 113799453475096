import { ReducerType } from './type'
import _ from 'lodash'
const InitialState = {
    groupMessages: [],
    isGroupMessages: false,
    noMoreOlderMessages: false,

    clientMessages: [],
    isClientMessages: false,
    noMoreClientOlderMessages: false,
    unreadCount: [{ type: 'team', count: 0 },
    { type: 'client', count: 0 }],

    allUnreadCounts: [
        { type: 'team', count: 0, room_id: '' },
        { type: 'client', count: 0, room_id: "" }],
}

const groupChat = (state = InitialState, action) => {
    switch (action?.type) {
        case ReducerType.GET_GROUP_CHAT_LIST_START:
            return {
                ...state,
                isGroupMessages: true,
            }
        case ReducerType.GET_GROUP_CHAT_LIST:
            return {
                ...state,
                isGroupMessages: false,
                noMoreOlderMessages: action.payload?.length > 19 ? false : true,
                groupMessages: action.payload

            }

        case ReducerType.GET_GROUP_CHAT_LIST_ERROR:
            return {
                ...state,
                isGroupMessages: false,
                groupMessages: []
            }
        case ReducerType.GET_SENDED_MESSAGE:
            const updatedMessages = [action.payload, ...state.groupMessages];
            const uniqueMessages = _.uniqBy(updatedMessages, '_id');
            return {
                ...state,
                isGroupMessages: false,
                groupMessages: uniqueMessages
            };
        case ReducerType.GET_OLDER_GROUP_MESSAGES:
            const updatedOlderMessages = [...state.groupMessages, ...action.payload];
            const uniqueMessagesArr = _.uniqBy(updatedOlderMessages, '_id');
            return {
                ...state,
                isGroupMessages: false,
                groupMessages: uniqueMessagesArr,
                noMoreOlderMessages: action.payload?.length > 19 ? false : true
            };



        //Client chat section



        case ReducerType.GET_CLIENT_CHAT_LIST_START:
            return {
                ...state,
                isClientMessages: true,
            }
        case ReducerType.GET_CLIENT_CHAT_LIST:
            return {
                ...state,
                noMoreClientOlderMessages: action.payload?.length > 19 ? false : true,
                clientMessages: action.payload,
                isClientMessages: false,


            }

        case ReducerType.GET_CLIENT_CHAT_LIST_ERROR:
            return {
                ...state,
                isClientMessages: false,
                clientMessages: []
            }
        case ReducerType.GET_CLIENT_SENDED_MESSAGE:
            const updatedCMessages = [action.payload, ...state.clientMessages];
            const uniqueCMessages = _.uniqBy(updatedCMessages, '_id');
            return {
                ...state,
                isClientMessages: false,
                clientMessages: uniqueCMessages
            };
        case ReducerType.GET_OLDER_CLIENT_MESSAGES:
            const updatedCOlderMessages = [...state.clientMessages, ...action.payload];
            const uniqueCMessagesArr = _.uniqBy(updatedCOlderMessages, '_id');
            return {
                ...state,
                isClientMessages: false,
                clientMessages: uniqueCMessagesArr,
                noMoreClientOlderMessages: action.payload?.length > 19 ? false : true
            };
        case ReducerType.GET_UNREAD_COUNT:
            return {
                ...state,
                unreadCount: action.payload?.length > 0 ? action.payload : [
                    { type: 'team', count: 0 },
                    { type: 'client', count: 0 }
                ]
            }


        case ReducerType.INCREASE_COUNT:

            return {
                ...state,
                unreadCount: state.unreadCount.map(obj =>
                    obj.type === action.payload ? { ...obj, count: obj.count + 1 } : obj
                ),
            };
        case ReducerType.DECREASE_COUNT:
            return {
                ...state,
                unreadCount: state.unreadCount.map(obj =>
                    obj.type === action.payload ? { ...obj, count: 0 } : obj
                ),
            };
        //
        case ReducerType.GET_ALL_UNREAD_COUNT:
            return {
                ...state,
                allUnreadCounts: action.payload?.length > 0 ? action.payload : [
                    { type: 'team', count: 0, room_id: "" },
                    { type: 'client', count: 0, room_id: "" }
                ]
            }


        case ReducerType.INCREASE_COUNT_PER_PROJECT:
            const projectExists = state.allUnreadCounts.some(
                obj => obj.type === action.payload.type && obj.room_id === action.payload.projectId
            );

            if (projectExists) {
                return {
                    ...state,
                    allUnreadCounts: state.allUnreadCounts.map(obj =>
                        obj.type === action.payload.type && obj.room_id === action.payload.projectId
                            ? { ...obj, count: obj.count + 1 }
                            : obj
                    ),
                };
            } else {
                return {
                    ...state,
                    allUnreadCounts: [
                        ...state.allUnreadCounts,
                        { type: action.payload.type, count: 1, room_id: action.payload.projectId }
                    ],
                };
            }


        case ReducerType.REMOVE_ALL_GROUP_CHAT:
            return {
                ...state,
                isGroupMessages: false,
                groupMessages: [],
                noMoreOlderMessages: false,

                clientMessages: [],
                isClientMessages: false,
                noMoreClientOlderMessages: false,
            }


        default:
            return state
    }
}
export default groupChat
