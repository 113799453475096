import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tab } from 'react-bootstrap'
import ChatContentBody from './ChatContentBody'
import MessageTextArea from './MessageTextArea'
import { fetchConversation } from 'utils/socket'
import { useDispatch, useSelector } from 'react-redux'
import { isAdmin, Decrypt, isSales } from 'utils/constants'
import { ChatPermissionContent } from './ChatPermissionContent'
import { ListionBothChatMessages, ListionMessage } from 'utils/socket'
import { useParams } from 'react-router-dom'

const ChatContent = ({ loader, activeTab }) => {
  const { Conversations } = useSelector(state => state.smsEmail)
  const { PorjectSettings } = useSelector(state => state.projects)
  const dispatch = useDispatch()
  let { id } = useParams()
  let decryptedId = Decrypt(id)
  useEffect(() => {
    const fetchData = async () => {
      if (Conversations.length > 0) {
        let conversationId = Conversations[0].id ?? ''
        await fetchConversation(dispatch, conversationId)
      }
    }
    fetchData()
  }, [Conversations.length]) // Removed [Conversations] dependency array
  useEffect(() => {
    if (decryptedId) {
      ListionBothChatMessages(dispatch, decryptedId, activeTab)
      ListionMessage(dispatch, decryptedId, 'chat', activeTab)

    }

  }, [decryptedId])
  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch({ type: 'REMOVE_STATES' })
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    dispatch({
      type: 'SEND_THREAD_MESSAGE',
      payload: {
        threadId: '',
        isOpen: true,
        threadSubject: '',
        selectedTab: 'sms'
      }
    })
  }, [])
  return (
    <Tab.Content className='card-chat-content'>
      <Tab.Pane key={0} eventKey={0} className='card-chat-pane'>
        {/* <ChatContentHeader thread={thread} setHideSidebar={setHideSidebar} /> */}
        {(!isAdmin() && !isSales()) &&
          PorjectSettings?.sms_chat_enabled === 0 &&
          PorjectSettings?.email_chat_enabled === 0 &&
          PorjectSettings?.team_chat_enabled === 0 ? (
          <ChatPermissionContent />
        ) : (
          <ChatContentBody loader={loader} />
        )}
      </Tab.Pane>
      {(!isAdmin() && !isSales()) &&
        PorjectSettings?.sms_chat_enabled === 0 &&
        PorjectSettings?.email_chat_enabled === 0 &&
        PorjectSettings?.team_chat_enabled === 0 ? (
        <div></div>
      ) : (
        <MessageTextArea />
      )}
    </Tab.Content>
  )
}

ChatContent.propTypes = {
  setHideSidebar: PropTypes.func.isRequired
}

export default ChatContent
