import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from "react-icons/io";
import { FaRegCopy } from "react-icons/fa";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Example({ openLinkModal, setOpenLinkModal, oneTimeLink }) {
    const [tooltipText, setTooltipText] = useState('Copy');

    const copyToClipboard = () => {
        navigator.clipboard.writeText(oneTimeLink);
        setTooltipText('Copied');
        setTimeout(() => {
            setTooltipText('Copy');
        }, 2000);
    };

    const handleClose = () => setOpenLinkModal(false);

    return (
        <>
            <Modal show={openLinkModal} >
                <div className="py-2 px-3">
                    <div className="w-100 text-end">
                        <IoMdClose onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>
                    <div>
                        <p className="mb-0">Hello there! Here is your one-time link <em style={{ wordBreak: 'break-word' }}
                            className="text-decoration-underline fw-bolder">{oneTimeLink}</em></p>
                    </div>
                    <div className="w-100 text-end mb-2">
                        <div className="d-inline-block">
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip`}>
                                        {tooltipText}
                                    </Tooltip>
                                }
                            >
                                <div id='tooltip' onClick={copyToClipboard}>
                                    <FaRegCopy size={20} style={{ cursor: 'pointer' }} />
                                </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Example;
