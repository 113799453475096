import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
// import {Select} from 'react-bootstrap'
import { Button, Form, Modal, Spinner, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getAllClients, getAllTags, getJobDetail, getSingleClinet, getAllSkills } from '../../../redux/users/action'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import {
  updateOpportunities,
  getAllOpportunities,
  addFollowers,
  removeFollowers,
  addTags,
  removeTags,
  getProjectSettingsDetail
} from '../../../redux/projects/action'
import _ from 'lodash'
import ProjectSettings from './ProjectSettings'
import { getAllUsers } from '../../../redux/users/action'

let validations = Yup.object({
  contact: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  opportunity: Yup.string().required('Required'),
  pipeline: Yup.string().required('Required'),
  stage: Yup.string().required('Required'),
  status: Yup.string().required('Required')
})

function KanbanModal({ show, handleToggle, selectedTask }) {
  const dispatch = useDispatch()
  const { isJobDetail, jobDetail, tags, clientList, usersList, isClients, skills } = useSelector(state => state.users)
  const { savedPipleline } = useSelector(state => state.calenders)
  const { pipelines, PorjectSettings } = useSelector(state => state.projects)
  const [ifContactNotMatch, setIfContactNotMatch] = useState({ id: '1', firstName: '', lastName: '' })
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stages, setStages] = useState([])
  let selectedPipeline = pipelines?.find(item => item?.id === savedPipleline?.pipelineId)

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal)
  }

  useEffect(() => {
    if (show) {
      dispatch({ type: 'CLEAR_JOB_DETAIL' })
      if (selectedTask) {
        dispatch(getJobDetail({ id: selectedTask?.id }))
        dispatch(getAllTags())
        dispatch(getAllClients())
        dispatch(getAllSkills())
        dispatch(getAllUsers())
        dispatch(getProjectSettingsDetail({ projectId: selectedTask?.id }))
      }
    }
  }, [show, selectedTask])



  const fetchDataForPipelines = async () => {
    try {
      // Create an array of promises for fetching data for each pipeline
      const promises = selectedPipeline?.stages?.map(stage => {
        // Dispatch the action to fetch data for each pipeline
        dispatch(getAllOpportunities({ pipeline_id: savedPipleline?.pipelineId, limit: 20, pipeline_stage_id: stage?.id }))
      });

      // Wait for all promises to resolve
      await Promise.all(promises);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const formik = useFormik({
    initialValues: {
      contact: '',
      email: '',
      phone: '',
      opportunity: '',
      pipeline: '',
      stage: '',
      status: '',
      money: '',
      owner: '',
      followers: '',
      business: '',
      source: '',
      tags: ''
    },
    validationSchema: validations,
    onSubmit: async (values, { setSubmitting }) => {
      setIsSubmitting(true)
      let params = {
        opportunities_id: jobDetail?.id,
        pipelineId: values?.pipeline,
        pipelineStageId: values?.stage,
        monetaryValue: values?.money,
        status: values?.status,
        name: values?.opportunity,
      }
      if (values?.source) {
        params.source = values?.source
      }
      let response = await updateOpportunities(params)
      if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        })
        setTimeout(() => {
          fetchDataForPipelines()
          setIsSubmitting(false)
          formik.resetForm()
          handleClose()
        }, 1000)
      } else {
        setIsSubmitting(false)
      }
    }
  })

  useEffect(() => {
    if (jobDetail) {
      let initialValues = {
        contact: jobDetail?.contact?.id,
        email: jobDetail?.contact?.email,
        phone: jobDetail?.contact?.phone,
        opportunity: jobDetail?.name,
        pipeline: jobDetail?.pipelineId,
        stage: jobDetail?.pipelineStageId,
        status: jobDetail?.status,
        money: jobDetail?.monetaryValue,
        owner: jobDetail?.assignedTo,
        followers: jobDetail?.followers,
        business: jobDetail?.contact?.companyName,
        source: jobDetail?.source,
        tags: jobDetail?.contact?.tags
      }
      setIfContactNotMatch({
        id: '1',
        firstName: jobDetail?.contact?.name,
        lastName: ''
      })
      formik.setValues(initialValues)
    }
  }, [jobDetail])

  let contactInfo = clientList.filter(client => formik.values.contact?.includes(client?.id))
  const handleChange = e => {
    if (e) {
      dispatch(getAllClients({ query: e }))
    }
  }


  useEffect(() => {
    if (formik.values.pipeline) {
      let filterdStages = pipelines?.filter((item) => item?.id === formik.values.pipeline)
      setStages(filterdStages.length > 0 ? filterdStages[0]?.stages : [])
    }
  }, [formik.values.pipeline])


  const handleFollowersChange = async selectedOptions => {
    const selectedIds = selectedOptions.map(option => option.id)
    const addedFollowers = selectedIds.filter(id => !formik.values.followers.includes(id))
    const removedFollowers = formik.values.followers.filter(id => !selectedIds.includes(id))
    if (addedFollowers.length > 0) {
      let params = {
        id: jobDetail?.id,
        followers: addedFollowers
      }
      let response = await addFollowers(params)
      if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    }
    if (removedFollowers.length > 0) {
      let params = {
        id: jobDetail?.id,
        followers: removedFollowers
      }
      let response = await removeFollowers(params)
      if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    }

    formik.setFieldValue('followers', selectedIds)
  }

  const handleTagsChange = async selectedOptions => {
    const selectedIds = selectedOptions
    const addedTags = selectedIds.filter(id => !formik.values.tags.includes(id))
    const removedTags = formik.values.tags.filter(id => !selectedIds.includes(id))
    if (addedTags.length > 0) {
      let params = {
        contactId: formik.values.contact,
        tags: addedTags
      }
      let response = await addTags(params)
      if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    }
    if (removedTags.length > 0) {
      let params = {
        contactId: formik.values.contact,
        tags: removedTags
      }
      let response = await removeTags(params)
      if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    }
    formik.setFieldValue('tags', selectedIds)
  }


  const handleChangeStage = (event) => {
    let id = event.target.value
    if (
      id === selectedPipeline?.stages[selectedPipeline?.stages?.length - 1]?.id
      && (!PorjectSettings?.bugCount || PorjectSettings?.bugCount == 0)
    ) {
      return toast.error(`Projects in Maintainence stage should have atleast one open bug
      `, {
        theme: 'colored',
        position: 'top-right'
      })
    } else {
      formik.handleChange(event);

    }


  };


  return (
    <>
      <Modal show={show} onHide={handleToggle} size='lg' aria-labelledby='example-custom-modal-styling-title'>
        <Modal.Header closeButton>
          <Modal.Title id='example-custom-modal-styling-title'>
            <h4 className='fs-8 ' style={{ wordBreak: 'break-all' }}>Edit "{selectedTask?.name}"</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 'auto', overflow: 'auto' }}>
          <ProjectSettings jobDetail={jobDetail} projectDetailValues={formik} />
        </Modal.Body>
        <Form className='position-relative' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body style={{ height: '75vh', overflow: 'auto' }}>
            <hr className='hr' />
            <Card className='py-2'>
              <Row>
                <Col sm={12}>
                  <div className='px-3'>
                    <div>
                      <p className='fs-9 fw-bolder'>Contact details</p>
                    </div>
                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='label'>
                          <Form.Label className='fs-10'>Contact Name</Form.Label>
                          <Select
                            isLoading={isClients}
                            getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
                            getOptionValue={option => option?.id}
                            isDisabled={true}
                            value={contactInfo.length > 0 ? contactInfo : ifContactNotMatch}
                            options={clientList.filter(client => !formik.values.contact?.includes(client?.id))}
                            placeholder={'Name'}
                            classNamePrefix='react-select'
                            onChange={selectedOptions => {
                              formik.setFieldValue('contact', selectedOptions?.id)
                              formik.setFieldValue('email', selectedOptions?.email)
                              formik.setFieldValue('phone', selectedOptions?.phone)
                            }}
                            onBlur={formik.handleBlur}
                            onInputChange={e => handleChange(e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='titleInput'>
                          <Form.Label className='fs-10'>Email</Form.Label>
                          <Form.Control
                            type='text'
                            disabled={true}
                            name='email'
                            placeholder='xyz@gmail.com'
                            required
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.email && !!formik.errors.email}
                            style={{ height: '38px' }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='titleInput'>
                          <Form.Label className='fs-10'>Phone</Form.Label>
                          <Form.Control
                            type='text'
                            disabled={true}
                            name='phone'
                            placeholder='+X XXXX XXXXX'
                            value={formik.values.phone}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            style={{ height: '38px' }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <p className='fs-9 fw-bolder'>Opportunity details</p>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Form.Group className='mb-3' controlId='titleInput'>
                          <Form.Label className='fs-10'>Opportunity Name</Form.Label>
                          <Form.Control
                            type='text'
                            disabled={isJobDetail}
                            name='opportunity'
                            placeholder=''
                            value={formik.values.opportunity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.opportunity && !!formik.errors.opportunity}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='label'>
                          <Form.Label className='fs-10'>Pipeline</Form.Label>
                          <Form.Select
                            name='pipeline'
                            value={formik.values.pipeline}
                            disabled={isJobDetail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.pipeline && !!formik.errors.pipeline}
                          >
                            {pipelines?.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='label'>
                          <Form.Label className='fs-10'>Stage</Form.Label>
                          <Form.Select
                            name='stage'
                            value={formik.values.stage}
                            disabled={isJobDetail}
                            onChange={handleChangeStage}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.stage && !!formik.errors.stage}
                          >
                            {stages?.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='label'>
                          <Form.Label className='fs-10'>Status</Form.Label>
                          <Form.Select
                            name='status'
                            value={formik.values.status}
                            disabled={isJobDetail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.status && !!formik.errors.status}
                          >
                            {[
                              { id: 'open', name: 'Open' },
                              { id: 'lost', name: 'Lost' },
                              { id: 'won', name: 'Won' },
                              { id: 'abandoned', name: 'Abandon' }
                            ].map((item, index) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='titleInput'>
                          <Form.Label className='fs-10'>Opportunity Value</Form.Label>
                          <Form.Control
                            type='number'
                            name='money'
                            disabled={isJobDetail}
                            placeholder=''
                            value={formik.values.money}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.money && !!formik.errors.money}
                            onWheel={e => e.currentTarget.blur()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='label'>
                          <Form.Label className='fs-10'>Owner</Form.Label>
                          <Select
                            name='owner'
                            getOptionLabel={option => `${option?.name}`}
                            getOptionValue={option => option?.id}
                            isClearable={true}
                            placeholder='Unassigned'
                            options={usersList}
                            value={usersList?.find(user => user.id === formik?.values?.owner)}
                            onChange={option => {
                              formik.setFieldValue('owner', option?.id)
                            }}
                            onBlur={formik.handleBlur('owner')}
                            isDisabled={true}
                            isInvalid={formik.touched.owner && !!formik.errors.owner}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='label'>
                          <Form.Label className='fs-10'>Followers</Form.Label>

                          <Select
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            isMulti
                            disabled={isJobDetail}
                            value={usersList?.filter(user => formik.values.followers?.includes(user?.id))}
                            options={usersList?.filter((user) => user?.userType === 2)}
                            placeholder={'followers'}
                            classNamePrefix='react-select'
                            onChange={selectedOptions => {
                              // Extract the IDs of currently selected options
                              const selectedIds = selectedOptions.map(option => option.id)
                              handleFollowersChange(selectedOptions)
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='titleInput'>
                          <Form.Label className='fs-10'>Business Name</Form.Label>
                          <Form.Control
                            type='text'
                            name='business'
                            disabled={true}
                            placeholder=''
                            value={formik.values.business}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.business && !!formik.errors.business}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='titleInput'>
                          <Form.Label className='fs-10'>Opportunity Source</Form.Label>
                          <Form.Control
                            type='text'
                            name='source'
                            placeholder=''
                            value={formik.values.source}
                            disabled={isJobDetail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.source && !!formik.errors.source}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='label'>
                          <Form.Label className='fs-10'>Tags</Form.Label>

                          <Select
                            getOptionLabel={option => option?.name}
                            getOptionValue={option => option?.id}
                            isMulti
                            disabled={isJobDetail}
                            value={tags.filter(tag => formik.values.tags?.includes(tag.name))}
                            options={tags}
                            placeholder={'Tags'}
                            classNamePrefix='react-select'
                            onChange={selectedOptions => {
                              const selectedIds = selectedOptions.map(option => option?.name)
                              handleTagsChange(selectedIds)
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
          <Modal.Footer className=' px-x1 border-top-0'>
            <Button variant='primary' type='submit' onClick={handleClose} className='px-4 mx-0' disabled={isSubmitting}>
              {isSubmitting ? <Spinner size='sm' /> : 'Update'}
            </Button>
          </Modal.Footer>
          {isJobDetail && (
            <div
              className='position-absolute top-0'
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backdropFilter: 'blur(3px)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner />
            </div>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default KanbanModal
