import React, { useEffect } from 'react'
import { isAdmin, isSales } from 'utils/constants'
import { BsInfoCircle } from 'react-icons/bs'

const KanbanColumnHeader = ({ id, title, itemCount, selectedPipeline }) => {
  let color = '#000'
  if (id === selectedPipeline?.stages[0]?.id) {
    color = '#006699'
  } else if (id === selectedPipeline?.stages[1]?.id) {
    color = '#ff9900'
  } else if (id === selectedPipeline?.stages[2]?.id) {
    color = '#3FB0FF'
  } else if (id === selectedPipeline?.stages[3]?.id) {
    color = '#cc66ff'
  } else if (id === selectedPipeline?.stages[4]?.id) {
    color = '#3EC852'
  } else if (id === selectedPipeline?.stages[5]?.id) {
    color = '#ff0000'
  } else {
    color = '#000'
  }


  return (
    <div className='kanban-column-header' style={{ borderTop: `2px solid ${color}`, background: '#fff' }}>
      <h5 className='fs-9 mb-0'>
        {title} <span className='text-500'>({itemCount})</span>
      </h5>
      <BsInfoCircle style={{ cursor: 'pointer', fontSize: '13px', color: '#575757' }} />
    </div>
  )
}

export default KanbanColumnHeader
