// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { getDeviceId, put, Encrypt } from "utils/constants";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyACne4cjCyiD4sXg_qCyGgAIr_cPdwINLE",
    authDomain: "my-trading-coder-portal.firebaseapp.com",
    projectId: "my-trading-coder-portal",
    storageBucket: "my-trading-coder-portal.appspot.com",
    messagingSenderId: "523515996259",
    appId: "1:523515996259:web:845cdf9d14109482a83056",
    measurementId: "G-X7TN5R861G"
};
// Initialize Firebase
let messaging;
if (typeof window !== 'undefined') {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
}

export const getMessagingToken = async () => {
    if (typeof window !== 'undefined' && 'Notification' in window) {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                const currentToken = await getToken(messaging, { vapidKey: 'BLFF_UJCD1I2AJTGBIe0d3TuGi1_IHPKl9BTunCnjo6w3bhmvAX0YZwMT6w3jRHWTEIXV7o6ut0quh00kgzf0-w' });

                if (currentToken) {
                    localStorage.setItem('fcmToken', Encrypt(currentToken))
                    let deviceId = getDeviceId()
                    const params = {
                        device_id: deviceId,
                        device_type: 3,
                        device_token: currentToken
                    }
                    const response = await put('user/add-device', params)
                    if (response?.data?.status_code !== 201 && response?.data?.status_code !== 200) {
                        // toast.error(response.data.message, {
                        //     position: 'top-right',
                        //     theme: 'colored'
                        // })

                        return
                    }
                } else {
                    console.log('Failed to generate the app registration token.');
                }
            } else {
                console.log("User Permission Denied.");
            }
        } catch (error) {
            console.log('An error occurred when requesting to receive the token.', error);
        }
    } else {
        console.log("Notifications not supported or navigator is not available.");
    }
};


export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const unsubscribeFromFirebaseMessaging = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BLFF_UJCD1I2AJTGBIe0d3TuGi1_IHPKl9BTunCnjo6w3bhmvAX0YZwMT6w3jRHWTEIXV7o6ut0quh00kgzf0-w' });
        if (currentToken) {
            await deleteToken(messaging, currentToken);
            console.log('Token deleted successfully');
            // Optionally, you can handle additional cleanup here
        } else {
            console.log('No token available to delete');
        }
    } catch (error) {
        console.error('Error deleting token:', error);
    }
};