import { checkResponse, get, post } from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'


export const getAllEvents = (params) => async (dispatch) => {
    dispatch({
        type: ReducerType.GET_ALL_EVENTS_START,

    })

    const res = await get(apis.get_all_events, params)
    const response = await checkResponse(res)
    if (response?.success) {

        dispatch({
            type: ReducerType.GET_ALL_EVENTS,
            payload: response.data?.data
        })

        return { data: response.data }
    } else {
        dispatch({
            type: ReducerType.GET_ALL_EVENTS_ERROR,

        })
    }

}


export const getAllCalenders = () => async (dispatch) => {
    dispatch({
        type: ReducerType.GET_ALL_CALENDERS_START,
    })
    const res = await get(apis.get_all_calenders)
    const response = await checkResponse(res)
    if (response?.success) {
        dispatch({
            type: ReducerType.GET_ALL_CALENDERS,
            payload: response.data?.data
        })
        return { data: response.data }
    } else {
        dispatch({
            type: ReducerType.GET_ALL_CALENDERS_ERROR,
        })
    }
}



export const getSettingsCalenders = () => async (dispatch) => {

    const res = await get(apis.get_all_calenders_settings)
    const response = await checkResponse(res)
    if (response?.success) {
        dispatch({
            type: ReducerType.GET_SAVED_CALENDERS,
            payload: response.data?.data
        })
        return { data: response.data }
    } else {
        console.log("errr")
    }
}


export const calenderWebhook = async () => {

    const res = await get(apis.calender_webhook)
    const response = await checkResponse(res)
    if (response?.success) {

        return { data: response.data }
    } else {
        console.log('err')
    }
}


export const getSavedPipeline = () => async (dispatch) => {

    const res = await get(apis.get_saved_pipeline)
    const response = await checkResponse(res)
    if (response?.success) {
        dispatch({
            type: ReducerType.GET_SAVED_PIPELINE,
            payload: response.data?.data
        })
        dispatch({
            type: 'SELECTED_PIPELINE',
            payload: response.data?.data
        })
        return { data: response.data }
    } else {
        console.log("errr")
    }
}

export const updatePipeline = async (data) => {
    const res = await post(apis.update_pipeline, data)
    return { data: res?.data }

}
export const saveCalenders = async (data) => {
    const res = await post(apis.update_calenders_settings, data)
    return { data: res?.data }

}


