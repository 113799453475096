
export const rootPaths = {
  root: '/',
  appsRoot: 'app',
  eCommerceRoot: 'e-commerce',
  productRoot: 'product',
  eLearningRoot: 'e-learning',
  authRoot: 'mtc',
  authCardRoot: 'auth'
}

export default {
  changelog: '/changelog',
  widgets: '/widgets',
  landing: '/landing',
  starter: `/${rootPaths.pagesRoot}/starter`,
  error404: `/${rootPaths.errorsRoot}/404`,
  error500: `/${rootPaths.errorsRoot}/500`,
  cardLogin: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/login`,
  cardRegister: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/register`,
  cardLogout: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/logout`,
  cardForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/forgot-password`,
  cardResetPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/reset-password/:id?&:type?&:linkHash?`,
  cardConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/confirm-mail`,
  chat: `/${rootPaths.appsRoot}/chat`,
  kanban: `/${rootPaths.appsRoot}/projects`,
  settings: `/${rootPaths.appsRoot}/settings`,
  project_detail: `/${rootPaths.appsRoot}/projects/:id/:pipe?/detail/:stage?`,
  profile: `/${rootPaths.appsRoot}/profile`,
  users: `/${rootPaths.appsRoot}/users`,
  clients: `/${rootPaths.appsRoot}/clients`,
  notifications: `/${rootPaths.appsRoot}/notifications`,

  products: productLayout =>
    `/${rootPaths.eCommerceRoot}/${rootPaths.productRoot}${productLayout ? `/${productLayout}` : ''}`,
  productDetails: productId =>
    `/${rootPaths.eCommerceRoot}/${rootPaths.productRoot}/product-details${productId ? `/${productId}` : ''}`,

  courseDetails: courseId => `/${rootPaths.eLearningRoot}/course/course-details${courseId ? `/${courseId}` : ''}`,
  courses: courseLayout => `/${rootPaths.eLearningRoot}/course${courseLayout ? `/${courseLayout}` : ''}`
}
