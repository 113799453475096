import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'bootstrap/dist/css/bootstrap.min.css'
import PageHeader from 'components/common/PageHeader'
import Card from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { OverlayTrigger, Tooltip, InputGroup, Form } from 'react-bootstrap'
import UserAddEdit from './AddEditClient'
import { getAllUsers } from '../../../redux/users/action'
import { useDispatch, useSelector } from 'react-redux'
import { getRandomColor } from 'utils/constants'
import { Spinner } from 'react-bootstrap'
import { FiEdit3 } from 'react-icons/fi'
import { StatusFirstModal } from './StatusModal'
import { getAllClientList } from '../../../redux/client/action'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import { IoSearch } from 'react-icons/io5'

// Custom formatter for the status column

// Custom formatter for the status column
const Logo = (cell, row, rowIndex) => {
  return (
    <div className='d-flex align-items-center gap-2'>
      <div
        className='rounded-circle  d-flex justify-content-center align-items-center '
        style={{
          width: '30px',
          height: '30px',
          backgroundColor: getRandomColor(),
          color: '#fff'
        }}
        alt='Avatar'
      >
        <span style={{ textTransform: 'capitalize' }}>{cell ? cell?.charAt(0) : ''}</span>
      </div>
    </div>
  )
}

const Users = () => {
  const dispatch = useDispatch()
  const [clientListArr, setClientListArr] = useState([])
  const { clientList, isClients } = useSelector(state => state.client)
  const [open, setOpen] = useState(false)
  const [clientDetail, setClientDetail] = useState(null)
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false)
  const [clientDeleteDetail, setClientDeleteDetail] = useState(null)
  const [statusVal, setStatusVal] = useState(0)
  const [page, setPage] = useState(1)
  const [nextClickDisable, setNextClickDisable] = useState(false)
  const [prevClickDisable, setPrevClickDisable] = useState(false)
  const [searchVal, setSearchVal] = useState('')

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleUpdate = row => {
    setClientDetail(row)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(!open)
    setPage(1)
    setClientDetail(null)
    setSearchVal('')
  }
  useEffect(() => {
    setClientListArr(clientList)
  }, [clientList])
  // Define static columns

  const handleStatusModal = (status, row) => {
    setIsOpenStatusModal(true)
    setClientDeleteDetail(row)
    setStatusVal(status == true ? 1 : 0)
  }

  const handleCloseStatusModal = () => {
    setIsOpenStatusModal(false)
    setClientDetail(null)
    setStatusVal(0)
  }

  const Edit = (cell, row, rowIndex) => {
    return <FiEdit3 onClick={() => handleUpdate(row)} size={20} className='cursor-pointer' />
  }

  const statusFormatter = (cell, row, rowIndex) => {
    return (
      <div className='form-check form-switch'>
        <input
          className='form-check-input'
          type='checkbox'
          id={`toggleSwitch${rowIndex}`}
          checked={cell == 1 ? true : false}
          onChange={event => {
            handleStatusModal(event.target.checked, row)
          }}
        />
        <label className='form-check-label' htmlFor={`toggleSwitch${rowIndex}`}>
          {cell === 1 ? 'Active' : 'Inactive'}
        </label>
      </div>
    )
  }

  // Define static data
  const columns = [
    {
      dataField: 'firstName',
      text: '',
      headerStyle: { width: '100px' },
      style: { width: '100px' },
      sort: true,
      formatter: Logo
    },
    { dataField: 'name', text: 'Name', sort: true, headerStyle: { width: '140px' } },
    { dataField: 'firstName', text: 'First Name', sort: true, headerStyle: { width: '150px' } },
    { dataField: 'lastName', text: 'Last Name', sort: true, headerStyle: { width: '150px' } },
    { dataField: 'email', text: 'Email', sort: true, headerStyle: { width: '220px' } },
    { dataField: 'number', text: 'Number', sort: true, headerStyle: { width: '150px' } },
    { dataField: 'status', text: 'Status', sort: true, formatter: statusFormatter, headerStyle: { width: '100px' } },
    { dataField: 'edit', text: 'Action', formatter: Edit, headerStyle: { width: '100px' } }
  ]

  // const pagination = paginationFactory({
  //   sizePerPage: 10, // Number of rows per page
  //   alwaysShowAllBtns: true,
  //   withFirstAndLast: true,
  //   showTotal: true // optional, shows total count
  // })

  // const totalRecords = clientListArr.length
  // const sizePerPage = 10
  // const totalPages = Math.ceil(totalRecords / sizePerPage)

  useEffect(() => {
    if (searchVal == '') {
      let params = {
        page: page,
        limit: 20
      }
      dispatch(getAllClientList(params))
    }
  }, [searchVal])

  const getClickLogsOnPageChange = async exclusiveBackKeyObj => {
    if (searchVal != '') {
      exclusiveBackKeyObj.search = searchVal
    }
    dispatch(getAllClientList(exclusiveBackKeyObj))
  }

  useEffect(() => {
    if (clientListArr?.length > 0 && clientListArr?.length >= 20 && !isClients) {
      setNextClickDisable(true)
    } else {
      setNextClickDisable(false)
    }
  }, [clientListArr, isClients])

  useEffect(() => {
    if (page > 1 && !isClients) {
      setPrevClickDisable(true)
    } else {
      setPrevClickDisable(false)
    }
  }, [page, isClients])

  const newDecreasePage = () => {
    const backpage = page - 1
    if (page - 1 >= 1) {
      const exclusiveBackKeyObj = {
        page: backpage,
        limit: 20
      }
      setPage(page - 1)
      getClickLogsOnPageChange(exclusiveBackKeyObj)
    } else {
      const exclusiveBackKeyObj = {
        backpage: 1,
        limit: 20
      }
      setPage(1)
      getClickLogsOnPageChange(exclusiveBackKeyObj)
    }
  }

  const newIncreasePage = () => {
    if (clientListArr.length > 0) {
      const nextpage = page + 1
      const exclusiveBackKeyObj = {
        page: nextpage,
        limit: 20
      }
      setPage(prev => prev + 1)
      getClickLogsOnPageChange(exclusiveBackKeyObj)
    }
  }

  const CustomPagination = () => {
    return (
      <div
        className='fixed-footer'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          padding: '0px 20px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <p style={{ fontSize: '15px', fontWeight: '600' }}>Current Page :</p>
          <p style={{ fontSize: '15px', fontWeight: '600' }}>{`${page}`}</p>

          <p style={{ fontSize: '15px', fontWeight: '600' }}>Rows Per Page :</p>
          <p style={{ fontSize: '15px', fontWeight: '600' }}>{clientListArr.length}</p>
        </div>
        <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
          <OverlayTrigger placement={'top'} overlay={<Tooltip>Previous</Tooltip>}>
            <div>
              <IoIosArrowBack
                style={{
                  color: prevClickDisable ? '#757575' : '#d3d3d3',
                  cursor: 'pointer',
                  fontSize: '18px'
                }}
                onClick={prevClickDisable ? newDecreasePage : () => { }}
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement={'top'} overlay={<Tooltip>Next</Tooltip>}>
            <div>
              <IoIosArrowForward
                style={{
                  color: nextClickDisable ? '#757575' : '#d3d3d3',
                  cursor: 'pointer',
                  fontSize: '18px'
                }}
                onClick={nextClickDisable ? newIncreasePage : () => { }}
              />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    )
  }

  const handleSearch = value => {
    setSearchVal(value)
    let params = {
      page: 1,
      limit: 20
    }
    if (value.length >= 2 && clientList.length > 0) {
      const type = 'search'
      params.search = value
      dispatch(getAllClientList(params, type))
    }
    if (value.length <= 2 && clientList.length == 0) {
      if (value.length > 0) {
        params.search = value
      }
      const type = 'search'
      dispatch(getAllClientList(params, type))
    }
    setPage(1)
  }
  return (
    <div>
      <PageHeader title='Clients List' className='mb-3 '></PageHeader>
      <Card className='p-2 ' style={{ minHeight: '400px' }}>
        {/* <div className='d-flex justify-content-end'>
              <Button onClick={handleOpen}>Add Client</Button>
            </div> */}
        <div className='d-flex justify-content-end w-100 mt-1'>
          <InputGroup style={{ width: '250px', boxShadow: 'none' }}>
            <Form.Control
              placeholder='Search...'
              aria-describedby='basic-addon2'
              value={searchVal}
              onChange={event => handleSearch(event.target.value)}
            />
            <InputGroup.Text
              id='basic-addon2'

            // onClick={handleSearch}
            >
              <IoSearch size={21} />
            </InputGroup.Text>
          </InputGroup>
        </div>
        {isClients ? (
          <div className='p-2  d-flex justify-content-center align-items-center' style={{ height: '400px' }}>
            {' '}
            <Spinner animation='border' size='lg' />
          </div>
        ) : (
          <div className='table-responsive' style={{
            overflowX: 'auto',
            maxWidth: '100%',
            height: '70vh',
            overflowY: 'auto',
            margin: '15px 0px',
            position: 'relative'
          }}>
            <BootstrapTable
              keyField='id'
              headerClasses='fixed-tableheader'
              data={clientListArr ?? []}
              columns={columns}
              bordered={false}
              noDataIndication={() => (
                <div
                  className='text-center '
                  style={{
                    color: 'rgb(112 109 109)',
                    fontWeight: '600',
                    minHeight: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <p>No data found!!</p>
                </div>
              )}
              bootstrap5
            />
            <CustomPagination />
          </div>
        )}
      </Card>
      <UserAddEdit open={open} clientDetail={clientDetail} handleClose={handleClose} />

      <StatusFirstModal
        isOpenStatusModal={isOpenStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        clientDeleteDetail={clientDeleteDetail}
        statusVal={statusVal}
      />
    </div>
  )
}

export default Users
