import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from 'components/notification/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { BsMailbox } from "react-icons/bs";
import { useNavigate } from 'react-router-dom'
import { Encrypt } from 'utils/constants';
import { readNotification } from '../../../redux/notifications/action'


const NotificationDropdown = () => {
  // State
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { latestNotifications, isNotifications } = useSelector((state) => state.notifications)
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(true);

  // Handler
  const handleUnread = async (item) => {
    await readNotification({ _id: item?._id })

    dispatch({
      type: "READ_SINGLE_NOTIFICATION",
      payload: { notification_id: item?._id }
    })
    switch (item?.request_type) {
      case 1:
        navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
        break;
      case 2:
        //not redirect
        break;
      case 3:
        dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: 'Notes' } })
        navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
        break;
      case 4:
        dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: 'Documents' } })
        navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
        break;
      case 5:
        dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: 'Videos' } })
        navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
        break;

      case 6:
        //not redirect
        break;
      case 7:
        //not redirect
        break;
      case 8:
        //not redirect
        break;
      case 9:
        dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: 'Client Documents' } })
        navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
        break;
      case 10:
        //not redirect
        break;
      case 11:
        navigate(`/app/projects/${Encrypt(item?.request_id)}/detail?isOpen=true`)
        break;
      case 12:
        navigate(`/app/projects/${Encrypt(item?.request_id)}/detail?isOpen=true`)
        break;
      case 13:
        navigate(`/app/projects`)
        break;
      case 14:
        navigate(`/app/projects/${Encrypt(item?.request_id)}/detail`)
        break;
    }

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const onToggle = () => {
    setIsOpen(!isOpen);

  }


  const markAllRead = () => {
    dispatch({ type: "READ_ALL_NOTIFICATIONS" })
    readNotification()
  }

  useEffect(() => {
    let readed = latestNotifications?.every((item) => item.is_read === 1)
    setIsAllRead(readed)
  }, [latestNotifications])
  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={onToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-5" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAllRead}
              >
                Mark all as read
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs-10 scrollbar"
            style={{ maxHeight: '19rem' }}
          >

            <div className="list-group-title">EARLIER</div>
            {latestNotifications?.length > 0 ? latestNotifications.map(notification => (
              <ListGroup.Item key={notification.id} onClick={() => handleUnread(notification)}>
                <Notification notification={notification} flush />
              </ListGroup.Item>
            )) : <div className='d-flex justify-content-center p-4 flex-column align-items-center'>

              <BsMailbox size={25} />
              <p>No notifications</p>
            </div>}
          </ListGroup>
          <div
            className="card-footer text-center border-top"

          >
            <Link className="card-link d-block" to="/app/notifications">
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
