export const ReducerType = {
  // basic Details
  SELECTED_PIPELINE: 'SELECTED_PIPELINE',
  GET_ALL_PIPELINES_START: 'GET_ALL_PIPELINES_START',
  GET_ALL_PIPELINES: 'GET_ALL_PIPELINES',
  GET_ALL_PIPELINES_ERROR: 'GET_ALL_PIPELINES_ERROR',

  GET_ALL_OPPORTUNITIES_START: 'GET_ALL_OPPORTUNITIES_START',
  GET_ALL_OPPORTUNITIES: 'GET_ALL_OPPORTUNITIES',
  GET_ALL_OPPORTUNITIES_ERROR: 'GET_ALL_OPPORTUNITIES_ERROR',
  MODIFIED_OPPORTUNITIES_ARRAY: 'MODIFIED_OPPORTUNITIES_ARRAY',
  OPEN_KANBAN_MODAL: 'OPEN_KANBAN_MODAL',
  TOGGLE_KANBAN_MODAL: 'TOGGLE_KANBAN_MODAL',

  ADD_KANBAN_COLUMN: 'ADD_KANBAN_COLUMN',
  ADD_TASK_CARD: 'ADD_TASK_CARD',
  REMOVE_TASK_CARD: 'REMOVE_TASK_CARD',
  UPDATE_SINGLE_COLUMN: 'UPDATE_SINGLE_COLUMN',
  UPDATE_DUAL_COLUMN: 'UPDATE_DUAL_COLUMN',
  DEV_UPDATE_DUAL_COLUMN: 'DEV_UPDATE_DUAL_COLUMN',

  REMOVE_KANBAN_COLUMN: 'REMOVE_KANBAN_COLUMN',
  MOVE_TO_INPROGRESS: 'MOVE_TO_INPROGRESS',
  REMOVE_ASSIGNED_CARD: 'REMOVE_ASSIGNED_CARD',

  GET_OLDER_PROJECTS_OF_STAGE_START: 'GET_OLDER_PROJECTS_OF_STAGE_START',
  GET_OLDER_PROJECTS_OF_STAGE: 'GET_OLDER_PROJECTS_OF_STAGE',
  GET_OLDER_PROJECTS_OF_STAGE_ERROR: 'GET_OLDER_PROJECTS_OF_STAGE_ERROR',

  IS_CARD_MOVING_START: 'IS_CARD_MOVING_START',
  IS_CARD_MOVING: 'IS_CARD_MOVING',
  IS_CARD_MOVING_ERROR: 'IS_CARD_MOVING_ERROR',

  GET_DEV_JOBS_START: 'GET_DEV_JOBS_START',
  GET_DEV_JOBS: 'GET_DEV_JOBS',
  GET_DEV_JOBS_ERROR: 'GET_DEV_JOBS_ERROR',

  ASSIGN_TO_DEV: 'ASSIGN_TO_DEV',

  GET_PROJECT_SETTINGS_DETAIL_START: 'GET_PROJECT_SETTINGS_DETAIL_START',
  GET_PROJECT_SETTINGS_DETAIL: 'GET_PROJECT_SETTINGS_DETAIL',
  GET_PROJECT_SETTINGS_DETAIL_ERROR: 'GET_PROJECT_SETTINGS_DETAIL_ERROR',

  GET_PROJECT_SETTINGS_ALL: 'GET_PROJECT_SETTINGS_ALL',

  REMOVE_ALL_PROJECTS_SETTINGS: 'REMOVE_ALL_PROJECTS_SETTINGS',

  TOGGLE_DETAIL_MODAL: 'TOGGLE_DETAIL_MODAL',

  DELETE_OPPORTINITY: 'DELETE_OPPORTINITY',

  //Bugs list type

  GET_PROJECT_BUGS_LIST_START: 'GET_PROJECT_BUGS_LIST_START',
  GET_PROJECT_BUGS_LIST: 'GET_PROJECT_BUGS_LIST',
  GET_PROJECT_BUGS_LIST_ERROR: 'GET_PROJECT_BUGS_LIST_ERROR',

  OPPORTUNITIES_STAGE_UPDATE: 'OPPORTUNITIES_STAGE_UPDATE',
  OPPORTUNITIES_CREATE: "OPPORTUNITIES_CREATE"
}
