import { ReducerType } from './type'

const InitialState = {
    projectsCount: null,
    isProjectsCount: false
}

const dashboard = (state = InitialState, action) => {
    switch (action?.type) {
        case ReducerType.GET_PROJECTS_COUNT_START:
            return {
                ...state,
                isProjectsCount: true,
            }
        case ReducerType.GET_PROJECTS_COUNT:
            return {
                ...state,
                projectsCount: action.payload,
                isProjectsCount: false
            }

        case ReducerType.GET_PROJECTS_COUNT_ERROR:
            return {
                ...state,
                projectsCount: [],
                isProjectsCount: false
            }

        default:
            return state
    }
}
export default dashboard
