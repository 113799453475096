import React, { useEffect } from 'react'
import { Tab } from 'react-bootstrap'
import GroupChatContentBody from './groupChatContentBody'
import GrMessageTextArea from './GrMessageTextArea'
import { useDispatch } from 'react-redux'
import { getAllGroupMessages } from '../../../../redux/groupChat/action'
import { Decrypt } from 'utils/constants'
import { useParams } from 'react-router-dom'
import { joinRoom, ListionMessage, ErrorMessages, socket } from 'utils/socket'

const GroupChatContent = React.memo(({ activeTab }) => {
  const dispatch = useDispatch()
  let { id } = useParams()
  let decryptedId = Decrypt(id)

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch({ type: 'REMOVE_STATES' })
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])



  useEffect(() => {
    if (decryptedId) {
      dispatch(getAllGroupMessages({ project_id: decryptedId, limit: 20, page: 1, type: 'team' }))
      joinRoom(decryptedId, 'team')
      ErrorMessages()
    }
  }, [decryptedId])

  useEffect(() => {
    if (decryptedId) {
      ListionMessage(dispatch, decryptedId, 'team', activeTab)

    }

  }, [decryptedId])
  return (
    <Tab.Content className='card-chat-content'>
      <Tab.Pane key={0} eventKey={0} className='card-chat-pane'>
        <GroupChatContentBody activeTab={activeTab} />
      </Tab.Pane>
      <GrMessageTextArea />
    </Tab.Content>
  )
})

export default GroupChatContent
