import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { MdOutlineClose } from 'react-icons/md'

import { notificationsArr, clientNotificationsArr } from 'utils/constants'
import { getAllNotificationsSettings, editNotificationsSettings } from '../../../../redux/notifications/action'
import { getAllProjectsSettings } from '../../../../redux/projects/action'

import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'


const NotificationPopup = ({ openNotificationPopup, handleCloseNotificationPopup, task }) => {

    const { userDetails } = useSelector((state) => state.login)
    const { savedPipleline } = useSelector(state => state.calenders)
    const [intalSettings, setIntialSettings] = useState(notificationsArr)
    const [notificationsSettings, setNotificationsSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [disableAll, setDisableAll] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const dispatch = useDispatch()


    useEffect(() => {
        if (userDetails?.userType === 3) {
            setIntialSettings(clientNotificationsArr)
        } else {
            setIntialSettings(notificationsArr)

        }
    }, [userDetails])
    useEffect(() => {
        if (notificationsSettings) {
            const isDisabled = notificationsArr.every(category =>
                category.child.every(child => notificationsSettings[child.value] === 0)
            );
            setDisableAll(isDisabled);
        }
    }, [notificationsSettings]);

    useEffect(() => {

        const fetchNotifications = async () => {
            setLoading(true);
            try {
                const response = await getAllNotificationsSettings({ projectId: task?.id });
                if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
                    setNotificationsSettings(response?.data?.data);

                } else {
                    setNotificationsSettings({
                        _id: "",
                        project_id: task?.id,
                        user_id: '',
                        assign: 0,
                        bugFix: 0,
                        bugRaise: 0,
                        clientDocumentAdd: 0,
                        clientDocumentDelete: 0,
                        createdAt: dayjs().toISOString(),
                        documentAdd: 0,
                        documentDelete: 0,
                        isDeleted: false,
                        noteAdd: 0,
                        noteDelete: 0,
                        sendMessage: 0,
                        stageUpdate: 0,
                        unassign: 0,
                        updatedAt: dayjs().toISOString(),
                        videoAdd: 0,
                        videoDelete: 0,
                        muteAll: 1
                    });
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            } finally {
                setLoading(false);
            }
        };
        if (task && openNotificationPopup) {
            fetchNotifications();

        }
    }, [task, openNotificationPopup]);


    const handleCheckboxChange = (event, value) => {
        setNotificationsSettings({
            ...notificationsSettings,
            [value]: event.target.checked ? 1 : 0
        });
    }

    const handleToggleAll = () => {
        const newStatus = !disableAll;
        setDisableAll(newStatus);
        const updatedSettings = Object.keys(notificationsSettings).reduce((acc, key) => {
            if (typeof notificationsSettings[key] === 'number') {
                acc[key] = newStatus ? 0 : 1;
            } else {
                acc[key] = notificationsSettings[key];
            }
            return acc;
        }, {});
        setNotificationsSettings(updatedSettings);
    };

    const handleCloseModal = () => {
        handleCloseNotificationPopup()
        setNotificationsSettings(null)
        setDisableAll(false)
    }

    const handleSubmit = async () => {
        setSubmitLoading(true);

        try {
            const params = {
                project_id: notificationsSettings?.project_id,
                assign: notificationsSettings?.assign,
                bugFix: notificationsSettings?.bugFix,
                bugRaise: notificationsSettings?.bugRaise,
                clientDocumentAdd: notificationsSettings?.clientDocumentAdd,
                clientDocumentDelete: notificationsSettings?.clientDocumentDelete,
                documentAdd: notificationsSettings?.documentAdd,
                documentDelete: notificationsSettings?.documentDelete,
                noteAdd: notificationsSettings?.noteAdd,
                noteDelete: notificationsSettings?.noteDelete,
                sendMessage: notificationsSettings?.sendMessage,
                stageUpdate: notificationsSettings?.stageUpdate,
                unassign: notificationsSettings?.unassign,
                videoAdd: notificationsSettings?.videoAdd,
                videoDelete: notificationsSettings?.videoDelete,
                muteAll: disableAll ? 1 : 0
            };
            let response = await editNotificationsSettings(params);
            if (response?.data?.status_code == 201) {
                dispatch(getAllProjectsSettings({ pipelineId: savedPipleline?.pipelineId }))

                toast.success(response?.data?.message, {
                    position: 'top-right',
                    theme: 'colored'
                })
                handleCloseModal()
            } else {
                toast.error(response?.data?.message, {
                    position: 'top-right',
                    theme: 'colored'
                })
            }
        } catch (err) {
            console.error('Error updating notifications:', err);
        } finally {
            setSubmitLoading(false);
            handleCloseModal()
        }
    };

    return (
        <>
            <Modal
                show={openNotificationPopup}
                onHide={handleCloseModal}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <div
                    // className='py-2 px-3 '
                    style={{
                        background: ' linear-gradient(107.56deg, #ECF6FF 0%, #F8F8FF 25%, #EDEBFF 48%, #EFEFF7 77%, #F9F9F9 100%)',
                        borderRadius: '10px',
                        border: 'none',
                        // height: `${height - 70}px`,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        position: 'relative'
                    }}
                >
                    <div className='py-2 px-3 '>


                        <div className='d-flex justify-content-between w-100 pb-2 border-bottom'>
                            <div className='d-flex gap-1'>

                                <p style={{ color: '#000', fontWeight: '600' }}>Enable /Disable Notifications ({task?.name})</p>
                            </div>

                            <MdOutlineClose onClick={handleCloseModal} size={25} style={{ cursor: 'pointer' }} />
                        </div>

                        <div className=''>
                            <div className='d-flex mt-3'>
                                <label className='form-check-label fw-bolder' style={{ width: '170px' }}>Mute All Notifications</label>
                                <div className='form-check form-switch'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        checked={disableAll}
                                        onChange={handleToggleAll}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>

                            {intalSettings.map((item) => (
                                <>
                                    <label key={item.id} className='form-check-label fw-bolder fs-10' >{item.name}</label>
                                    <div className='d-flex flex-wrap '>
                                        {item.child.map((item2) => (
                                            <div className='d-flex ' key={item2.id}>
                                                <label className='form-check-label fw-normal' style={{ width: '170px' }}>{item2.name}</label>
                                                <div className='form-check form-switch'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        checked={notificationsSettings ? notificationsSettings[item2?.value] === 1 : false}
                                                        onChange={(e) => handleCheckboxChange(e, item2?.value)}

                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </>
                            ))}

                        </div>
                        <div className='d-flex justify-content-end w-100'>
                            <Button onClick={handleSubmit}>{submitLoading ? <Spinner size='sm' /> : "Save"}</Button>
                        </div>
                    </div>
                    {loading &&
                        <div style={{
                            position: 'absolute',
                            width: '100%',
                            backdropFilter: 'blur(2px)',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}>
                            <Spinner />
                        </div>
                    }
                </div>
            </Modal>
        </>
    )
}
export default NotificationPopup