import { ReducerType } from './type'

const InitialState = {
    loading: false,
    error: null,
    success: false,
    isLogin: false,
    userDetails: null,

}

const login = (state = InitialState, action) => {
    switch (action?.type) {
        case ReducerType.LOGIN_ACTION_START:
            return {
                ...state,
                success: false,
                error: '',
                loading: true,
                isLogin: false
            }
        case ReducerType.LOGGED_IN:
            return {
                ...state,
                success: false,
                loading: false,
                isLogin: true
            }
        case ReducerType.OTP_VERIFIED:
            return {
                ...state,
                success: false,
                loading: false,
                isLogin: false,

                userDetails: action.payload
            }
        case ReducerType.REMOVE_USER:
            return {
                ...state,
                success: false,
                loading: false,
                userDetails: null,
                isLogin: false
            }
        case ReducerType.LOGIN_ERROR:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: false,
                isLogin: false
            }

        default:
            return state
    }
}
export default login