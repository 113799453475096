import React, { useState, useRef, useEffect } from 'react'
import { Button, Form, Spinner, Overlay } from 'react-bootstrap'
import 'tinymce/skins/ui/oxide/skin.css'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import { toast } from 'react-toastify'
import { EditorState, Modifier, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Picker from '@emoji-mart/react'
import { UplaodSMSEmailFile, sendSms, getEmailDetail } from '../../../../redux/smsMail/action'
import { useSelector } from 'react-redux'
import { IoMdClose } from 'react-icons/io'
import { IoDocumentAttach } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import draftToHtml from 'draftjs-to-html'

import dayjs from 'dayjs'

const Email = ({ sendThreadMessage }) => {
  const [editorUpIntro, setEditorUpIntro] = useState(() => EditorState.createEmpty())
  const [previewEmoji, setPreviewEmoji] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [subject, setSubject] = useState(sendThreadMessage?.threadSubject ?? '')
  const { Conversations } = useSelector(state => state.smsEmail)
  const { userDetails } = useSelector(state => state.login)
  const { savedPipleline } = useSelector(state => state.calenders)
  const [subjectError, setSubjectError] = useState('')
  const emojiPickerRef = useRef(null)


  useEffect(() => {
    if (sendThreadMessage?.threadSubject) {
      setSubject(sendThreadMessage?.threadSubject)

    } else {
      setSubject('')

    }
  }, [sendThreadMessage?.threadSubject])
  const [Files, setFiles] = useState([])
  const dispatch = useDispatch()

  const handleEditorChange = editorState => {
    setEditorUpIntro(editorState)
  }
  const handleAddAttachment = async files => {
    // const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];

    // const invalidFiles = Array.from(files).filter(file => !allowedTypes.includes(file.type));

    // if (invalidFiles.length > 0) {
    //   toast.error("This format not allowed", {
    //     theme: 'colored',
    //     position: 'top-right'
    //   })
    //   return;
    // }

    if (!files || Object.keys(files).length === 0) return
    setIsUploading(true)
    const formData = new FormData()
    Object.values(files).forEach(file => {
      formData.append('fileAttachment', file)
    })
    formData.append('conversationId', Conversations[0]?.id)

    try {
      // Upload the files using the 'uploadInnerDocuments' API.
      const response = await UplaodSMSEmailFile(formData)
      if (response?.data?.status_code === 201 || response?.data?.status_code === 200) {
        const responseData = response?.data?.data || {}
        setIsUploading(false)

        const fileList = Object.entries(responseData).map(([filename, url]) => ({
          filename,
          url
        }))
        // Append the uploaded files to the existing files state.
        setFiles(prevFiles => [...prevFiles, ...fileList])
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        })
      } else {
        setIsUploading(false)
        toast.error(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
      // Check the response and extract any relevant data.
    } catch (error) {
      console.error('Error:', error)
      setIsUploading(false)
    }
  }

  const handleRemoveAttachment = urlToRemove => {
    const updatedFiles = Files.filter(file => file.url !== urlToRemove)
    setFiles(updatedFiles)
  }

  const addEmoji = e => {
    const emoji = e.native
    const contentState = editorUpIntro.getCurrentContent()
    const selectionState = editorUpIntro.getSelection()
    const newContentState = Modifier.insertText(contentState, selectionState, emoji)
    const newEditorState = EditorState.push(editorUpIntro, newContentState, 'insert-characters')
    setEditorUpIntro(newEditorState)
    setPreviewEmoji(false)
  }

  const handleClickOutside = event => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setPreviewEmoji(false)
    }
  }
  const sendEmail = async () => {
    const contentState = editorUpIntro.getCurrentContent()
    const htmlContent = draftToHtml(convertToRaw(contentState))
    const htmlContentWithSignature = userDetails?.emailSignature ? htmlContent + userDetails.emailSignature : htmlContent;

    if (subject != '') {
      try {
        setIsSubmitting(true)
        let data = {
          subject: subject,
          contactId: Conversations[0]?.contactId,
          attachments: Files?.map(item => item?.url),
          html: htmlContentWithSignature,
          emailFrom: `${savedPipleline?.chatName} <${savedPipleline?.chatEmail}>`,
          emailReplyMode: sendThreadMessage?.threadId ? 'reply' : 'reply_all',
          type: 'Email',
          userId: userDetails?.id ? userDetails?.id : 'IBNJhAncHUPEb0k431mP'
        }
        if (sendThreadMessage?.threadId) {
          data.threadId = sendThreadMessage?.threadId
          data.subject = `Re: ${sendThreadMessage?.threadSubject}`
        }
        let response = await sendSms(data)
        if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
          dispatch({
            type: 'USER_SEND_MESSAGE',
            payload: response?.data?.data?.threadId
          })
          setFiles([])
          setSubject('')
          setSubjectError('')
          let storeOnState = {
            id: response?.data?.data?.threadId,
            type: 3,
            locationId: '',
            contactId: '',
            subject: subject,
            // subject: '',
            lastMessage: htmlContentWithSignature,
            // lastMessage: '',
            contentType: 'text/html',
            conversationId: response?.data?.data?.conversationId,
            dateAdded: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            userId: userDetails?.id ? userDetails?.id : 'IBNJhAncHUPEb0k431mP',
            meta: {
              email: {
                messageIds: [response?.data?.data?.messageId]
              }
            }
          }
          if (!sendThreadMessage?.threadId) {
            dispatch({ type: 'GET_SMS_DETAIL', payload: storeOnState })
          }
          setEditorUpIntro(() => EditorState.createEmpty())
          dispatch(getEmailDetail({ email_id: response?.data?.data?.messageId }))
          dispatch({
            type: 'SEND_THREAD_MESSAGE',
            payload: {
              threadId: '',
              isOpen: true,
              threadSubject: '',
              selectedTab: 'email'
            }
          })
          setIsSubmitting(false)
          toast.success(response?.data?.message, {
            position: 'top-right',
            theme: 'colored'
          })
        } else {
          setIsSubmitting(false)
          toast.error(response?.data?.message, {
            position: 'top-right',
            theme: 'colored'
          })
        }
      } catch (error) {
        setIsSubmitting(false)

        console.error('Error:', error)
      }
    } else {
      setSubjectError('Subject is Required .')
    }
  }

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid rgb(239 233 233)',
        borderTop: 'none',
        padding: '10px',
        borderRadius: '0px 0px 10px 10px'
      }}
    >
      {!sendThreadMessage?.threadId && (
        <Form.Control
          value={subject}
          onChange={e => setSubject(e.target.value)}
          type='text'
          placeholder='Subject'
          className='border-0 focus-shadow-none shadow-none'
        />
      )}
      {subjectError && subject == '' ? (
        <div style={{ marginBottom: '7px' }}>
          <span
            style={{
              color: '#e63757',
              fontSize: '12px',
              fontWeight: '600',
              padding: '0rem 1rem'
            }}
          >
            {' '}
            {subjectError}
          </span>
        </div>
      ) : null}

      <div className='border border-0 border-200' style={{ height: '140px' }}>
        <Editor
          editorState={editorUpIntro}
          onEditorStateChange={handleEditorChange}
          placeholder='Type a message'
          wrapperClassName='wrapper-class'
          editorClassName='editor-class'
          toolbarClassName='toolbar-class'
          toolbar={{
            options: ['inline', 'blockType', 'list'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough']
            },

            list: {
              inDropdown: false,
              options: ['unordered', 'ordered']
            }
          }}
          wrapperStyle={{ height: window.innerWidth > 400 ? '100px' : '40px' }}
        />
      </div>

      <>
        <Flex alignItems='center' justifyContent='between'>
          <div className='d-flex flex-wrap gap-2'>
            {Files?.map((item, index) => (
              <div className='bg-200 text-dark py-1 px-2 d-flex gap-1 position-relative' key={index}>
                <IoDocumentAttach size={20} />
                <p className='mb-0'>{item?.filename}</p>
                <p
                  onClick={() => handleRemoveAttachment(item?.url)}
                  className='mb-0'
                  style={{
                    position: 'absolute',
                    right: -20,
                    top: -10,
                    background: 'rgb(190 190 190)',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 1,
                    justifyContent: 'center',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
            ))}
          </div>
          <Flex>
            <Form.Group controlId='email-attachment'>
              <Form.Control
                type='file'
                multiple
                className='d-none'
                onChange={({ target }) => handleAddAttachment(target.files)}
              />

              {isUploading ? (
                <Spinner size='sm' />
              ) : (
                <IconButton
                  as={Form.Label}
                  className='me-2 mb-0'
                  variant='tertiary'
                  size='sm'
                  icon='paperclip'
                  iconClassName='fs-8'
                  transform='down-2'
                />
              )}
            </Form.Group>
            <Form.Group controlId='email-image'>
              <Form.Control
                type='file'
                className='d-none'
                accept='.jpg, .jpeg, .png, .xls, .xlsx, .doc, .docx, .pdf'
                multiple
                onChange={({ target }) => handleAddAttachment(target.files)}
              />
              <IconButton
                className='me-2 mb-0'
                variant='tertiary'
                onClick={e => setPreviewEmoji(!previewEmoji)}
                size='sm'
                icon={['far', 'laugh-beam']}
                iconClassName='fs-8'
                transform='down-2'
              />
              <Overlay target={emojiPickerRef?.current} show={previewEmoji} placement='top'>
                {({
                  placement: _placement,
                  arrowProps: _arrowProps,
                  show: _show,
                  popper: _popper,
                  hasDoneInitialMeasure: _hasDoneInitialMeasure
                }) => (
                  // {previewEmoji && (
                  <div className='chat-emoji-picker' dir='ltr' ref={emojiPickerRef}>
                    <Picker set='google' onEmojiSelect={addEmoji} previewPosition='none' skinTonePosition='none' />
                  </div>
                )}
              </Overlay>
            </Form.Group>
            <Button
              disabled={
                (!editorUpIntro.getCurrentContent().hasText() && Files.length === 0) || isSubmitting ? true : false
              }
              onClick={() => sendEmail()}
              variant='primary'
              size='sm'
              style={{ cursor: 'pointer' }}
              className='px-4 px-sm-5 me-2 '
            >
              {isSubmitting ? <Spinner size='sm' /> : 'Send Email'}
            </Button>
          </Flex>
        </Flex>
      </>
    </div>
  )
}

export default Email
