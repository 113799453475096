import React, { useState } from 'react'
import { Button, Form, Modal, Spinner, Card } from 'react-bootstrap'
import { RiErrorWarningLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux';
import { deleteOpportunity } from '../../../redux/projects/action'
import { toast } from 'react-toastify';


export const TaskDeleteModal = ({ openTaskDelete, handleCloseTaskDelete, task }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      let response = await deleteOpportunity({ id: task?.id });
      if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
        toast.success(response?.data.message, {
          position: 'top-right',
          theme: 'colored'
        });
        dispatch({ type: 'DELETE_OPPORTINITY', payload: task });
        handleCloseTaskDelete();
      } else {
        setIsLoading(false);
        // Handle other response status codes if needed
      }
    } catch (error) {
      console.error('An error occurred while deleting opportunity:', error);
      toast.error('An error occurred while deleting opportunity', {
        position: 'top-right',
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {' '}
      <Modal
        show={openTaskDelete}
        onHide={handleCloseTaskDelete}
        aria-labelledby='example-custom-modal-styling-title'
        verticallyCentered
        centered
        size='md'
        style={{
          borderRadius: '20px'
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{
            height: 'auto',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <RiErrorWarningLine style={{ color: 'rgb(249 111 136)', fontSize: '60px' }} />
          <p style={{ textAlign: 'center', fontSize: '14px', fontWeight: '500' }}>
            {' '}
            Are you Sure you want to Delete <span className='fw-bolder'><em> {task?.name}</em></span> ?
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              gap: '10px'
            }}
          >
            <Button variant='secondary' onClick={handleCloseTaskDelete}>
              No
            </Button>
            <Button variant='primary' onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size='sm' /> : 'Yes'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
