import React, { useState } from 'react'
import { deleteFn } from 'utils/constants'
import { apis } from 'utils/Apis'
import { useDispatch } from 'react-redux'
import { Button, Form, Modal, Spinner, Card } from 'react-bootstrap'
import DeleteIcon from 'assets/img/delete-icon.svg'
import { toast } from 'react-toastify'

export const DeleteSkillModal = ({ isOpenDeleteModal, skillId, handleCloseModal }) => {
  const [deleteLoader, setDeleteLoader] = useState(false)
  const dispatch = useDispatch()

  const handleDeleteSkill = async () => {
    setDeleteLoader(true)
    let params = {
      skill_id: skillId
    }
    const response = await deleteFn(apis.delete_skill, params)
    if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
      setDeleteLoader(false)
      toast.success(response?.data?.message, {
        theme: 'colored',
        position: 'top-right'
      })
      dispatch({ type: 'DELETE_SKILL_ACTION', payload: skillId })
      handleCloseModal()
    } else {
      setDeleteLoader(false)
      toast.error(response?.data?.message, {
        theme: 'colored',
        position: 'top-right'
      })
      handleCloseModal()
    }
  }

  return (
    <>
      <Modal
        show={isOpenDeleteModal}
        onHide={handleCloseModal}
        aria-labelledby='example-custom-modal-styling-title'
        verticallyCentered
        centered
        size='md'
        style={{
          borderRadius: '20px'
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{
            height: 'auto',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <img src={DeleteIcon} alt='dmd' style={{ width: '64px', height: '64px' }} />
          <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>
            {' '}
            Are you Sure you want to delete this Skill?
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <Button variant='secondary' onClick={handleCloseModal}>
              No
            </Button>
            <Button variant='primary' onClick={handleDeleteSkill} disabled={deleteLoader}>
              {deleteLoader ? <Spinner size='sm' /> : 'Yes'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
