import React, { useState, useEffect, useMemo } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as _ from 'lodash'
import { getAllClientList, EditClient } from '../../../redux/client/action'

function AddEdit({ open, handleClose, clientDetail }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (clientDetail) {
      formik.setFieldValue('number', clientDetail?.number ?? '')
      formik.setFieldValue('email', clientDetail?.email ?? '')
      formik.setFieldValue('firstName', clientDetail?.firstName ?? '')
      formik.setFieldValue('lastName', clientDetail?.lastName ?? '')
      formik.setFieldValue('username', clientDetail?.name ?? '')
    }
  }, [clientDetail])

  const formik = useFormik({
    initialValues: {
      firstName: clientDetail?.firstName ?? '',
      lastName: clientDetail?.lastName ?? '',
      username: clientDetail?.name ?? '',
      email: clientDetail?.email ?? '',
      number: clientDetail?.number ?? ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().required('Required')
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true)
      let params = {
        id: clientDetail?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        number: values?.number,
        email: values?.email
      }

      let clientParams = {
        page: 1,
        limit: 10
      }

      let res = await EditClient(params)
      if (res?.data?.status_code == 200 || res?.data?.status_code == 201) {
        dispatch(getAllClientList(clientParams))
        setIsLoading(false)
        formik.resetForm()
        handleClose()
        toast.success(res?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      } else {
        setIsLoading(false)
        toast.error(res?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
        formik.resetForm()
        handleClose()
      }
    }
  })

  const handleCloseAddEdit = () => {
    handleClose()
  }

  return (
    <Offcanvas style={{ width: '500px' }} placement={'end'} show={open} onHide={handleCloseAddEdit}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit Client</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom01'>
              <Form.Label>First name</Form.Label>
              <Form.Control
                type='text'
                name='firstName'
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik?.touched?.firstName && formik?.errors?.firstName}
                placeholder='First name'
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.firstName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='12' controlId='validationCustom02'>
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type='text'
                name='lastName'
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik?.touched?.lastName && formik?.errors?.lastName}
                placeholder='Last name'
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.lastName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='12' controlId='validationCustomUsername'>
              <Form.Label>Username</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id='inputGroupPrepend'>@</InputGroup.Text>
                <Form.Control
                  type='text'
                  name='username'
                  disabled
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik?.touched?.username && formik?.errors?.username}
                  placeholder='Username'
                  aria-describedby='inputGroupPrepend'
                />
                <Form.Control.Feedback type='invalid'>{formik?.errors?.username}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom03'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='text'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik?.touched?.email && formik?.errors?.email}
                placeholder='Email'
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.email}</Form.Control.Feedback>
            </Form.Group>
            {/* Repeat the pattern for other form fields */}
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom03'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type='text'
                name='number'
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik?.touched?.number && formik?.errors?.number}
                placeholder='Phone Number'
              />
              <Form.Control.Feedback type='invalid'>{formik?.errors?.number}</Form.Control.Feedback>
            </Form.Group>
            {/* Repeat the pattern for other form fields */}
          </Row>
          <Button type='submit' disabled={isLoading}>
            {' '}
            {isLoading ? <Spinner animation='border' size='sm' /> : clientDetail ? 'Update' : 'Create'}
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default AddEdit
