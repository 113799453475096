import React, { useEffect } from 'react'
import is from 'is_js'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { CloseButton } from 'components/common/Toast'
import { useAppContext } from 'providers/AppProvider'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { exitRoom } from 'utils/socket'
const App = () => {
  const location = useLocation()

  useEffect(() => {
    const startProgress = () => {
      NProgress.start()
    }

    const endProgress = () => {
      NProgress.done()
    }

    endProgress()
    return () => {
      startProgress()
    }
  }, [location])

  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  const {
    config: { navbarPosition }
  } = useAppContext()

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows')
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome')
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox')
    }
    if (is.safari()) {
      HTMLClassList.add('safari')
    }
  }, [HTMLClassList])

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout')
    }
    return () => HTMLClassList.remove('double-top-nav-layout')
  }, [navbarPosition])

  useEffect(() => {
    if (!location.pathname.includes('detail')) {
      exitRoom()
    }
  }, [location])


  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function (registration) {
        console.log('ServiceWorker registration successful with scope: ');
      }, function (err) {
        console.log('ServiceWorker registration failed: ', err);
      });
    });
  }

  return (
    <>
      <Outlet />
      {/* <SettingsToggle /> */}
      <ToastContainer closeButton={CloseButton} icon={true} position='bottom-left' />
    </>
  )
}

export default App
