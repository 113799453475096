import React, { useEffect } from 'react'
import ProfileBanner from '../ProfileBanner'
import coverSrc from 'assets/img/generic/4.jpg'
import { Col, Row, Spinner, Card } from 'react-bootstrap'
import ProfileSettings from './ProfileSettings'

import ChangePassword from './ChangePassword'
import { useDispatch, useSelector } from 'react-redux'

const Settings = () => {
  const { userDetails, loading } = useSelector(state => state.login)

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={
            userDetails
              ? userDetails?.name
                ? userDetails?.name
                : `${userDetails?.firstName} ${userDetails?.lastName}`
              : ''
          }
          className='mb-8'
        />
      </ProfileBanner>
      <Row className='g-3'>
        <Col lg={8}>
          {loading ? (
            <Card
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 15px',
                flexWrap: 'wrap',
                gap: '10px',
                height: '350px'
              }}
            >
              <Spinner />
            </Card>
          ) : (
            <ProfileSettings />
          )}

          {/* <ExperiencesSettings />
          <EducationSettings /> */}
        </Col>
        <Col lg={4}>
          <div className='sticky-sidebar'>
            {/* <AccountSettings />
            <BillingSettings /> */}

            {loading ? (
              <Card
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px 15px',
                  flexWrap: 'wrap',
                  gap: '10px',
                  height: '350px'
                }}
              >
                <Spinner />
              </Card>
            ) : (
              <ChangePassword />
            )}

            {/* <DangerZone /> */}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Settings
