import { checkResponse, get, post, put } from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'


export const getAllGroupMessages = (params) => async (dispatch) => {
    if (params.type == 'team') {
        dispatch({
            type: ReducerType.GET_GROUP_CHAT_LIST_START,
        })
        const res = await get(apis.group_messages_list, params)
        const response = await checkResponse(res)
        if (response?.success) {

            dispatch({
                type: ReducerType.GET_GROUP_CHAT_LIST,
                payload: response.data?.data
            })

            return { data: response.data }
        } else {
            dispatch({
                type: ReducerType.GET_GROUP_CHAT_LIST_ERROR,

            })
        }
    } else {
        dispatch({
            type: ReducerType.GET_CLIENT_CHAT_LIST_START,
        })
        const res = await get(apis.group_messages_list, params)
        const response = await checkResponse(res)
        if (response?.success) {

            dispatch({
                type: ReducerType.GET_CLIENT_CHAT_LIST,
                payload: response.data?.data
            })

            return { data: response.data }
        } else {
            dispatch({
                type: ReducerType.GET_CLIENT_CHAT_LIST_ERROR,

            })
        }
    }


}


export const getOlderGroupMessages = (params) => async (dispatch) => {
    if (params?.type === 'team') {
        dispatch({
            type: ReducerType.GET_GROUP_CHAT_LIST_START,
        })
        const res = await get(apis.group_messages_list, params)
        const response = await checkResponse(res)
        if (response?.success) {

            dispatch({
                type: ReducerType.GET_OLDER_GROUP_MESSAGES,
                payload: response.data?.data
            })

            return { data: response.data }
        } else {
            dispatch({
                type: ReducerType.GET_GROUP_CHAT_LIST_ERROR,

            })
        }
    } else {
        dispatch({
            type: ReducerType.GET_CLIENT_CHAT_LIST_START,
        })
        const res = await get(apis.group_messages_list, params)
        const response = await checkResponse(res)
        if (response?.success) {

            dispatch({
                type: ReducerType.GET_OLDER_CLIENT_MESSAGES,
                payload: response.data?.data
            })

            return { data: response.data }
        } else {
            dispatch({
                type: ReducerType.GET_CLIENT_CHAT_LIST_ERROR,

            })
        }
    }


}

export const getChatUnreadCount = (params) => async (dispatch) => {
    const res = await get(apis.chat_uread_count, params)
    const response = await checkResponse(res)
    if (response?.success) {
        dispatch({
            type: ReducerType.GET_UNREAD_COUNT,
            payload: response.data?.data
        })
        return { data: response.data }
    } else {
        return { data: res }
    }
}

export const getALLUnreadCount = () => async (dispatch) => {
    const res = await get(apis.chat_uread_count)
    const response = await checkResponse(res)
    if (response?.success) {
        dispatch({
            type: ReducerType.GET_ALL_UNREAD_COUNT,
            payload: response.data?.data
        })
        return { data: response.data }
    } else {
        return { data: res }
    }
}
