import React, { useState, useEffect } from 'react'
import { Spinner, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { renderDocsView } from 'utils/constants'
import DocumentLightBox from '../devProjects/DocumentLightBox'
import EmptyImg from 'assets/dashboard/empty.svg'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addProjectsAssets, getProjectSettingsDetail, deleteProjectsAssets } from '../../../../redux/projects/action'
import { UplaodFile } from '../../../../redux/projects/action'
import { useDropzone } from 'react-dropzone'
import Flex from 'components/common/Flex'
import cloudUpload from 'assets/img/icons/cloud-upload.svg'
import { IoMdClose } from 'react-icons/io'
import { v4 as uuidv4 } from 'uuid'
import { IoMdDownload } from 'react-icons/io'
import axios from 'axios'

const Documents = () => {
  const { userDetails } = useSelector(state => state.login)
  const dispatch = useDispatch()
  const { PorjectSettings, isProjectSettings } = useSelector(state => state.projects)
  const { jobDetail } = useSelector(state => state.users)
  const { usersList } = useSelector(state => state.users)
  const [isOpenLightBox, setIsOpenLightBox] = useState(false)
  const [documentImage, setDocumentImage] = useState('')
  const [openEditor, setOpenEditor] = useState(false)
  const [files, setFiles] = useState([])
  const [ImgLoading, setImgLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    // accept: {
    //   'image/png': ['.png'],
    //   'image/jpeg': ['.jpg', '.jpeg'],
    //   'application/msword': ['.doc'],
    //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    //   'application/vnd.ms-excel': ['.xls'],
    //   'application/pdf': ['.pdf'],
    //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    // },
    onDrop: async acceptedFiles => {
      // Determine the maximum file size based on file type.

      const maxFileSizeBytes =
        acceptedFiles[0].type.startsWith('application/') || acceptedFiles[0].type.startsWith('image/')
          ? 10 * 1024 * 1024
          : 0

      // Check if the file size exceeds the maximum limit and display a toast message if so.
      if (acceptedFiles[0].size > maxFileSizeBytes) {
        if (acceptedFiles[0].type.startsWith('application/') || acceptedFiles[0].type.startsWith('image/')) {
          return toast.error('File size exceeds the maximum limit (10MB)', {})
        }
      }

      // Set image loading state to true.
      setImgLoading(true)
      const formData = new FormData()
      formData.append('file', acceptedFiles[0])

      try {
        // Upload the file using the 'uploadInnerDocuments' API.
        const response = await UplaodFile(formData)
        if (response?.data?.status_code == 201 || response?.data?.status_code == 200) {
          setImgLoading(false)
          setFiles([
            {
              id: uuidv4(),
              title: '',
              url: response?.data?.data?.file,
              source: 'requirements',
              created_by: userDetails?._id,
              date: dayjs().valueOf()
            }
          ])
          toast.success(response?.data.message, {
            position: 'top-right',
            theme: 'colored'
          })
        } else {
          setImgLoading(false)
        }
        // Check the response and extract the image URL.
      } catch (error) {
        console.error('Error:', error)
        setImgLoading(false)
      }
    }
  })

  const handleOpenEditor = () => {
    setOpenEditor(!openEditor)
  }

  const openDialog = url => {
    setDocumentImage(url)
    setIsOpenLightBox(true)
  }

  const closeDialog = url => {
    setDocumentImage('')
    setIsOpenLightBox(false)
  }
  const handleSubmit = async e => {
    e.preventDefault()

    setLoader(true)
    let params = {
      id: uuidv4(),
      projectId: jobDetail?.id,
      assetType: 2,
      title: files.length > 0 ? files[0]?.title : '',
      url: files.length > 0 ? files[0]?.url : '',
      created_by: userDetails?._id,
      date: dayjs().valueOf(),
      source: 'conversion'
    }
    try {
      let res = await addProjectsAssets(params)
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        setLoader(false)
        dispatch(getProjectSettingsDetail({ projectId: jobDetail?.id }))
        setFiles([])
        setOpenEditor(false)
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      } else {
        setLoader(false)
      }
    } catch (err) {
      toast.error(err?.data?.message, {
        theme: 'colored',
        position: 'top-right'
      })
      setLoader(false)
    }
  }

  const handleDelete = async id => {
    let params = {
      id: id,
      projectId: jobDetail?.id,
      assetType: 2
    }
    try {
      setDeleteLoader(true)
      setDeleteId(id)
      let res = await deleteProjectsAssets(params)
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        dispatch(getProjectSettingsDetail({ projectId: jobDetail?.id }))
        setFiles([])
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
        setDeleteLoader(false)
        setDeleteId('')
      }
    } catch (err) {
      setDeleteLoader(false)
      setDeleteId('')
      toast.error(err?.data?.message, {
        theme: 'colored',
        position: 'top-right'
      })
    }
  }

  const handleRemoveFile = rmDoc => {
    let filteredDocs = files?.filter(item => item?.url !== rmDoc)
    setFiles(filteredDocs)
  }

  const handleUpdateDocTitle = (index, fieldName, value) => {
    const updatedFiles = [...files]
    updatedFiles[index] = {
      ...updatedFiles[index],
      [fieldName]: value
    }
    setFiles(updatedFiles)
  }

  const fileList = files?.map((file, index) => {
    return (
      <div key={index}>
        <div className='d-flex align-items-center gap-3'>
          <div className='d-inline-block position-relative'>
            {renderDocsView(file?.url)}
            <span
              className='position-absolute d-flex flex-column align-items-center justify-content-between '
              style={{ width: '20px', height: '20px', top: -10, borderRadius: '50%', right: -10, border: 'none' }}
            >
              <span className='cursor-pointer' onClick={() => handleRemoveFile(file?.url)}>
                <IoMdClose />
              </span>
            </span>
          </div>
          <div style={{ width: '300px' }}>
            <Form.Control
              type='text'
              value={file?.title}
              name='title'
              placeholder='Title'
              onChange={e => handleUpdateDocTitle(0, 'title', e.target.value)}
            />
          </div>
        </div>

        <div></div>
      </div>
    )
  })

  const downloadFile = async (url, fileName = 'mtc-files') => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob' // Important
      })

      // Create a blob from the response
      const blob = new Blob([response.data], { type: response.headers['content-type'] })

      // Create a link element to trigger the download
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = fileName

      // Trigger the download
      downloadLink.click()
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-end w-100 mb-2'>
        <Button onClick={handleOpenEditor}>Add Document </Button>
      </div>
      <div className='mb-1'>
        {openEditor && (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Upload Document</Form.Label>
              <div {...getRootProps({ className: 'dropzone-area py-4 ' })}>
                <input {...getInputProps({ multiple: false })} disabled={ImgLoading} />
                <Flex justifyContent='center'>
                  {ImgLoading ? (
                    <Spinner size='md' />
                  ) : (
                    <>
                      <img src={cloudUpload} alt='' width={25} className='me-2' />
                      <p className='fs-9 mb-0 text-700'>Drop your Docs here</p>
                    </>
                  )}
                </Flex>
              </div>
            </Form.Group>
            <div className='mt-3 d-flex mb-4 gap-3 flex-wrap'>{fileList}</div>
            <div className='d-flex justify-content-end w-100 mt-2'>
              <Button type='submit' disabled={loader}>
                {loader ? <Spinner size='sm' /> : 'Submit'}
              </Button>
            </div>
          </Form>
        )}
      </div>

      <div style={{ height: '70vh', overflow: 'auto', position: 'relatvie' }}>
        <p className='fw-bolder  mb-3'>Documents List</p>
        {isProjectSettings ? (
          <div className='d-flex flex-column align-items-center py-3'>
            <Spinner />
          </div>
        ) : (
          <div className='d-flex align-items-center gap-3 flex-wrap'>
            {PorjectSettings?.documents.length > 0 ? (
              PorjectSettings?.documents?.map((item, index) => {
                let user = usersList?.find(user => user._id === item?.created_by)

                return (
                  <div
                    key={index}
                    style={{
                      padding: '5px 20px',
                      width: '230px',
                      background: 'rgb(236,246,255)',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderRadius: '8px',
                      position: 'relative'
                    }}
                  >
                    <div className='d-flex align-items-center gap-1 ' onClick={() => openDialog(item?.url)}>
                      {/* Displaying the modified filename */}
                      <div className='d-flex align-items-center gap-1'>
                        {renderDocsView(item?.url)}
                        <p className='mb-0 fs-10 ' style={{ wordBreak: 'break-all' }}>
                          {item?.title}
                          <p className='mb-0 fs-11'>
                            By:<span className='fw-bolder'>{user?.name ?? 'Admin'} </span>
                          </p>
                        </p>
                      </div>
                    </div>
                    <div>
                      <IoMdDownload
                        size={18}
                        style={{ cursor: 'pointer' }}
                        onClick={e => {
                          downloadFile(item?.url, item?.title)
                        }}
                      />
                      <p className='mb-0 fs-11 text-end w-100'>{dayjs.unix(item?.date / 1000).format('YYYY-MM-DD')} </p>
                    </div>

                    {item?.created_by === userDetails?._id && (
                      <span
                        className='position-absolute d-flex  align-items-center'
                        style={{
                          width: '25px',
                          height: '25px',
                          top: -10,
                          borderRadius: '50%',
                          right: -8,
                          border: 'none',
                          backgroundColor: '#F5F1EE',
                          color: 'red',
                          padding: '5px'
                        }}
                        disabled={deleteLoader}
                      >
                        {deleteLoader && deleteId == item?.id ? (
                          <Spinner style={{ width: '14px', height: '14px' }} />
                        ) : (
                          <IoMdClose onClick={() => handleDelete(item?.id)} style={{ cursor: 'pointer' }} />
                        )}
                      </span>
                    )}
                  </div>
                )
              })
            ) : (
              <>
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      height: '300px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <img src={EmptyImg} alt='empty' />
                    <p className='fw-bolder mt-2 mb-1'>No Available Documents</p>
                    <p className='fs-10 mt-2 mb-1'>Currently here is no documents for display</p>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <DocumentLightBox isOpenLightBox={isOpenLightBox} toggleHandler={closeDialog} documentImage={documentImage} />
      </div>
    </div>
  )
}

export default Documents
