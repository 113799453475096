import React, { useEffect, useState } from 'react'
import { Tab, Card, Spinner, Dropdown, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap'
import ChatContent from 'components/app/chat/content/ChatContent'
import GroupChatContent from '../chat/groupChat/groupChatContent'
import ClientChat from 'components/clientLayout/components/clientChat/ClientChatContent'
import NotificationPopup from './projectDetailViews/NotificationPopup'
import { useSelector, useDispatch } from 'react-redux'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { getJobDetail, getAllUsers } from '../../../redux/users/action'
import { updateOpportunities, getProjectSettingsDetail, getAllProjectsSettings } from '../../../redux/projects/action'
import { getAllConversations, getConvMessages, getDevConvMessages } from '../../../redux/smsMail/action'
import GroupMember from './GroupAvatar'
import { useParams } from 'react-router-dom'
import { Decrypt, isAdmin, Encrypt, isSales, isDeveloper } from 'utils/constants'
import { getAllCalenders, getSettingsCalenders } from '../../../redux/calender/action'
import { getChatUnreadCount } from '../../../redux/groupChat/action'

import { toast } from 'react-toastify'
import LinkModal from './devProjects/LinkModal'
import { IoIosLink } from 'react-icons/io'
import NotesIcon from 'assets/chat/notes.svg'
import DocIcon from 'assets/chat/doc.svg'
import InfoIcon from 'assets/chat/info.svg'
import VideoIcon from 'assets/chat/video.svg'
import GroupChat from 'assets/chat/group-chat.svg'
import GroupChatWhite from 'assets/chat/Group-chat-white.svg'
import EmailChat from 'assets/chat/email-chat.svg'
import EmailChatWhite from 'assets/chat/email-chat-white.svg'
import ClientWhite from 'assets/chat/client-white.svg'
import ClientBlack from 'assets/chat/client-black.svg'
import BugIcon from 'assets/chat/bug-white.svg'
import BellIcon from 'assets/chat/Bell.svg'

import DetailDrawer from './DetailDrawer'
import { post } from 'utils/constants'
import { apis } from 'utils/Apis'
import { fetchCalenderUrl } from 'utils/socket'
import { v4 as uuidv4 } from 'uuid'
import { RxHamburgerMenu } from 'react-icons/rx'
import { ButtonDrawer } from './ButtonDrawer'
import Badge from 'react-bootstrap/Badge'
import { ClientModal } from 'components/clientLayout/clientProjects/clientModal'
import { AdminClientModal } from './adminBugScreen/AdminClientModal'

const ChatTab = ({ loader, activeTab }) => {
  return (
    <Tab.Container id='left-tabs-example' defaultActiveKey='0'>
      <Row>
        <Col xs={12} className='p-0'>
          <div className='border-none'>
            <ChatContent loader={loader} activeTab={activeTab} />
          </div>
        </Col>
      </Row>
    </Tab.Container>
  )
}

const GroupChatTab = ({ activeTab }) => {
  return (
    <Tab.Container id='left-tabs-example' defaultActiveKey='0'>
      <Row>
        <Col xs={12} className='p-0'>
          <div className='border-none'>
            <GroupChatContent activeTab={activeTab} />
          </div>
        </Col>
      </Row>
    </Tab.Container>
  )
}
const ClientChatTab = ({ activeTab }) => {
  return (
    <Tab.Container id='left-tabs-example' defaultActiveKey='0'>
      <Row>
        <Col xs={12} className='p-0'>
          <div className='border-none'>
            <ClientChat activeTab={activeTab} />
          </div>
        </Col>
      </Row>
    </Tab.Container>
  )
}


function ProjectDetail() {
  const navigate = useNavigate()
  let { id, stage, pipe } = useParams()

  const dispatch = useDispatch()
  let decryptedId = Decrypt(id)
  const { userDetails } = useSelector(state => state.login)
  const { isJobDetail, jobDetail } = useSelector(state => state.users)
  const { pipelines, PorjectSettings, allProjectSettings, openDetailModal } = useSelector(state => state.projects)
  const { Conversations, isConvMessages } = useSelector(state => state.smsEmail)
  const { isGroupMessages, isClientMessages, unreadCount } = useSelector(state => state.groupChat)

  const { calenders, savedCalenders, savedPipleline } = useSelector(state => state.calenders)
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [projectStatus, setProjectStatus] = useState('')
  const [oneTimeLink, setOneTimeLink] = useState('')
  const [isLink, setIsLink] = useState(false)
  const [linkUniqueHash, setLinkUniqueHash] = useState('')
  const [isStatus, setIsStatus] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [openNotificationPopup, setOpenNotificationPopup] = useState(false)
  const [openDetailTitle, setOpenDetailTitle] = useState('')
  const [openButtonDrawer, setOpenButtonDrawer] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [activeTab, setActiveTab] = useState('clientChat')
  const [loader, setLoader] = useState(false)
  const [selectedPipeline, setSelectedPipeline] = useState(null)
  let selectedProjectSetting = allProjectSettings?.find(item => item?.projectId === decryptedId)
  const [conversationType, setConversationType] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [openProject, setOpenProject] = useState(true)
  const [stageValue, setStageValue] = useState('')
  const [pipelineID, setPipelineId] = useState('')
  const [clentChatUnreadCount, setClientChatUnreadCount] = useState(0)
  const [teamChatUnreadCount, setTeanChatUnreadCount] = useState(0)
  const [isSalesDisabled, setIsSalesDisabled] = useState(false)
  let IsValidProject = jobDetail?.pipelineStageId !== selectedPipeline?.stages[selectedPipeline?.stages?.length - 2]?.id
  useEffect(() => {
    let clientChatUnreadCount = unreadCount?.find((item) => item?.type === 'client')?.count ?? 0
    let teamChatUnreadCount = unreadCount?.find((item) => item?.type === 'team')?.count ?? 0
    setClientChatUnreadCount(clientChatUnreadCount ?? 0)
    setTeanChatUnreadCount(teamChatUnreadCount ?? 0)
  }, [unreadCount])


  useEffect(() => {
    if (stage) {
      let stageVal = Decrypt(stage)
      setStageValue(stageVal)
      navigate(`/app/projects/${Encrypt(decryptedId)}/detail`, { replace: true })

    }
  }, [stage])




  useEffect(() => {
    if (pipe) {
      let pipeVal = Decrypt(pipe)
      setPipelineId(pipeVal)
      navigate(`/app/projects/${Encrypt(decryptedId)}/detail`, { replace: true })
    }
  }, [pipe])

  useEffect(() => {
    if (savedPipleline && pipelines) {
      const selectedPipe = pipelines?.find(item => item?.id === savedPipleline?.pipelineId)
      setSelectedPipeline(selectedPipe)
    }
  }, [savedPipleline, pipelines])

  useEffect(() => {
    // Track whether both conditions are false
    const shouldHideLoader = !isJobDetail && !isConvMessages && !isGroupMessages && !isClientMessages
    if (isJobDetail || isConvMessages || isGroupMessages || isClientMessages) {
      setLoader(true)
    } else if (shouldHideLoader) {
      // Hide loader only when both conditions are false
      setTimeout(() => {
        setLoader(false)
      }, 2000)
    }
  }, [isJobDetail, isGroupMessages, isConvMessages, isClientMessages])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleOpenDetail = name => {
    if (name == 'Bugs') {
      handleClientModal()
    } else if (name == 'Notifications Settings') {
      setOpenNotificationPopup(true)
    } else {
      setOpenDetail(true)
      setOpenDetailTitle(name)
    }
  }

  useEffect(() => {
    if (openDetailModal.show) {
      handleOpenDetail(openDetailModal.title)
    }
  }, [openDetailModal.show])

  const handleCloseDetail = () => {
    setOpenDetail(false)
    dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: false, title: '' } })

    setOpenDetailTitle('')
  }

  useEffect(() => {
    dispatch({ type: 'CLEAR_JOB_DETAIL' })
    if (decryptedId) {
      dispatch(getJobDetail({ id: decryptedId }))
      dispatch(getProjectSettingsDetail({ projectId: decryptedId }))
      // dispatch(getAllGroupMessages({ project_id: decryptedId, limit: 20, page: 1, type: 'team' }))
      dispatch(getChatUnreadCount({ project_id: decryptedId }))
      dispatch(getAllCalenders())
      dispatch(getSettingsCalenders())

      if (isAdmin()) {
        dispatch(getAllUsers())
      }
    }
  }, [decryptedId])
  useEffect(() => {
    if (savedPipleline) {
      dispatch(getAllProjectsSettings({ pipelineId: savedPipleline?.pipelineId }))
    }
  }, [savedPipleline])
  useEffect(() => {
    if (jobDetail && selectedPipeline) {
      dispatch(getAllConversations({ contactId: jobDetail?.contactId }))
      const filteredData = selectedPipeline?.stages
        ?.slice(startIndex)
        ?.find((item, index) => item.id == jobDetail?.pipelineStageId)

      if (filteredData) {
        setProjectStatus(filteredData?.name)
      }
      // else {
      //   navigate('/app/projects')
      // }
    }
  }, [jobDetail, selectedPipeline])

  useEffect(() => {
    if (PorjectSettings && userDetails?.userType !== 3 && openProject) {
      if (PorjectSettings?.sms_chat_enabled === 1 && PorjectSettings?.email_chat_enabled === 1) {
        setActiveTab('clientChat')
        setConversationType('TYPE_SMS,TYPE_EMAIL')
      } else if (PorjectSettings?.sms_chat_enabled === 1 && PorjectSettings?.email_chat_enabled === 0) {
        setActiveTab('clientChat')
        setConversationType('TYPE_SMS')
      } else if (PorjectSettings?.sms_chat_enabled === 0 && PorjectSettings?.email_chat_enabled === 1) {
        setActiveTab('clientChat')
        setConversationType('TYPE_EMAIL')
      } else if (
        userDetails?.userType === 2 &&
        PorjectSettings?.sms_chat_enabled === 0 &&
        PorjectSettings?.email_chat_enabled === 0 &&
        PorjectSettings?.team_chat_enabled === 1
      ) {
        setActiveTab('groupChat')
      }
    } else if (userDetails?.userType === 3) {
      setActiveTab('clientChat')
    }
    dispatch({
      type: 'DECREASE_COUNT',
      payload: 'client'
    })
    setTimeout(() => setOpenProject(false), 1000)
  }, [PorjectSettings, userDetails, openProject])

  useEffect(() => {
    if (Conversations?.length > 0 && !isJobDetail) {
      if (isAdmin() || isSales()) {
        dispatch(getConvMessages({ conversationId: Conversations[0]?.id, type: 'TYPE_SMS,TYPE_EMAIL' }))
      } else {
        if (conversationType != '') {
          dispatch(getDevConvMessages({ conversationId: Conversations[0]?.id, type: conversationType }))
        }
      }
    }
  }, [Conversations, isJobDetail, conversationType])

  const handleStatus = async item => {
    if (
      userDetails?.userType === 1 && item?.id === selectedPipeline?.stages[selectedPipeline?.stages?.length - 1]?.id
      && (!PorjectSettings?.bugCount || PorjectSettings?.bugCount == 0)
    ) {
      return toast.error(`Projects in Maintainence stage should have atleast one open bug
      `, {
        theme: 'colored',
        position: 'top-right'
      })
    } else if (userDetails?.userType === 2 && (jobDetail?.pipelineStageId === selectedPipeline?.stages[0]?.id && item?.id !== selectedPipeline?.stages[0]?.id && item?.id !== selectedPipeline?.stages[1]?.id)) {
      return toast.error(`Please change the status to Post-Discovery first.
        `, {
        theme: 'colored',
        position: 'top-right'
      })
    } else if (userDetails?.userType === 2 && (jobDetail?.pipelineStageId === selectedPipeline?.stages[1]?.id && item?.id !== selectedPipeline?.stages[0]?.id && item?.id !== selectedPipeline?.stages[1]?.id)) {
      return toast.error(`You don't have permission to change the status to ${item?.name}`, {
        theme: 'colored',
        position: 'top-right'
      })
    } else if (userDetails?.userType === 2 &&
      (jobDetail?.pipelineStageId !== selectedPipeline?.stages[0]?.id &&
        jobDetail?.pipelineStageId !== selectedPipeline?.stages[1]?.id) &&
      (item?.id === selectedPipeline?.stages[0]?.id ||
        item?.id === selectedPipeline?.stages[1]?.id)) {
      return toast.error(`You don't have permission to change the status to ${item?.name}`, {
        theme: 'colored',
        position: 'top-right'
      });
    } else if (userDetails?.userType === 4 && (jobDetail?.pipelineStageId === selectedPipeline?.stages[0]?.id && item?.id !== selectedPipeline?.stages[0]?.id && item?.id !== selectedPipeline?.stages[1]?.id)) {
      return toast.error(`Please change the status to Post-Discovery first.
        `, {
        theme: 'colored',
        position: 'top-right'
      })
    } else if (userDetails?.userType === 4 && (jobDetail?.pipelineStageId === selectedPipeline?.stages[1]?.id && item?.id !== selectedPipeline?.stages[0]?.id && item?.id !== selectedPipeline?.stages[1]?.id && item?.id !== selectedPipeline?.stages[2]?.id)) {
      return toast.error(`You don't have permission to change the status to ${item?.name}
        `, {
        theme: 'colored',
        position: 'top-right'
      })
    } else {
      setIsStatus(true)
      try {
        const apiParams = {
          opportunities_id: jobDetail?.id,
          pipelineId: savedPipleline?.pipelineId,
          pipelineStageId: item?.id
        }

        let res = await updateOpportunities(apiParams)
        if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
          dispatch(getJobDetail({ id: decryptedId }))
          setProjectStatus(item?.name)
          setIsStatus(false)
          toast.success(res?.data?.message, {
            theme: 'colored',
            position: 'top-right'
          })
        } else {
          toast.error('Something went wrong', {
            theme: 'colored',
            position: 'top-right'
          })
          setIsStatus(false)
        }
      } catch (error) {
        console.error('Error occurred while updating opportunities:', error)
        toast.error('An error occurred while updating opportunities', {
          theme: 'colored',
          position: 'top-right'
        })
        setIsStatus(false)
      }
    }
  }
  let devCalenders = calenders?.filter(item => userDetails?.calendars?.includes(item?.id)) ?? []

  let startIndex = isAdmin() ? 0 : 0
  let endIndex = isAdmin() ? selectedPipeline?.stages?.length : isSales() ? selectedPipeline?.stages?.length - 4 : selectedPipeline?.stages?.length - 2
  const handleSelectCalender = async calender => {
    setIsLink(true)
    setLinkUniqueHash(uuidv4())
    const index = savedCalenders?.findIndex(item => item.calendarId === calender.id)
    if (savedCalenders[index]?.url) {
      let params = {
        calendarId: calender?.id,
        cid: jobDetail?.contactId
      }

      await post(apis.calender_generate_link, params)
    } else {
      setIsLink(false)
      toast.error('No URL assigned for this calendar', {
        theme: 'colored',
        position: 'top-right'
      })
    }
  }
  let calenderOptions = isAdmin() ? calenders : devCalenders
  useEffect(() => {
    ; (async () => {
      await fetchCalenderUrl(setOneTimeLink, setIsLink, setOpenLinkModal, setLinkUniqueHash, linkUniqueHash)
    })()

    return () => { }
  }, [linkUniqueHash])

  const handleHamBurger = () => {
    setOpenButtonDrawer(true)
  }

  const handleCloseHamBurger = () => {
    setOpenButtonDrawer(false)
  }

  const handleClientModal = () => {
    setOpenModal(true)
  }

  useEffect(() => {
    if (decryptedId) {


      const queryParams = new URLSearchParams(window.location.search);
      const isOpenParam = queryParams.get('isOpen');
      if (isOpenParam === 'true') {
        setOpenModal(true)
      }
    }
  }, [decryptedId]);




  useEffect(() => {
    let timeoutId;

    if (jobDetail) {
      timeoutId = setTimeout(() => {
        if (IsValidProject || userDetails?.userType == 1 || userDetails?.userType == 3) {
          console.log('')
        } else {
          navigate(`/app/projects`, { replace: true });
          toast.error("You do not have permission to access this project.", {
            position: 'top-right',
            theme: 'colored'
          })
        }
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [jobDetail, IsValidProject, navigate, userDetails]);

  useEffect(() => {
    if (jobDetail && userDetails?.userType === 4 && jobDetail?.pipelineStageId !== selectedPipeline?.stages[0]?.id && jobDetail?.pipelineStageId !== selectedPipeline?.stages[1]?.id) {

      setIsSalesDisabled(true)
    } else {
      setIsSalesDisabled(false)

    }
  }, [jobDetail, selectedPipeline])
  return (
    <>
      <div
        className={
          width < 856
            ? 'd-flex justify-content-between flex-column-reverse gap-2'
            : 'd-flex justify-content-between gap-2'
        }
      >
        <div style={{ width: width > 856 ? 'calc(100% - 80px)' : '100%' }}>
          <div className='position-relative' style={{ background: '#fff', padding: '10px 20px', borderRadius: '10px' }}>
            <div style={{ borderBottom: '1px solid rgb(233 233 233)' }}>
              <div className='d-flex align-items-center justify-content-between'>
                <div
                  className='d-flex align-items-center'
                  style={{ gap: width < 978 ? '4px' : '16px', marginBottom: '6px' }}
                >
                  <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{jobDetail?.name}</Tooltip>}>
                    <Card.Title
                      id='example-custom-modal-styling-title'
                      style={{
                        maxWidth:
                          width < 978
                            ? '150px'
                            : width < 1130
                              ? selectedProjectSetting?.assignedDev?.length > 1
                                ? '150px'
                                : '250px'
                              : selectedProjectSetting?.assignedDev?.length > 1
                                ? '300px'
                                : '400px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                      }}
                    >
                      {jobDetail?.name}
                    </Card.Title>
                  </OverlayTrigger>
                  <GroupMember
                    avatarSize='l'
                    users={selectedProjectSetting?.assignedDev}
                    showMember={2}
                    className='d-none d-sm-block'
                  />
                  {!isAdmin() &&
                    PorjectSettings?.sms_chat_enabled === 0 &&
                    PorjectSettings?.email_chat_enabled === 0 &&
                    PorjectSettings?.team_chat_enabled === 0 ? null : (
                    <Badge bg='info'>
                      {activeTab === 'chat'
                        ? 'SMS/Email Chat'
                        : activeTab === 'groupChat'
                          ? 'Team Chat'
                          : 'Client Chat'}
                    </Badge>
                  )}{' '}
                </div>
                {userDetails && userDetails?.userType !== 3 && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '10px' }}>
                    {width > 600 && (
                      <>
                        {userDetails?.userType == 2 &&
                          (jobDetail?.pipelineStageId ===
                            selectedPipeline?.stages[selectedPipeline?.stages?.length - 1]?.id ||
                            jobDetail?.pipelineStageId ==
                            selectedPipeline?.stages[selectedPipeline?.stages?.length - 2]?.id) ? null : (
                          <OverlayTrigger placement={'top'} overlay={<Tooltip id={`ewdd`} style={{ position: 'fixed' }}>Project Status</Tooltip>}>
                            <div className='min-w-0'>
                              <Dropdown style={{ borderRadius: '50px' }}>
                                <Dropdown.Toggle
                                  style={{ borderRadius: '50px' }}
                                  variant={
                                    projectStatus == 'Discovery'
                                      ? 'warning'
                                      : projectStatus == 'Post Discovery'
                                        ? 'secondary'
                                        : projectStatus == 'Complete'
                                          ? 'success'
                                          : projectStatus == 'In-Progress'
                                            ? 'primary'
                                            : projectStatus == 'In-Review'
                                              ? 'info'
                                              : 'secondary'
                                  }
                                  id='dropdown-basic'
                                  disabled={isStatus ? true : isSalesDisabled}
                                >
                                  {isStatus ? <Spinner size='sm' className='me-3' /> : <>{projectStatus}</>}
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{ minWidth: '100%', overflowWrap: 'break-word', overflow: 'auto' }}
                                >
                                  {selectedPipeline?.stages?.slice(startIndex, endIndex)?.map((item, index) => (
                                    <Dropdown.Item
                                      onClick={() => handleStatus(item)}
                                      key={index}
                                      style={{ wordBreak: 'break-all', maxWidth: '100%' }}
                                    >
                                      {item.name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </OverlayTrigger>
                        )}



                        <OverlayTrigger
                          placement={'top'}
                          overlay={<Tooltip id={`ewdd`}>Select a calendar to generate a one-time link</Tooltip>}
                        >
                          <Dropdown style={{ borderRadius: '50px' }}>
                            <Dropdown.Toggle
                              disabled={isLink}
                              style={{ borderRadius: '50px' }}
                              variant='primary'
                              id='dropdown-basic'
                            >
                              {isLink ? (
                                <Spinner size='sm' className='me-3' />
                              ) : (
                                <>
                                  <IoIosLink
                                    size={18}
                                    style={{
                                      marginRight: '2px'
                                    }}
                                  />
                                  {width > 786
                                    ? width < 930 && selectedProjectSetting?.assignedDev?.length > 1
                                      ? ''
                                      : 'Generate Calender link'
                                    : ''}
                                </>
                              )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ minWidth: '100%', overflowWrap: 'break-word', overflow: 'auto' }}>
                              {calenderOptions?.length > 0 ? calenderOptions?.map((item, index) => (
                                <Dropdown.Item
                                  onClick={() => handleSelectCalender(item)}
                                  key={index}
                                  style={{ wordBreak: 'break-all', maxWidth: '100%' }}
                                >
                                  {item.name}
                                </Dropdown.Item>
                              )) :
                                <Dropdown.Item


                                  style={{ wordBreak: 'break-all', maxWidth: '100%' }}
                                >
                                  No Assigned Calenders
                                </Dropdown.Item>
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </OverlayTrigger>
                      </>
                    )}
                    {width > 856 && (
                      <div>
                        <FaArrowLeftLong
                          onClick={() => {
                            setStageValue('')
                            setPipelineId('')
                            navigate('/app/projects')
                          }}
                          size={22}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {userDetails && userDetails?.userType !== 3 && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '10px' }}>
                  {width < 600 && (
                    <div className='d-flex w-100 gap-1 flex-wrap '>
                      {userDetails?.userType == 2 &&
                        (jobDetail?.pipelineStageId ===
                          selectedPipeline?.stages[selectedPipeline?.stages?.length - 1]?.id ||
                          jobDetail?.pipelineStageId ==
                          selectedPipeline?.stages[selectedPipeline?.stages?.length - 2]?.id) ? null : (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip style={{ position: 'fixed' }} id={`ewdd`}>Project Status</Tooltip>}>
                          <div className='min-w-0'>
                            <Dropdown style={{ borderRadius: '50px' }}>
                              <Dropdown.Toggle
                                style={{ borderRadius: '50px' }}
                                variant={
                                  projectStatus == 'Discovery'
                                    ? 'warning'
                                    : projectStatus == 'Post Discovery'
                                      ? 'secondary'
                                      : projectStatus == 'Complete'
                                        ? 'success'
                                        : projectStatus == 'In-Progress'
                                          ? 'primary'
                                          : projectStatus == 'In-Review'
                                            ? 'info'
                                            : 'secondary'
                                }
                                id='dropdown-basic'
                                disabled={isStatus ? true : isSalesDisabled}

                              >
                                {isStatus ? <Spinner size='sm' className='me-3' /> : <>{projectStatus}</>}
                              </Dropdown.Toggle>

                              <Dropdown.Menu style={{ minWidth: '100%', overflowWrap: 'break-word', overflow: 'auto' }}>
                                {selectedPipeline?.stages?.slice(startIndex)?.map((item, index) => (
                                  <Dropdown.Item
                                    onClick={() => handleStatus(item)}
                                    key={index}
                                    style={{ wordBreak: 'break-all', maxWidth: '100%' }}
                                  >
                                    {item.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </OverlayTrigger>
                      )}
                      <OverlayTrigger
                        placement={'top'}
                        overlay={<Tooltip id={`ewdd`}>Select a calendar to generate a one-time link</Tooltip>}
                      >
                        <Dropdown style={{ borderRadius: '50px' }}>
                          <Dropdown.Toggle
                            disabled={isLink}
                            style={{ borderRadius: '50px' }}
                            variant='primary'
                            id='dropdown-basic'
                          >
                            {isLink ? (
                              <Spinner size='sm' className='me-3' />
                            ) : (
                              <>
                                <IoIosLink
                                  size={18}
                                  style={{
                                    marginRight: '2px'
                                  }}
                                />
                              </>
                            )}
                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{ minWidth: '100%', overflowWrap: 'break-word', overflow: 'auto' }}>
                            {calenderOptions?.map((item, index) => (
                              <Dropdown.Item
                                onClick={() => handleSelectCalender(item)}
                                key={index}
                                style={{ wordBreak: 'break-all', maxWidth: '100%' }}
                              >
                                {item.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              )}
            </div>
            {(IsValidProject || userDetails?.userType === 1 || userDetails?.userType === 4 || userDetails?.userType === 3) &&
              <Card.Body>
                {(() => {
                  switch (activeTab) {
                    case 'groupChat':
                      return <GroupChatTab activeTab={activeTab} />
                    case 'chat':
                      return <ChatTab loader={loader} activeTab={activeTab} />
                    case 'clientChat':
                      return <ClientChatTab activeTab={activeTab} />
                    default:
                      return <ClientChatTab activeTab={activeTab} />
                  }
                })()}

              </Card.Body>
            }
            {loader && (
              //  && isConvMessages
              <div
                className='position-absolute top-0'
                style={{
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 999,
                  bottom: 0,
                  backdropFilter: 'blur(8px)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Spinner />
              </div>
            )}
            <LinkModal openLinkModal={openLinkModal} setOpenLinkModal={setOpenLinkModal} oneTimeLink={oneTimeLink} />
          </div>
        </div>

        {userDetails && (
          <div style={{ width: width < 856 ? '100%' : '60px' }}>
            {width < 856 ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RxHamburgerMenu
                  style={{ color: '#000', fontSize: '18px', cursor: 'pointer' }}
                  onClick={handleHamBurger}
                />
                <div style={{ marginRight: '5px' }}>
                  <FaArrowLeftLong
                    onClick={() => {
                      setStageValue('')
                      setPipelineId('')
                      navigate('/app/projects')
                    }}
                    size={22}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            ) : (
              <>
                {/* {!isAdmin() &&
                  PorjectSettings?.sms_chat_enabled === 0 &&
                  PorjectSettings?.email_chat_enabled === 0 &&
                  PorjectSettings?.team_chat_enabled === 0 ? null : ( */}
                <div
                  style={{ background: '#E6DEF4', borderRadius: '50px', padding: '3px', marginBottom: '20px' }}
                  className={
                    width < 856
                      ? 'd-flex  flex-row  gap-3 align-items-center'
                      : 'd-flex  flex-column  gap-3 align-items-center'
                  }
                >
                  <OverlayTrigger
                    placement={'left'}
                    overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }} >Client Chat</Tooltip> : ""}
                  >
                    <div
                      onClick={() => {
                        setActiveTab('clientChat'), dispatch({
                          type: 'DECREASE_COUNT',
                          payload: 'client'
                        })
                      }
                      }
                      style={{
                        position: 'relative',
                        background: activeTab === 'clientChat' ? '#682FCC' : '#fff',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '50px',
                        width: '50px',
                        borderRadius: '50px',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                      }}
                    >
                      {activeTab === 'clientChat' ? <img src={ClientWhite} /> : <img src={ClientBlack} />}
                      {clentChatUnreadCount > 0 &&
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {clentChatUnreadCount < 100 ? clentChatUnreadCount : "99+"}
                        </span>
                      }

                    </div>
                  </OverlayTrigger>

                  {(isAdmin() || isSales() || PorjectSettings?.team_chat_enabled === 1) && userDetails?.userType !== 3 ? (
                    <OverlayTrigger
                      placement={'left'}
                      overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>Team Chat</Tooltip> : ''}
                    >
                      <div
                        onClick={() => {
                          setActiveTab('groupChat'),

                            dispatch({
                              type: 'DECREASE_COUNT',
                              payload: 'team'
                            })

                        }}
                        style={{
                          position: 'relative',
                          background: activeTab === 'groupChat' ? '#682FCC' : '#fff',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '50px',
                          width: '50px',
                          borderRadius: '50px',
                          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                        }}
                      >
                        {activeTab === 'groupChat' ? <img src={GroupChatWhite} /> : <img src={GroupChat} />}
                        {teamChatUnreadCount > 0 &&
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {teamChatUnreadCount < 100 ? teamChatUnreadCount : "99+"}
                          </span>
                        }
                      </div>
                    </OverlayTrigger>
                  ) : null}



                  {/* {!isDeveloper() &&
                    <>
                      {(!isAdmin() &&
                        PorjectSettings?.sms_chat_enabled === 0 &&
                        PorjectSettings?.email_chat_enabled === 0) ||
                        userDetails?.userType === 3 ? null : (
                        <OverlayTrigger
                          placement={'left'}
                          overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>SMS/Email Chat</Tooltip> : ''}
                        >
                          <div
                            onClick={() => setActiveTab('chat')}
                            style={{
                              background: activeTab === 'chat' ? '#682FCC' : '#fff',

                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              height: '50px',
                              width: '50px',
                              borderRadius: '50px',
                              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                            }}
                          >
                            {activeTab === 'chat' ? <img src={EmailChatWhite} /> : <img src={EmailChat} />}
                          </div>
                        </OverlayTrigger>
                      )}

                    </>
                  } */}
                  {((isAdmin() || isSales()) || (userDetails?.userType === 2 && PorjectSettings?.sms_chat_enabled === 1 || PorjectSettings?.email_chat_enabled === 1)) &&


                    <OverlayTrigger
                      placement={'left'}
                      overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>SMS/Email Chat</Tooltip> : ''}
                    >
                      <div
                        onClick={() => setActiveTab('chat')}
                        style={{
                          background: activeTab === 'chat' ? '#682FCC' : '#fff',

                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '50px',
                          width: '50px',
                          borderRadius: '50px',
                          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                        }}
                      >
                        {activeTab === 'chat' ? <img src={EmailChatWhite} /> : <img src={EmailChat} />}
                      </div>
                    </OverlayTrigger>

                  }





                </div>
                {/* )} */}

                <div
                  style={{ background: '#E6DEF4', borderRadius: '50px', padding: '3px' }}
                  className={
                    width < 856
                      ? 'd-flex  flex-row  gap-3 align-items-center'
                      : 'd-flex  flex-column  gap-3 align-items-center'
                  }
                >
                  <OverlayTrigger
                    placement={'left'}
                    overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>Project Information</Tooltip> : ''}
                  >
                    <div
                      onClick={() => handleOpenDetail('Information')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '50px',
                        width: '50px',
                        borderRadius: '50px',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                      }}
                    >
                      <img src={InfoIcon} />
                    </div>
                  </OverlayTrigger>

                  {activeTab === 'clientChat' ? (
                    <>
                      <OverlayTrigger
                        placement={'left'}
                        overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>Documents</Tooltip> : ''}
                      >
                        <div
                          onClick={() => handleOpenDetail('Client Documents')}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: '50px',
                            width: '50px',
                            borderRadius: '50px',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                          }}
                        >
                          <img src={DocIcon} />
                        </div>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <>
                      <OverlayTrigger
                        placement={'left'}
                        overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>Notes</Tooltip> : ''}
                      >
                        <div
                          onClick={() => handleOpenDetail('Notes')}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: '50px',
                            width: '50px',
                            borderRadius: '50px',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                          }}
                        >
                          <img src={NotesIcon} />
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement={'left'}
                        overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>Documents</Tooltip> : ''}
                      >
                        <div
                          onClick={() => handleOpenDetail('Documents')}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: '50px',
                            width: '50px',
                            borderRadius: '50px',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                          }}
                        >
                          <img src={DocIcon} />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement={'left'}
                        overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>Videos</Tooltip> : ''}
                      >
                        <div
                          onClick={() => handleOpenDetail('Videos')}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: '50px',
                            width: '50px',
                            borderRadius: '50px',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                          }}
                        >
                          <img src={VideoIcon} />
                        </div>
                      </OverlayTrigger>
                    </>
                  )}
                </div>
                {userDetails?.userType === 2 && jobDetail?.pipelineStageId !== selectedPipeline?.stages[selectedPipeline?.stages?.length - 1]?.id
                  ? null :

                  <div
                    style={{ background: '#E6DEF4', borderRadius: '50px', padding: '3px', marginTop: '20px' }}
                    className={
                      width < 856
                        ? 'd-flex  flex-row  gap-3 align-items-center'
                        : 'd-flex  flex-column  gap-3 align-items-center'
                    }
                  >
                    <OverlayTrigger
                      placement={'left'}
                      overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>Bugs</Tooltip> : ''}
                    >
                      <div
                        onClick={() => handleOpenDetail('Bugs')}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '50px',
                          width: '50px',
                          background: '#F55734',
                          borderRadius: '50px',
                          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                        }}
                      >
                        <img src={BugIcon} />
                      </div>
                    </OverlayTrigger>
                  </div>
                }
                <div
                  style={{ background: '#E6DEF4', borderRadius: '50px', padding: '3px', marginTop: '20px' }}
                  className={
                    width < 856
                      ? 'd-flex  flex-row  gap-3 align-items-center'
                      : 'd-flex  flex-column  gap-3 align-items-center'
                  }
                >
                  <OverlayTrigger
                    placement={'left'}
                    overlay={width > 856 ? <Tooltip id={`ewdd`} style={{ position: "fixed" }}>Notifications Settings</Tooltip> : ''}
                  >
                    <div
                      onClick={() => handleOpenDetail('Notifications Settings')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '50px',
                        width: '50px',
                        background: '#fff',
                        borderRadius: '50px',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                      }}
                    >
                      <img src={BellIcon} />
                    </div>
                  </OverlayTrigger>
                </div>

              </>
            )}
          </div>
        )}
      </div>
      <DetailDrawer openDetail={openDetail} openDetailTitle={openDetailTitle} handleCloseDetail={handleCloseDetail} />
      {userDetails && (
        <ButtonDrawer
          openButtonDrawer={openButtonDrawer}
          handleCloseHamBurger={handleCloseHamBurger}
          handleOpenDetail={handleOpenDetail}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          teamChatUnreadCount={teamChatUnreadCount}
          clentChatUnreadCount={clentChatUnreadCount}

        />
      )}
      {userDetails?.userType == 3 || userDetails?.userType == 2 ? (
        <ClientModal
          show={openModal}
          handleClose={() => {
            setOpenModal(false)
            navigate(`/app/projects/${id}/detail`)
          }}
          projectDetail={selectedProjectSetting ?? jobDetail}
          stage={stageValue}
          setOpenClientModal={setOpenModal}
        />
      ) : (
        <AdminClientModal
          show={openModal}
          handleClose={() => {
            setOpenModal(false)
            navigate(`/app/projects/${id}/detail`)

          }}
          projectDetail={selectedProjectSetting ?? jobDetail}
          pipelineID={pipelineID}
          selectedPipeLine={selectedPipeline}
          task={jobDetail}
          setOpenClientModal={setOpenModal}
        />
      )}

      <NotificationPopup openNotificationPopup={openNotificationPopup} handleCloseNotificationPopup={() => setOpenNotificationPopup(false)} task={jobDetail} />
    </>
  )
}

export default ProjectDetail
