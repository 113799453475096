import React, { useEffect, useState } from 'react'
import PasswordResetForm from 'components/authentication/PasswordResetForm'
import AuthCardLayout from 'layouts/AuthCardLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import { getWithoutToken } from 'utils/constants'
import { apis } from 'utils/Apis'
import { toast } from 'react-toastify'

const PasswordReset = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [userEmail, setUserEmail] = useState('')
  const queryParams = new URLSearchParams(search)
  const id = queryParams.get('id')
  const type = queryParams.get('type')
  const linkHash = queryParams.get('linkHash')
  const [isDisplay, setIsDisplay] = useState(false)
  useEffect(() => {
    if (!id && !type) {
      toast.error('Sorry ,this Link in not Valid !', {
        position: 'top-right',
        theme: 'colored'
      })

      navigate('/mtc/auth/login')

    } else if (!id && type) {
      toast.error('Sorry ,this Link in not Valid !', {
        position: 'top-right',
        theme: 'colored'
      })
    } else if (type == 2 && !linkHash) {
      toast.error('Sorry ,this Link in not Valid !', {
        position: 'top-right',
        theme: 'colored'
      })
    } else if (id && !type) {
      toast.error('Sorry ,this Link in not Valid !', {
        position: 'top-right',
        theme: 'colored'
      })
    } else {
      handleUserProfile()
    }
  }, [id])

  const handleUserProfile = async () => {
    let params = {
      user_id: id,
      type: parseInt(type)
    }
    if (type == 2) {
      params.linkHash = linkHash
    }

    const response = await getWithoutToken(apis.user_profile, params)
    if (response?.data?.status === 0) {
      setIsDisplay(false)

      toast.error(' Link is Expired !', {
        position: 'top-right',
        theme: 'colored'
      })
      navigate('/mtc/auth/login')
    } else {
      setUserEmail(response?.data?.data?.email ?? '')
      setIsDisplay(true)

    }
  }

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <AuthCardLayout>
      {isDisplay ?
        <>

          <h1 style={{ color: '#000', fontWeight: '600', fontSize: width > 769 ? '30px' : '35px' }}>
            {type != 1 && "Reset"}
            <br />  {type == 1 ? "Set Password" : " Password"}
          </h1>
          <p style={{ color: '#808080', fontSize: '15px', fontWeight: '500' }}>{type == 1 ? "Set your password" : "Enter your new password for reset"}</p>
          <PasswordResetForm layout='card' user_id={id} userEmail={userEmail} />
        </> : ""

      }

    </AuthCardLayout>
  )
}

export default PasswordReset
