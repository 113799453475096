export const ReducerType = {
    // basic Details
    GET_ALL_NOTIFICATION_ACTION_START: 'GET_ALL_NOTIFICATION_ACTION_START',
    GET_ALL_NOTIFICATION: 'GET_ALL_NOTIFICATION',
    GET_ALL_NOTIFICATION_ERROR: 'GET_ALL_NOTIFICATION_ERROR',
    GET_LATEST_NOTIFICATION: 'GET_LATEST_NOTIFICATION',
    GET_OLD_NOTIFICATIONS_START: "GET_OLD_NOTIFICATIONS_START",
    GET_OLD_NOTIFICATIONS: "GET_OLD_NOTIFICATIONS",
    GET_OLD_NOTIFICATIONS_ERROR: "GET_OLD_NOTIFICATIONS_ERROR",
    READ_SINGLE_NOTIFICATION: "READ_SINGLE_NOTIFICATION",
    READ_ALL_NOTIFICATIONS: "READ_ALL_NOTIFICATIONS",
    GET_PUSH_NOTIFICATION: "GET_PUSH_NOTIFICATION"
}
