import React, { useState } from 'react'
import { Button, Form, Modal, Spinner, Card } from 'react-bootstrap'
import { RiErrorWarningLine } from 'react-icons/ri'
import { apis } from 'utils/Apis'
import { put } from 'utils/constants'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

export const StatusFirstModal = ({ isOpenStatusModal, handleCloseStatusModal, userDeleteDetail, statusVal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const disptach = useDispatch()

  const handleStatusChange = async () => {
    setIsLoading(true)
    let payload = {
      _id: userDeleteDetail?._id,
      isActive: statusVal
    }
    const response = await put(apis.user_status_change, payload)
    if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
      setIsLoading(false)
      toast.success(response?.data?.message, {
        theme: 'colored',
        position: 'top-right'
      })
      disptach({ type: 'USER_UPDATE_STATUS', payload: payload })
      handleCloseStatusModal()
    } else {
      setIsLoading(false)
      toast.error(response?.data?.message, {
        theme: 'colored',
        position: 'top-right'
      })
      handleCloseStatusModal()
    }
  }

  return (
    <>
      <Modal
        show={isOpenStatusModal}
        onHide={handleCloseStatusModal}
        aria-labelledby='example-custom-modal-styling-title'
        verticallyCentered
        centered
        size='md'
        style={{
          borderRadius: '20px'
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{
            height: 'auto',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <RiErrorWarningLine style={{ color: 'rgb(249 111 136)', fontSize: '60px' }} />
          <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>
            {' '}
            Are you Sure you want to {statusVal == 1 ? `Active` : `In-Active`} this User ?
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              gap: '10px'
            }}
          >
            <Button variant='secondary' onClick={handleCloseStatusModal}>
              No
            </Button>
            <Button variant='primary' onClick={handleStatusChange} disabled={isLoading}>
              {isLoading ? <Spinner size='sm' /> : 'Yes'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
