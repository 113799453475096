import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'bootstrap/dist/css/bootstrap.min.css'
import PageHeader from 'components/common/PageHeader'
import Card from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { Button } from 'react-bootstrap'
import UserAddEdit from './AddEditUser'
import { getAllUsers, getAllUsersList } from '../../../redux/users/action'
import { useDispatch, useSelector } from 'react-redux'
import { getRandomColor } from 'utils/constants'
import { Spinner, Form, Col } from 'react-bootstrap'
import { FiEdit3 } from 'react-icons/fi'
import { MdDeleteOutline } from 'react-icons/md'
import UserModal from './UserModal'
import { StatusFirstModal } from './StatusModal'

// Custom formatter for the status column

// Custom formatter for the status column
const Logo = (cell, row, rowIndex) => {
  return (
    <div className='d-flex align-items-center gap-2'>
      <div
        className='rounded-circle  d-flex justify-content-center align-items-center '
        style={{
          width: '30px',
          height: '30px',
          backgroundColor: getRandomColor(),
          color: '#fff'
        }}
        alt='Avatar'
      >
        <span style={{ textTransform: 'capitalize' }}>{cell.charAt(0)}</span>
      </div>
    </div>
  )
}

const Users = () => {
  const dispatch = useDispatch()
  const [useListArr, setUserListArr] = useState([])
  const { usersList, isUserList } = useSelector(state => state.users)
  const [open, setOpen] = useState(false)
  const [userDetail, setUserDetail] = useState(null)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false)
  const [userDeleteDetail, setUserDeleteDetail] = useState(null)
  const [statusVal, setStatusVal] = useState(0)
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = async (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (value) {
      dispatch(getAllUsers({ type: value }))

    } else {
      dispatch(getAllUsers())

    }
  };


  const handleOpen = () => {
    setOpen(!open)
  }

  const handleUpdate = row => {
    setUserDetail(row)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(!open)
    setUserDetail(null)
  }
  useEffect(() => {
    setUserListArr(usersList)
  }, [usersList])
  // Define static columns

  const handleDeleteUser = row => {
    setIsOpenDeleteModal(true)
    setUserDeleteDetail(row)
  }

  const handleCloseModal = () => {
    setIsOpenDeleteModal(false)
    setUserDeleteDetail(null)
  }

  const handleStatusModal = (status, row) => {
    setIsOpenStatusModal(true)
    setUserDeleteDetail(row)
    setStatusVal(status == true ? 1 : 0)
  }

  const handleCloseStatusModal = () => {
    setIsOpenStatusModal(false)
    setUserDetail(null)
    setStatusVal(0)
  }

  const Edit = (cell, row, rowIndex) => {
    return (
      <div className='d-flex align-items-center gap-3 '>
        <FiEdit3 onClick={() => handleUpdate(row)} size={20} className='cursor-pointer' />
        <MdDeleteOutline
          onClick={() => handleDeleteUser(row)}
          size={21}
          className='cursor-pointer'
          style={{ color: '#e63757' }}
        />
      </div>
    )
  }

  const statusFormatter = (cell, row, rowIndex) => {
    return (
      <div className='form-check form-switch'>
        <input
          className='form-check-input'
          type='checkbox'
          id={`toggleSwitch${rowIndex}`}
          checked={cell}
          onChange={event => {
            handleStatusModal(event.target.checked, row)
          }}
        />
        <label className='form-check-label' htmlFor={`toggleSwitch${rowIndex}`}>
          {cell ? 'Active' : 'Inactive'}
        </label>
      </div>
    )
  }
  const userType = (cell, row, rowIndex) => {
    return (

      <label className='form-check-label' htmlFor={`toggleSwitch${rowIndex}`}>
        {cell == 2 ? 'Developer' : 'Sales'}
      </label>
    )
  }
  // Define static data
  const columns = [
    {
      dataField: 'firstName',
      text: '',
      headerStyle: { width: '100px' },
      style: { width: '100px' },
      sort: true,
      formatter: Logo
    },
    { dataField: 'name', text: 'Name', sort: true, headerStyle: { width: '140px' } },
    { dataField: 'firstName', text: 'First Name', sort: true, headerStyle: { width: '120px' } },
    { dataField: 'lastName', text: 'Last Name', sort: true, headerStyle: { width: '100px' } },
    { dataField: 'email', text: 'Email', sort: true, headerStyle: { width: '200px' } },
    { dataField: 'number', text: 'Number', sort: true, headerStyle: { width: '130px' } },
    { dataField: 'assignProjects', text: 'Assigned Projects', sort: true, headerStyle: { width: '130px' } },
    { dataField: 'userType', text: 'Type', sort: true, formatter: userType, headerStyle: { width: '130px' } },
    { dataField: 'status', text: 'Status', sort: true, formatter: statusFormatter, headerStyle: { width: '200px' } },
    { dataField: 'edit', text: '', formatter: Edit, headerStyle: { width: '100px' } }
  ]

  const pagination = paginationFactory({
    sizePerPage: 10, // Number of rows per page
    nextPageText: 'Next',
    prePageText: 'Previous',
    withFirstAndLast: true,
    hidePageListOnlyOnePage: true
  })

  const totalRecords = useListArr.length
  const sizePerPage = 10
  const totalPages = Math.ceil(totalRecords / sizePerPage)

  useEffect(() => {
    dispatch(getAllUsers())
    dispatch(getAllUsersList())
  }, [])
  return (
    <div>

      <>
        <PageHeader title='Users List' className='mb-3 '></PageHeader>

        <Card className='p-2 ' style={{ minHeight: '400px' }}>

          <div className='d-flex justify-content-end'>
            <div className='w-20 mx-3 ' >
              <Form.Group value={selectedOption} onChange={handleSelectChange} controlId='formGroupPipeline'>
                <Form.Select>
                  <option value=''>All</option>

                  <option key={1} value={2}>
                    Developers
                  </option>
                  <option key={2} value={4}>
                    Sales
                  </option>

                </Form.Select>
              </Form.Group>
            </div>
            <Button onClick={handleOpen}>Add User</Button>
          </div>

          {isUserList ? (
            <>
              <div className='p-2 mt-2  d-flex justify-content-center align-items-center' style={{ height: '400px' }}>
                <Spinner animation='border' size='lg' />
              </div>
            </>
          ) : (
            <div className='table-responsive' style={{ overflowX: 'auto', maxWidth: '100%' }}>
              <BootstrapTable
                keyField='id'
                data={useListArr ?? []}
                columns={columns}
                pagination={totalPages > 1 ? pagination : null}
                bordered={false}
                bootstrap5
              />
            </div>
          )}
        </Card>
        <UserAddEdit selectedFilter={selectedOption}
          open={open} userDetail={userDetail} handleClose={handleClose} />
      </>


      <UserModal
        selectedOption={selectedOption}
        isOpenDeleteModal={isOpenDeleteModal}
        userDeleteDetail={userDeleteDetail}
        handleCloseModal={handleCloseModal}
      />
      <StatusFirstModal
        isOpenStatusModal={isOpenStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        userDeleteDetail={userDeleteDetail}
        statusVal={statusVal}
      />
    </div>
  )
}

export default Users
