import React, { useEffect, useState } from 'react';
import KanbanContainer from './KanbanContainer';
import KanbanProvider from 'providers/KanbanProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjectsSettings } from '../../../redux/projects/action'

const Kanban = () => {
  const dispatch = useDispatch()
  const { userDetails } = useSelector((state) => state.login)
  const { savedPipleline } = useSelector(state => state.calenders)

  useEffect(() => {
    if (userDetails && savedPipleline) {
      dispatch(getAllProjectsSettings({ pipelineId: savedPipleline?.pipelineId }))

    }
  }, [userDetails, savedPipleline])
  return (
    <>
      <KanbanProvider>
        <KanbanContainer />
      </KanbanProvider>
    </>
  );
};

export default Kanban;
