import React from 'react'
import PropTypes from 'prop-types'
import Avatar, { AvatarGroup } from './Avatar'
import { Dropdown, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const GroupMember = ({ avatarSize = 'l', users, showMember = 2, className }) => {
  return (
    <div className={className}>
      <AvatarGroup>
        {users?.slice(0, showMember)?.map((user, index) => (
          <Dropdown key={index}>
            <Dropdown.Toggle
              as={Nav.Link}
              className={classNames('dropdown-caret-none p-0', {
                'ms-n1': index > 0
              })}
            >
              <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={<Tooltip>{user.name}</Tooltip>}>
                <div>
                  <Avatar name={user.name} size={avatarSize} />
                </div>
              </OverlayTrigger>
            </Dropdown.Toggle>
          </Dropdown>
        ))}
        {users?.length > showMember && (
          <Dropdown>
            <Dropdown.Toggle as={Nav.Link} className='dropdown-caret-none p-0 ms-n1'>
              <Avatar name={`${users?.length - showMember}+`} size={avatarSize} isExact mediaClass='avatar-button' />
            </Dropdown.Toggle>

            <Dropdown.Menu className='dropdown-md dropdown-open'>
              <h6 className=' py-0 px-2 mb-0'>{'Project Members'}</h6>
              <Dropdown.Divider />

              <div className='px-2 d-flex gap-2'>
                {users.map(user => (
                  <Link className='text-900' key={user.id}>
                    <div className='position-relative'>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip style={{ position: 'fixed' }} id={`member-${user.id}`}>
                            {user.name}
                          </Tooltip>
                        }
                      >
                        <div className=''>
                          <Avatar name={user.name} size={avatarSize} />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </Link>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </AvatarGroup>
    </div>
  )
}

export default GroupMember
