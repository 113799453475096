import FalconCardHeader from 'components/common/FalconCardHeader'
import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { LuEyeOff, LuEye } from 'react-icons/lu'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { apis } from 'utils/Apis'
import Spinner from 'react-bootstrap/Spinner'
import { put } from 'utils/constants'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { userDetails } = useSelector(state => state.login)

  // const handleChange = e => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   })
  // }

  const handletogglePassword = id => {
    if (id === 1) {
      setShowOldPassword(!showOldPassword)
    } else if (id === 2) {
      setShowNewPassword(!showNewPassword)
    } else {
      setShowConfirmNewPassword(!showConfirmNewPassword)
    }
  }

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().test('is-required', 'Please enter a valid password', function (value) {
        // Access userDetail from context
        const { userDetail } = this.parent
        // Check if isPasswordSet is 1 and oldPassword is not empty
        if (userDetail?.isPasswordSet === 1 && !value) {
          return false
        }
        return true
      }),
      newPassword: Yup.string()
        .matches(/[A-Za-z]/, 'Password must contain at least one letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[0-9]/, 'Password must contain at least one digit')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .test('no-spaces', 'Password must not contain spaces', value => typeof value === 'string' && !/\s/.test(value))
        .required('Please enter a valid password')
        .min(8, 'Password must be at least 8 characters')
        .max(128, 'Password must not exceed 128 characters'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Please ensure password and re-enter password fields should be the same')
        .required('Please enter a valid confirm password')
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true)
      let params = {
        new_password: values.newPassword
      }
      if (userDetails?.isPasswordSet === 1) {
        params.type = 1
        params.old_password = values.oldPassword
      } else {
        params.type = 2
        params.googleId = userDetails.googleId
      }
      const response = await put(apis.user_change_password, params)
      if (response?.data?.status_code == 200 || response?.data?.status_code == 201) {
        formik.resetForm()
        setIsLoading(false)
        toast.success(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      } else {
        setIsLoading(false)
        toast.error(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
        formik.resetForm()
      }
      formik.resetForm()
    }
  })

  return (
    <Card className='mb-3'>
      <FalconCardHeader title='Change Password' />
      <Card.Body className='bg-body-tertiary'>
        <Form noValidate onSubmit={formik.handleSubmit}>
          {userDetails?.isPasswordSet !== 1 && (
            <p className='text-center fs-10 text-danger'>
              &#x24BE; Your password is not set with this email. Please set it.{' '}
            </p>
          )}
          {userDetails?.isPasswordSet === 1 && (
            <Form.Group className='mb-3 position-relative' controlId='oldPassword'>
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type={showOldPassword ? 'text' : 'password'}
                value={formik.values.oldPassword}
                name='oldPassword'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.oldPassword && formik.errors.oldPassword}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '52%',
                  right: '10px',
                  cursor: 'pointer'
                }}
              >
                {formik.touched.oldPassword && formik.errors.oldPassword ? null : showOldPassword ? (
                  <LuEye
                    onClick={() => handletogglePassword(1)}
                    style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
                  />
                ) : (
                  <LuEyeOff
                    onClick={() => handletogglePassword(1)}
                    style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
                  />
                )}
              </div>
              <Form.Control.Feedback type='invalid'>{formik.errors.oldPassword}</Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group className='mb-3 position-relative' controlId='newPassword'>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type={showNewPassword ? 'text' : 'password'}
              value={formik.values.newPassword}
              name='newPassword'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.newPassword && formik.errors.newPassword}
            />
            <div style={{ position: 'absolute', top: '52%', right: '10px' }}>
              {formik.touched.newPassword && formik.errors.newPassword ? null : showNewPassword ? (
                <LuEye
                  onClick={() => handletogglePassword(2)}
                  style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
                />
              ) : (
                <LuEyeOff
                  onClick={() => handletogglePassword(2)}
                  style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
                />
              )}
            </div>
            <Form.Control.Feedback type='invalid'>{formik.errors.newPassword}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3 position-relative' controlId='confirmPassword'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type={showConfirmNewPassword ? 'text' : 'password'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='confirmPassword'
              value={formik.values.confirmPassword}
              isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />
            <div style={{ position: 'absolute', top: '52%', right: '10px' }}>
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? null : showConfirmNewPassword ? (
                <LuEye
                  onClick={() => handletogglePassword(3)}
                  style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
                />
              ) : (
                <LuEyeOff
                  onClick={() => handletogglePassword(3)}
                  style={{ cursor: 'pointer', fontSize: '18px', color: '#9da9bb' }}
                />
              )}
            </div>
            <Form.Control.Feedback type='invalid'>{formik.errors.confirmPassword}</Form.Control.Feedback>
          </Form.Group>
          <Button className='w-100' type='submit' disabled={isLoading}>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Update Password'}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default ChangePassword
