import React, { useEffect, useState, useRef } from 'react'

import SimpleBarReact from 'simplebar-react'
import { useSelector, useDispatch } from 'react-redux'
import { Image, Tooltip, OverlayTrigger } from 'react-bootstrap'
import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import classNames from 'classnames'
import FalconLightBoxGallery from 'components/app/chat/ChatLightBox'
import dayjs from 'dayjs'
import { IoMdDownload } from 'react-icons/io'
import axios from 'axios'
import { getOlderGroupMessages } from '../../../../redux/groupChat/action'
import { Decrypt, formatFileSize } from 'utils/constants'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import MessageIcon from '../../../../assets/img/NoMessages.svg'
import DocumentLightBox from 'components/app/projects/devProjects/DocumentLightBox'



const ChatContentBody = React.memo(({ activeTab }) => {
  const dispatch = useDispatch()
  const { sendThreadMessage } = useSelector(state => state.smsEmail)
  const { groupMessages, noMoreOlderMessages, isGroupMessages } = useSelector(state => state.groupChat)
  const [messagesArr, setMessagesArr] = useState([])
  const { userDetails } = useSelector(state => state.login)
  const [page, setPage] = useState(2)
  const messagesEndRef = useRef()
  const [isOpenLightBox, setIsOpenLightBox] = useState(false)
  const [documentImage, setDocumentImage] = useState('')

  const [windowHeight, setWindowHeight] = useState(
    sendThreadMessage?.isOpen ? window.innerHeight - 360 : window.innerHeight - 215
  )
  let { id } = useParams()
  let decryptedId = Decrypt(id)

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(sendThreadMessage?.isOpen ? window.innerHeight - 360 : window.innerHeight - 215)
    }

    handleWindowResize() // Call it once to set the initial height

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [sendThreadMessage?.isOpen])

  useEffect(() => {
    const handleBeforeUnload = () => {
      setMessagesArr([])
      dispatch({ type: 'REMOVE_ALL_GROUP_CHAT' })
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }, [])

  useEffect(() => {
    setMessagesArr([])

    if (groupMessages?.length > 0 && activeTab === 'groupChat') {
      setMessagesArr([...groupMessages] ?? [])
    } else {
      setMessagesArr([])
      setPage(2)
    }
  }, [groupMessages, activeTab])

  const downloadFile = async (url, fileName = 'mtc-files') => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob' // Important
      })

      // Create a blob from the response
      const blob = new Blob([response.data], { type: response.headers['content-type'] })

      // Create a link element to trigger the download
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = fileName

      // Trigger the download
      downloadLink.click()
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const handleLoadMore = () => {
    if (groupMessages?.length > 0) {
      dispatch(getOlderGroupMessages({ project_id: decryptedId, limit: 20, page: page, type: 'team' }))
      setPage(page + 1)
    }
  }


  const openDialog = url => {
    setDocumentImage(url)
    setIsOpenLightBox(true)
  }

  const closeDialog = url => {
    setDocumentImage('')
    setIsOpenLightBox(false)
  }


  return (
    <div
      className='chat-content-body'
      style={{
        display: 'inherit',
        height: `${windowHeight}px`
      }}
    >
      {!isGroupMessages && groupMessages.length <= 0 ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src={MessageIcon} alt='dmd' />
          <h5 style={{ fontSize: '15px', fontWeight: '600', color: '#000' }}>Let's Start a Conversation</h5>
          <p style={{ textAlign: 'center', color: 'rgb(153, 153, 153)' }}>
            Transform your plans into progress. Collaborate with our <br /> developers  and elevate your trading experience. <br />Start your conversation now!

          </p>
        </div>
      ) : (
        <SimpleBarReact style={{ height: '100%' }}>
          {!noMoreOlderMessages && (
            <div onClick={handleLoadMore} className='text-center fs-9 text-800 fw-semibold mt-2 cursor-pointer'>
              <u>Load More</u>
            </div>
          )}
          <div className='chat-content-scroll-area'>
            {messagesArr?.map((item, index) => {
              let isLeft = item?.user_id !== userDetails?._id
              return (
                <div key={index}>
                  <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
                    {isLeft && (
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id='button-tooltip'>{item?.userName}</Tooltip>}
                      >
                        <div>
                          <Avatar size='l' className='me-2' name={item?.userName} />
                        </div>
                      </OverlayTrigger>
                    )}
                    <div
                      className={classNames('flex-1', {
                        'd-flex justify-content-end': !isLeft
                      })}
                    >
                      <div
                        className={classNames('w-xxl-75', {
                          'w-100': !isLeft
                        })}
                      >
                        <Flex
                          alignItems='center'
                          className={classNames('hover-actions-trigger', {
                            'flex-end-center': !isLeft,
                            'align-items-center': isLeft
                          })}
                        >
                          {item.media?.length > 0 ? (
                            <div
                              style={{
                                backgroundColor: isLeft ? '#F6F3FB' : '#C7A9F1',
                                border: isLeft ? '1px solid rgb(221 211 238)' : '1px solid #C7A9F1'
                              }}
                              className={classNames('p-2 rounded-3 chat-message', {
                                ' text-white': !isLeft
                              })}
                            >
                              {item.message && (
                                <p
                                  className='mb-0'
                                  dangerouslySetInnerHTML={{
                                    __html: item.message
                                  }}
                                />
                              )}
                              <div className='d-flex gap-2 flex-wrap'>
                                {item?.media.map((obj, index) => {
                                  const isImage = ['.png', '.jpg', '.png', 'mpeg', 'jpeg'].some(ext =>
                                    obj?.url?.endsWith(ext)
                                  )
                                  const isVideo = ['mp4', 'mov', '.gif', '.avi', 'm4p'].some(ext =>
                                    obj?.url?.endsWith(ext)
                                  )

                                  return (
                                    <>
                                      {isImage ? (
                                        <div>
                                          <Image
                                            bsPrefix
                                            rounded
                                            width={width < 500 ? 180 : 300}
                                            height={width < 500 ? 100 : 200}
                                            className='mb-2 cursor-pointer'
                                            src={obj?.url}
                                            alt=''
                                            onClick={() => openDialog(obj?.url)}
                                          />

                                        </div>
                                      ) : isVideo ?
                                        <div onClick={() => openDialog(obj?.url)} className="embed-responsive embed-responsive-4by3">
                                          <iframe onClick={() => openDialog(obj?.url)} allow=" fullscreen; encrypted-media; picture-in-picture"
                                            className="embed-responsive-item" src={obj?.url}></iframe>
                                        </div>
                                        : (
                                          <div
                                            onClick={() => downloadFile(obj?.url)}
                                            style={{ background: '#C7A9F1' }}
                                            className={classNames(
                                              'p-2 rounded-2 d-flex align-items-center gap-1 justify-content-between cursor-pointer',
                                              {
                                                'text-white ': isLeft,
                                                'bg-200 text-dark': !isLeft
                                              }
                                            )}
                                          >
                                            <div className='d-flex flex-column'>
                                              <div className='d-flex align-items-center gap-2 '>
                                                {obj?.name}
                                                <IoMdDownload
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() => downloadFile(obj?.url)}
                                                  size={16}
                                                />
                                              </div>
                                              <p className='fs-11 fw-bolder'>{formatFileSize(obj?.size)}</p>
                                            </div>

                                          </div>
                                        )}
                                    </>
                                  )
                                })}
                              </div>

                            </div>
                          ) : (
                            <>
                              <div
                                style={{
                                  backgroundColor: isLeft ? '#F6F3FB' : '#C7A9F1',
                                  border: isLeft ? '1px solid rgb(221 211 238)' : '1px solid #C7A9F1'
                                }}
                                className={classNames('p-2 rounded-3 chat-message', {
                                  ' text-white': !isLeft
                                })}
                              >
                                {(item || item.message) && (
                                  <p
                                    className='mb-0'
                                    dangerouslySetInnerHTML={{
                                      __html: item.message
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </Flex>
                        <div
                          className={classNames('text-400 fs-11', {
                            'text-end': !isLeft
                          })}
                        >
                          <div className='d-flex flex-column'>{dayjs(item.createdAt).format('YYYY-MM-DD hh:mm a')}</div>
                        </div>
                      </div>
                    </div>
                  </Flex>
                </div>
              )
            })}
          </div>
          <div ref={messagesEndRef} />
        </SimpleBarReact>
      )}
      <DocumentLightBox isOpenLightBox={isOpenLightBox} toggleHandler={closeDialog} documentImage={documentImage} />

    </div>
  )
})

export default ChatContentBody
