import React, { useState, useEffect } from 'react'
import { Button, Form, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  addProjectsAssets,
  getProjectSettingsDetail,
  deleteProjectsAssets,
  editProjectsAssets
} from '../../../../redux/projects/action'
import { getAllUsers } from '../../../../redux/users/action'

import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import Placeholder from 'react-bootstrap/Placeholder'
import EmptyImg from 'assets/dashboard/empty.svg'
import dayjs from 'dayjs'
import Avatar from '../Avatar'
import { FaRegEdit } from 'react-icons/fa'
import { MdOutlineDeleteOutline } from 'react-icons/md'
const Notes = () => {
  const { userDetails } = useSelector(state => state.login)
  const { PorjectSettings, isProjectSettings } = useSelector(state => state.projects)
  const { jobDetail } = useSelector(state => state.users)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const { usersList } = useSelector(state => state.users)
  const [openEditor, setOpenEditor] = useState(false)
  const [noteVal, setNoteVal] = useState('')
  const [title, setTitle] = useState('')
  const [loader, setLoader] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch()
  const [dataforUpdate, setDataForUpdate] = useState(null)
  useEffect(() => {
    dispatch(getAllUsers())
  }, [])
  const handleOpenEditor = () => {
    setOpenEditor(!openEditor)
  }
  const handleCloseEditor = () => {
    setOpenEditor(!openEditor)
    setDataForUpdate(null)
    setTitle('')
    setNoteVal('')
    setIsEdit(false)
  }

  const handleChange = val => {
    setNoteVal(val)
  }

  const handleEdit = noteItem => {
    setIsEdit(true)
    setOpenEditor(true)
    setDataForUpdate(noteItem)
    setTitle(noteItem?.title)
    setNoteVal(noteItem?.description)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoader(true)
    let params = {
      id: isEdit ? dataforUpdate?.id : uuidv4(),
      projectId: jobDetail?.id,
      assetType: 1,
      title: title,
      description: noteVal,
      created_by: userDetails?._id,
      date: dayjs().valueOf(),
      source: 'conversion'
    }
    try {
      let res = null
      if (isEdit) {
        res = await editProjectsAssets(params)
      } else {
        res = await addProjectsAssets(params)
      }
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        setLoader(false)
        dispatch(getProjectSettingsDetail({ projectId: jobDetail?.id }))
        setNoteVal('')
        setTitle('')
        setIsEdit(false)
        setOpenEditor(false)
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
      } else {
        setLoader(false)
      }
    } catch (err) {
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-right'
      })
      setLoader(false)
    }
  }

  const handleDelete = async id => {
    let params = {
      id: id,
      projectId: jobDetail?.id,
      assetType: 1
    }
    try {
      setDeleteLoader(true)
      let res = await deleteProjectsAssets(params)
      if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
        dispatch(getProjectSettingsDetail({ projectId: jobDetail?.id }))
        toast.success(res?.data?.message, {
          theme: 'colored',
          position: 'top-right'
        })
        setDeleteLoader(false)
      }
    } catch (err) {
      setDeleteLoader(false)

      toast.error(err?.data?.message, {
        theme: 'colored',
        position: 'top-right'
      })
    }
  }

  return (
    <div className=''>
      <div className='d-flex justify-content-end w-100'>
        {!openEditor ? (
          <Button onClick={handleOpenEditor}>{'Add Note'}</Button>
        ) : (
          <Button onClick={handleCloseEditor}>{'Close Editor'}</Button>
        )}
      </div>
      <div>
        {openEditor && (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Title</Form.Label>
              <Form.Control value={title} placeholder='Title...' name='note' onChange={e => setTitle(e.target.value)} />
              <Form.Label>Type a note</Form.Label>
              <Form.Control
                value={noteVal}
                placeholder='Note...'
                name='note'
                onChange={e => handleChange(e.target.value)}
                as='textarea'
                rows={3}
              />
            </Form.Group>

            <div className='d-flex justify-content-end w-100 mt-2'>
              <Button disabled={loader || !noteVal} type='submit'>
                {loader ? <Spinner size='sm' /> : 'Submit'}
              </Button>
            </div>
          </Form>
        )}
        <div>
          <p className='fw-bolder border-top mt-1 fs-8 pt-3'>Notes List</p>

          <div className='d-flex flex-column gap-2'>
            {!isProjectSettings ? (
              <>
                {PorjectSettings?.notes && PorjectSettings?.notes?.length > 0 ? (
                  PorjectSettings?.notes?.map((item, index) => {
                    let user = usersList?.find(user => user?._id === item?.created_by)
                    return (
                      <div
                        key={index}
                        style={{
                          background: '#EFF7FF',
                          padding: '10px 20px',
                          borderRadius: '10px',
                          border: '1px solid rgb(197 218 239) ',
                          wordBreak: 'break-all'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px'
                          }}
                        >
                          <p className='fw-bolder' style={{ marginBottom: '0px' }}>
                            {item?.title}
                          </p>
                          {item?.created_by === userDetails?._id && (
                            <div
                              style={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center'
                              }}
                            >
                              <FaRegEdit onClick={() => handleEdit(item)} style={{ cursor: 'pointer' }} />

                              <MdOutlineDeleteOutline
                                onClick={() => handleDelete(item?.id)}
                                style={{ fontSize: '19px', cursor: 'pointer', color: '#f44336' }}
                                disabled={deleteLoader}
                              />
                            </div>
                          )}
                        </div>
                        <div className='d-flex gap-2'>
                          <div style={{ width: '60px' }}>
                            <OverlayTrigger placement='top' overlay={<Tooltip>{user?.name ?? 'Admin'}</Tooltip>}>
                              <div>
                                <Avatar name={user?.name ?? 'A'} />
                              </div>
                            </OverlayTrigger>
                          </div>

                          {item?.description}
                        </div>
                        <div className='text-end'>
                          <p className='mb-0  fs-10'>{dayjs.unix(item?.date / 1000).format('YYYY-MM-DD HH:mm')} </p>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div style={{ width: '100%' }}>
                    <div
                      style={{
                        height: '300px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <img src={EmptyImg} alt='empty' />
                      <p className='fw-bolder mt-2 mb-1'>No Available Notes</p>
                      <p className='fs-10 mt-2 mb-1'>Currently here is no notes for display</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Placeholder animation='glow' className='d-flex flex-column gap-2'>
                <Placeholder style={{ width: '100%', height: '100px', borderRadius: '10px' }} />
                <Placeholder style={{ width: '100%', height: '100px', borderRadius: '10px' }} />
                <Placeholder style={{ width: '100%', height: '100px', borderRadius: '10px' }} />
              </Placeholder>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notes
