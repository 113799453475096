import React, { useState, useEffect } from 'react'
import { Modal, Table, Badge, Button, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { MdOutlineClose } from 'react-icons/md'
import { AdminBugModal } from './AdminBugModal'
import { AdminInfoModal } from './adminInfoModal'
import { useSelector, useDispatch } from 'react-redux'
import { getAllProjectBugList } from '../../../../redux/projects/action'
import dayjs from 'dayjs'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'

export const AdminClientModal = ({ show, handleClose, projectDetail, selectedPipeLine, task, setOpenClientModal }) => {
  const [openRaiseBug, setOpenRaiseBug] = useState(false)
  const [openInfoBug, setOpenInfoBug] = useState(false)
  const { isProjectBugsList, projectBugsList, pipelines } = useSelector(state => state.projects)
  const [page, setPage] = useState(1)
  const [projectBugArr, setProjectBugArr] = useState([])
  const [bugDetail, setBugDetail] = useState(null)
  const dispatch = useDispatch()
  const [nextClickDisable, setNextClickDisable] = useState(false)
  const [prevClickDisable, setPrevClickDisable] = useState(false)

  const handleCloseInfoBug = () => {
    setPage(1)
    setBugDetail(null)
    setOpenInfoBug(false)
    setOpenClientModal(true)
  }

  const handleOpenInfoBug = data => {
    setOpenInfoBug(true)
    setBugDetail(data)
    handleClose()
  }

  const handleCloseRaiseBug = () => {
    setPage(1)
    setOpenRaiseBug(false)
    setOpenClientModal(true)
  }

  const handleOpenRaiseBug = () => {
    setOpenRaiseBug(true)
    handleClose()
  }

  const handleCloseModal = () => {
    setPage(1)
    handleClose()
  }

  useEffect(() => {
    if (show) {
      const params = {
        projectId: projectDetail?.projectId ? projectDetail?.projectId : projectDetail?.id,
        page: page,
        limit: 10
      }
      dispatch(getAllProjectBugList(params))
    }
  }, [show])

  useEffect(() => {
    if (projectBugsList) {
      setProjectBugArr(projectBugsList)
    }
  }, [projectBugsList])

  const getClickLogsOnPageChange = async exclusiveBackKeyObj => {
    dispatch(getAllProjectBugList(exclusiveBackKeyObj))
  }

  useEffect(() => {
    if (projectBugArr?.length > 0 && projectBugArr?.length >= 10 && !isProjectBugsList) {
      setNextClickDisable(true)
    } else {
      setNextClickDisable(false)
    }
  }, [projectBugArr, isProjectBugsList])

  useEffect(() => {
    if (page > 1 && !isProjectBugsList) {
      setPrevClickDisable(true)
    } else {
      setPrevClickDisable(false)
    }
  }, [page, isProjectBugsList])

  const newDecreasePage = () => {
    const backpage = page - 1
    if (page - 1 >= 1) {
      const exclusiveBackKeyObj = {
        page: backpage,
        limit: 10,
        projectId: projectDetail?.projectId ? projectDetail?.projectId : projectDetail?.id
      }
      setPage(page - 1)
      getClickLogsOnPageChange(exclusiveBackKeyObj)
    } else {
      const exclusiveBackKeyObj = {
        backpage: 1,
        limit: 10,
        projectId: projectDetail?.projectId ? projectDetail?.projectId : projectDetail?.id
      }
      setPage(1)
      getClickLogsOnPageChange(exclusiveBackKeyObj)
    }
  }

  const newIncreasePage = () => {
    if (projectBugArr?.length > 0) {
      const nextpage = page + 1
      const exclusiveBackKeyObj = {
        page: nextpage,
        limit: 10,
        projectId: projectDetail?.projectId ? projectDetail?.projectId : projectDetail?.id
      }
      setPage(prev => prev + 1)
      getClickLogsOnPageChange(exclusiveBackKeyObj)
    }
  }

  const CustomPagination = () => {
    return (
      <div
        className='fixed-footer'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          padding: '0px 20px 10px 20px',
          backgroundColor: '#FFF'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <p style={{ fontSize: '15px', fontWeight: '600' }}>Page :</p>
          <p style={{ fontSize: '15px', fontWeight: '600' }}>{`${page}`}</p>

          <p style={{ fontSize: '15px', fontWeight: '600' }}>Rows Per Page :</p>
          <p style={{ fontSize: '15px', fontWeight: '600' }}>{projectBugArr?.length}</p>
        </div>
        <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
          <OverlayTrigger placement={'top'} overlay={<Tooltip>Previous</Tooltip>}>
            <div>
              <IoIosArrowBack
                style={{
                  color: prevClickDisable ? '#757575' : '#d3d3d3',
                  cursor: 'pointer',
                  fontSize: '18px'
                }}
                onClick={prevClickDisable ? newDecreasePage : () => { }}
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement={'top'} overlay={<Tooltip>Next</Tooltip>}>
            <div>
              <IoIosArrowForward
                style={{
                  color: nextClickDisable ? '#757575' : '#d3d3d3',
                  cursor: 'pointer',
                  fontSize: '18px'
                }}
                onClick={nextClickDisable ? newIncreasePage : () => { }}
              />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    )
  }

  return (
    <>
      <Modal show={show} onHide={handleCloseModal} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <div
          className='py-2 px-3'
          style={{
            background:
              'rgb(236,246,255) linear-gradient(180deg, rgba(236,246,255,1) 0%, rgba(248,248,255,1) 25%, rgba(237,235,255,1) 100%, rgba(239,239,246,1) 75%, rgba(249,249,249,1) 100%)',
            borderRadius: '10px',
            border: 'none',
            // height: `${height - 70}px`,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          <div className='d-flex justify-content-between w-100 pb-2 border-bottom'>
            <p style={{ color: '#000', fontWeight: '600' }}>Bugs List</p>
            <MdOutlineClose onClick={handleCloseModal} size={25} style={{ cursor: 'pointer' }} />
          </div>
          <div
            className='table-responsive'
            style={{
              borderRadius: '10px',
              maxHeight: '400px',
              overflow: isProjectBugsList || projectBugArr.length === 0 ? 'hidden' : 'auto',
              position: 'relative',
              backgroundColor: '#FFF',
              marginBottom: '10px'
            }}
          >
            <Table
              striped
              bordered
              hover
              style={{
                backgroundColor: '#FFF',
                marginBottom: '7px',
                maxHeight: '400px'
              }}
            >
              <thead className='fixed-header'>
                <tr>
                  <th style={{ color: '#FFF', fontSize: '14px' }}>Title</th>
                  <th style={{ color: '#FFF', fontSize: '14px' }}>Created By</th>
                  <th style={{ color: '#FFF', fontSize: '14px' }}>Date Add on</th>
                  <th style={{ color: '#FFF', fontSize: '14px' }}>Closed on</th>
                  <th style={{ color: '#FFF', fontSize: '14px' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {isProjectBugsList ? (
                  <tr>
                    <td
                      colSpan='5'
                      style={{
                        textAlign: 'center',
                        padding: '50px'
                      }}
                    >
                      <Spinner />
                    </td>
                  </tr>
                ) : !isProjectBugsList && projectBugArr?.length > 0 ? (
                  projectBugArr.map((item, index) => {
                    return (
                      <>
                        <tr onClick={() => handleOpenInfoBug(item)} style={{ cursor: 'pointer' }} key={index}>
                          <td style={{ color: '#000', fontSize: '14px', maxWidth: '200px', wordBreak: 'break-all' }}>{item?.title}</td>
                          <td style={{ color: '#000', fontSize: '14px' }}>{item?.createByName}</td>
                          <td style={{ color: '#000', fontSize: '14px' }}>
                            {dayjs(item?.createAt).format('DD-MM-YYYY')}
                          </td>
                          <td style={{ color: '#000', fontSize: '14px' }}>
                            {' '}
                            {item?.closedDate ? dayjs(item?.closedDate).format('DD-MM-YYYY') : ''}
                          </td>
                          <td style={{ color: '#000' }}>
                            <Badge
                              bg={
                                item?.status == 1
                                  ? 'info'
                                  : item?.status == 2
                                    ? 'warning'
                                    : item?.status == 3
                                      ? 'success'
                                      : ''
                              }
                              style={{ borderRadius: '50px' }}
                            >
                              {item?.status == 1 ? 'Bug Raised' : item?.status == 2 ? 'In-progress' : 'Bug Fixed'}
                            </Badge>
                          </td>
                        </tr>
                      </>
                    )
                  })
                ) : (
                  <tr>
                    <td
                      colSpan='5'
                      style={{
                        textAlign: 'center',
                        padding: '50px'
                      }}
                    >
                      <p style={{ color: '#000', fontWeight: '600' }}>No Bug Found !!</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {CustomPagination()}
          </div>
          {task?.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 1]?.id ||
            task?.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 2]?.id ? (
            <div className='d-flex justify-content-end w-100 pb-2 '>
              <Button style={{ borderRadius: '50px' }} onClick={handleOpenRaiseBug}>
                Raise a Bug
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </Modal>

      <AdminInfoModal
        openInfoBug={openInfoBug}
        handleCloseInfoBug={handleCloseInfoBug}
        projectDetail={projectDetail}
        bugDetail={bugDetail}
      />

      <AdminBugModal
        openRaiseBug={openRaiseBug}
        handleCloseRaiseBug={handleCloseRaiseBug}
        projectDetail={projectDetail}
      />
    </>
  )
}
