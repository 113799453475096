import React, { useState } from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { MdOutlineClose } from 'react-icons/md'
import { useFormik } from 'formik'
import cloudUpload from 'assets/img/icons/cloud-upload.svg'
import { renderDocsView } from 'utils/constants'
import Flex from 'components/common/Flex'
import { IoMdClose } from 'react-icons/io'
import { toast } from 'react-toastify'
import {
  UplaodFile,
  handleAddProjectBugs,
  getAllProjectsSettings,
  getAllProjectBugList
} from '../../../../redux/projects/action'
import { useDropzone } from 'react-dropzone'
import { updateOpportunities } from '../../../../redux/projects/action'
import { useSelector, useDispatch } from 'react-redux'
import { FaArrowLeftLong } from 'react-icons/fa6'

export const AdminBugModal = ({ openRaiseBug, handleCloseRaiseBug, projectDetail }) => {
  const [ImgLoading, setImgLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [bugIndexState, setBugIndexState] = useState('')
  const [titleError, setTitleError] = useState([])
  const [descriptionError, setDescriptionError] = useState([])
  const [isSubmitLoader, setIsSubmitLoader] = useState(false)
  const { pipelines } = useSelector(state => state.projects)
  const { savedPipleline } = useSelector(state => state.calenders)
  let selectedPipeLine = pipelines?.find(item => item?.id == savedPipleline?.pipelineId)
  const dispatch = useDispatch()
  let bugIndex = null

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: async acceptedFiles => {
      // Determine the maximum file size based on file type.
      const maxFileSizeBytes =
        acceptedFiles[0].type.startsWith('application/') || acceptedFiles[0].type.startsWith('image/')
          ? 10 * 1024 * 1024
          : 0

      // Check if the file size exceeds the maximum limit and display a toast message if so.
      if (acceptedFiles[0].size > maxFileSizeBytes) {
        if (acceptedFiles[0].type.startsWith('application/') || acceptedFiles[0].type.startsWith('image/')) {
          return toast.error('File size exceeds the maximum limit (10MB)', {})
        }
      }

      // Set image loading state to true.
      setImgLoading(true)
      const formData = new FormData()
      formData.append('file', acceptedFiles[0])
      try {
        // Upload the file using the 'uploadInnerDocuments' API.
        const response = await UplaodFile(formData)
        if (response?.data?.status_code == 201 || response?.data?.status_code == 200) {
          setImgLoading(false)
          setBugIndexState('')
          setFiles([...files, response?.data?.data?.file])
          handleBugChange(bugIndex, 'documents', [
            ...formik.values.bugs[bugIndex].documents,
            {
              url: response?.data?.data?.file,
              name: acceptedFiles[0].name,
              size: acceptedFiles[0].size
            }
          ])

          toast.success(response?.data.message, {
            position: 'top-right',
            theme: 'colored'
          })
        } else {
          setImgLoading(false)
        }
        // Check the response and extract the image URL.
      } catch (error) {
        console.error('Error:', error)
        setImgLoading(false)
      }
    }
  })

  const handleRemoveFile = (rmDoc, documentIndex) => {
    const updatedFiles = [...formik.values.bugs[documentIndex].documents]
    let filteredDocs = updatedFiles?.filter(item => item?.url !== rmDoc?.url)

    const updatedBugs = [...formik.values.bugs]
    updatedBugs[documentIndex] = {
      ...updatedBugs[documentIndex],
      documents: filteredDocs
    }
    formik.setFieldValue('bugs', updatedBugs)
  }

  const fileList = documentIndex => {
    return formik.values.bugs[documentIndex].documents.map((file, index) => {
      return (
        <div key={index}>
          <div className='d-flex align-items-center flex-column'>
            <div className='d-inline-block position-relative'>
              {renderDocsView(file?.url)}
              <span
                className='position-absolute d-flex flex-column align-items-center justify-content-between '
                style={{ width: '20px', height: '20px', top: -10, borderRadius: '50%', right: -10, border: 'none' }}
              >
                <span className='cursor-pointer' onClick={() => handleRemoveFile(file, documentIndex)}>
                  <IoMdClose />
                </span>
              </span>
            </div>
            <p style={{ fontSize: '11px' }}>{file?.name}</p>
          </div>
        </div>
      )
    })
  }
  const formik = useFormik({
    initialValues: {
      bugs: [
        {
          description: '',
          title: '',
          documents: []
        }
      ]
    },
    onSubmit: async (values, { setSubmitting }) => {
      let titleError = []
      let descriptionError = []

      values.bugs.forEach((bug, index) => {
        if (bug.title == '') {
          titleError.push(`Title is Required`)
        } else {
          titleError.push('')
        }
      })
      values.bugs.forEach((bug, index) => {
        if (bug.description == '') {
          descriptionError.push(`Description is Required`)
        } else {
          descriptionError.push('')
        }
      })
      setTitleError(titleError)
      setDescriptionError(descriptionError)
      const titleRequiredError = titleError.some(error => error.includes('Title is Required'))
      const descriptionRequiredError = descriptionError.some(error => error.includes('Description is Required'))

      try {
        if (!titleRequiredError && !descriptionRequiredError) {
          let params = {
            projectId: projectDetail?.projectId ? projectDetail?.projectId : projectDetail?.id ?? '',
            bugs: formik.values.bugs
          }
          const opportunitesParams = {
            opportunities_id: projectDetail?.projectId,
            pipelineId: savedPipleline?.pipelineId,
            pipelineStageId: selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 1]?.id
          }
          const bugParams = {
            projectId: projectDetail?.projectId ? projectDetail?.projectId : projectDetail?.id,
            page: 1,
            limit: 10
          }
          setIsSubmitLoader(true)
          const response = await handleAddProjectBugs(params)
          if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
            dispatch(getAllProjectsSettings({ pipelineId: savedPipleline?.pipelineId }))
            dispatch(getAllProjectBugList(bugParams))

            await updateOpportunities(opportunitesParams)
            toast.success(response?.data.message, {
              position: 'top-right',
              theme: 'colored'
            })
          } else {
            toast.error(response?.data?.message, {
              position: 'top-right',
              theme: 'colored'
            })
          }
          setIsSubmitLoader(false)
          formik.resetForm()
          handleCloseModal()
        }
      } catch (error) {
        console.error('An error occurred:', error)
        toast.error('An error occurred. Please try again.', {
          position: 'top-right',
          theme: 'colored'
        })
        setIsSubmitLoader(false)
        formik.resetForm()
        handleCloseModal()
      }
    }
  })

  const handleBugChange = (index, fieldName, value) => {
    const newTitleError = [...titleError]
    const newDescriptionError = [...descriptionError]
    const updatedBugs = [...formik.values.bugs]
    updatedBugs[index] = {
      ...updatedBugs[index],
      [fieldName]: value
    }
    if (fieldName == 'title') {
      if (value) {
        if (updatedBugs[index].title != '') {
          newTitleError[index] = ''
        }
      } else {
        newTitleError[index] = 'Title is Required'
      }
    }
    if (fieldName == 'description') {
      if (value) {
        if (updatedBugs[index].description != '') {
          newDescriptionError[index] = ''
        }
      } else {
        newDescriptionError[index] = 'Description is Required'
      }
    }
    setTitleError(newTitleError)
    setDescriptionError(newDescriptionError)
    formik.setFieldValue('bugs', updatedBugs)
  }

  const handleCloseModal = () => {
    setFiles([])
    handleCloseRaiseBug()
    setTitleError([])
    setDescriptionError([])
    formik.resetForm()
  }

  // const handleAddAttachment = async files => {
  //   if (!files || Object.keys(files).length === 0) return
  //   setIsUploading(true)

  //   const formData = new FormData()
  //   const fileInfo = Object.values(files).map(file => ({
  //     filename: file.name,
  //     size: file.size,
  //     type: file.type
  //   }))
  //   Object.values(files).forEach(file => {
  //     formData.append('file', file)
  //   })

  //   try {
  //     // Upload the files using the 'uploadInnerDocuments' API.
  //     const response = await UplaodFile(formData)
  //     if (response?.data?.status_code === 201 || response?.data?.status_code === 200) {
  //       const responseData = response?.data?.data || {}
  //       setIsUploading(false)

  //       const fileList = Object.entries(responseData).map(([filename, url]) => {
  //         return {
  //           name: fileInfo[0]?.filename,
  //           url,
  //           size: fileInfo[0]?.size ?? 0
  //         }
  //       })
  //       // Append the uploaded files to the existing files state.
  //       setFiles(prevFiles => [...prevFiles, ...fileList])
  //       toast.success(response?.data.message, {
  //         position: 'top-right',
  //         theme: 'colored'
  //       })
  //     } else {
  //       setIsUploading(false)
  //     }
  //   } catch (error) {
  //     console.error('Error:', error)
  //     setIsUploading(false)
  //   }
  // }


  return (
    <>
      <Modal
        show={openRaiseBug}
        onHide={handleCloseModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div
          className='py-2 px-3'
          style={{
            background: ' linear-gradient(107.56deg, #ECF6FF 0%, #F8F8FF 25%, #EDEBFF 48%, #EFEFF7 77%, #F9F9F9 100%)',
            borderRadius: '10px',
            border: 'none',
            // height: `${height - 70}px`,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          <div className='d-flex justify-content-between w-100 pb-2 border-bottom'>
            <div className='d-flex gap-1'>
              <FaArrowLeftLong
                onClick={() => handleCloseModal()}
                size={21}
                style={{ cursor: 'pointer', color: '#000', marginTop: '2px' }}
              />
              <p style={{ color: '#000', fontWeight: '600' }}>Raise Bug</p>
            </div>

            <MdOutlineClose onClick={handleCloseModal} size={25} style={{ cursor: 'pointer' }} />
          </div>

          <Form noValidate onSubmit={formik.handleSubmit}>
            {formik.values.bugs.map((item, index) => {
              return (
                <>
                  <div
                    className='px-3 py-3'
                    style={{
                      backgroundColor: '#FFF',
                      borderRadius: '10px',
                      marginBottom: '15px',
                      position: 'relative'
                    }}
                  >
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <p style={{ color: '#000', fontSize: '13px', fontWeight: '600', marginBottom: '3px' }}>
                        Bug Title*
                      </p>
                      <Form.Control
                        name='title'
                        value={item.title}
                        onChange={event => handleBugChange(index, 'title', event.target.value)}
                      />
                      {titleError[index] ? (
                        <span style={{ color: '#e63757', fontSize: '12px', fontWeight: '600' }}>
                          {' '}
                          {titleError[index]}
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <p style={{ color: '#000', fontSize: '13px', fontWeight: '600', marginBottom: '3px' }}>
                        Description*
                      </p>
                      <Form.Control
                        name='description'
                        value={item.description}
                        onChange={event => handleBugChange(index, 'description', event.target.value)}
                        as='textarea'
                        rows={3}
                      />
                      {descriptionError[index] ? (
                        <span style={{ color: '#e63757', fontSize: '12px', fontWeight: '600' }}>
                          {' '}
                          {descriptionError[index]}
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <p style={{ color: '#000', fontSize: '13px', fontWeight: '600', marginBottom: '3px' }}>
                        Upload Documents
                      </p>

                      <div
                        {...getRootProps({
                          className: 'dropzone-area py-4 ',
                          onClick: () => {
                            bugIndex = index
                            setBugIndexState(index)
                          }
                        })}
                      >
                        <input {...getInputProps({ multiple: false })} disabled={ImgLoading} type='file' />
                        <Flex justifyContent='center'>
                          {ImgLoading && bugIndexState == index ? (
                            <Spinner size='md' />
                          ) : (
                            <>
                              <img src={cloudUpload} alt='' width={25} className='me-2' />
                              <p className='fs-9 mb-0 text-700'>Drop your Docs here</p>
                            </>
                          )}
                        </Flex>
                      </div>
                    </Form.Group>

                    <div className='mt-3 d-flex mb-4 gap-3 flex-wrap'>{fileList(index)}</div>
                  </div>
                </>
              )
            })}

            <div className='d-flex justify-content-end w-100 pb-2 '>
              <Button type='submit' style={{ borderRadius: '50px' }} disabled={isSubmitLoader}>
                {isSubmitLoader ? <Spinner size='sm' /> : 'Submit'}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}
