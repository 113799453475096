import React, { useEffect } from 'react'
import SendMessage from './SendMessage'
import { useSelector, useDispatch } from 'react-redux'

function GroupChatTextArea() {
  const dispatch = useDispatch()
  const [key, setKey] = React.useState('sms')
  const [isAccordionOpen, setIsAccordionOpen] = React.useState(true)
  const { sendThreadMessage } = useSelector(state => state.smsEmail)
  const handleSelect = eventKey => {
    dispatch({
      type: 'SEND_THREAD_MESSAGE',
      payload: {
        threadId: '',
        isOpen: true,
        threadSubject: ''
      }
    })

    setKey(eventKey)
    setIsAccordionOpen(true)
  }

  const toggleCloseAccordion = () => {
    setKey('sms')
    setIsAccordionOpen(!isAccordionOpen)

    dispatch({
      type: 'SEND_THREAD_MESSAGE',
      payload: {
        threadId: '',
        isOpen: !isAccordionOpen,
        threadSubject: ''
      }
    })
  }
  useEffect(() => {
    if (sendThreadMessage?.threadId) {
      setKey('email')
      setIsAccordionOpen(true)
    }
  }, [sendThreadMessage])

  return (
    <>
      <div
        style={{
          borderTop: '1px solid #F1F1F1',
          borderRadius: '10px'
        }}
      ></div>

      <SendMessage sendThreadMessage={sendThreadMessage} toggleCloseAccordion={toggleCloseAccordion} />
    </>
  )
}

export default GroupChatTextArea
