import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import OtpInput from 'react-otp-input'
import { useDispatch, useSelector } from 'react-redux'
import { VerifyOtp, resendOtp } from '../../../redux/login/action'
import { IoMdClose } from 'react-icons/io'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Otp = props => {
  const [otp, setOtp] = useState('')
  const [seconds, setSeconds] = useState(60)
  const [loading, setLoading] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()
  const [isLoader, setIsLoader] = useState(false)
  const [apiError, setApiError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    let interval

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [isActive, seconds])

  const handleStartStopClick = () => {
    setIsActive(!isActive)
  }

  const handleResetClick = async () => {
    setIsLoader(true)
    setIsActive(true)
    setSeconds(60)
    let { data } = await resendOtp({ email: props.email, type: 1 })
    if (data.status_code === 201) {
      setIsLoader(false)
    }
  }
  useEffect(() => {
    if (props.show) {
      handleStartStopClick()
    } else {
      setIsActive(false)
      setSeconds(60)
    }
  }, [props.show])

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(remainingSeconds).padStart(2, '0')
    return `${formattedMinutes}:${formattedSeconds}`
  }

  const handleSubmit = async () => {
    setLoading(true)
    let params = {
      otp: Number(otp),
      email: props.email,
      type: 1
    }
    const res = await dispatch(VerifyOtp(params))
    if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
      props.setOtpErrorMessage('')
      navigate(`/mtc/auth/reset-password?id=${res?.data?.data?.user_id}&&type=1`)

      toast.success(res?.data.message, {
        position: 'top-right',
        theme: 'colored'
      })
    } else {
      setLoading(false)
      setApiError(true)
      setTimeout(() => {
        setApiError(false)
      }, 2000)
    }
  }

  const handleClose = () => {
    dispatch({
      type: 'REMOVE_USER'
    })
    setOtp('')
    props.onHide()
  }

  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      style={{
        maxWidth: '380px',
        margin: 'auto',
        left: 0,
        right: 0
      }}
    >
      <div className='p-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <img
            className='me-2'
            src={'https://mytradingcoder.com/wp-content/uploads/2023/12/mtc-logo-mobile.svg'}
            alt='Logo'
            width={60}
          />{' '}
          <div onClick={handleClose}>
            <IoMdClose size={24} className='cursor-pointer' />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column justify-content-center p-2'>
        <p className='text-center fs-8 fw-bolder'>Enter Otp</p>
        <p className='text-center fs-9'>
          Please enter 4 digits code that you received <br />
          on *******{props.email.slice(-8)}
        </p>

        <div className='d-flex  justify-content-center flex-column align-items-center gap-1'>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={props => <input {...props} />}
            inputStyle={{
              border: apiError ? '1px solid crimson' : '1px solid #2E7BE5',
              borderRadius: '5px',
              width: '54px',
              height: '54px',
              fontSize: '12px',
              color: '#000',
              fontWeight: '400',
              caretColor: '#2E7BE5',
              animation: apiError ? 'shake 0.5s' : 'none'
            }}
          />
          <span style={{ fontSize: '11px', textAlign: 'center', fontWeight: '600', color: '#e63757' }}>
            {props.otpErrorMessage}
          </span>
        </div>
        <div className='d-flex  justify-content-center mt-4 mb-3'>
          <Button onClick={handleSubmit} color='primary' className='w-50' disabled={loading || isLoader}>
            {loading || isLoader ? <Spinner size='sm' /> : 'Verify'}
          </Button>
        </div>
        <div>
          {seconds > 1 && (
            <p style={{ fontSize: '12px', textAlign: 'center' }}>
              Code expires in: <span style={{ color: 'crimson' }}>{formatTime(seconds)}</span>
            </p>
          )}

          <p className='text-center fs-11'>
            Didn't receive code?{' '}
            <span onClick={handleResetClick} className='text-primary fw-bolder cursor-pointer'>
              Resend
            </span>
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default Otp
