import { ReducerType } from './type'

const InitialState = {
  loading: false,
  error: null,
  success: false,
  allUsers: [],
  develoeprs: [],
  isUserList: false,
  usersList: [],
  isJobDetail: false,
  jobDetail: null,
  isTags: false,
  tags: [],
  isClients: false,
  clientList: [],
  skills: [],
  isSkill: false
}

const users = (state = InitialState, action) => {
  switch (action?.type) {
    case ReducerType.GET_ALL_DEV:
      return {
        ...state,
        success: false,
        error: '',
        loading: true,
        develoeprs: action.payload
      }

    case ReducerType.GET_ALL_USERS_ACTION_START:
      return {
        ...state,
        isUserList: true
      }
    case ReducerType.GET_ALL_USERS:
      return {
        ...state,
        isUserList: false,
        usersList: action.payload
      }
    case ReducerType.GET_ALL_USERS_LIST:
      return {
        ...state,
        isUserList: false,
        allUsers: action.payload
      }

    case ReducerType.GET_ALL_USERS_ERROR:
      return {
        ...state,
        isUserList: false,
        usersList: []
      }

    //job detail

    case ReducerType.GET_ALL_JOBS_DETAIL_START:
      return {
        ...state,
        isJobDetail: true
      }
    case ReducerType.GET_ALL_JOBS_DETAIL:
      return {
        ...state,
        isJobDetail: false,
        jobDetail: action.payload
      }

    case ReducerType.GET_ALL_JOBS_DETAIL_ERROR:
      return {
        ...state,
        isJobDetail: false,
        jobDetail: null
      }

    //Tags List

    case ReducerType.GET_ALL_TAGS_START:
      return {
        ...state,
        isTags: true
      }
    case ReducerType.GET_ALL_TAGS:
      return {
        ...state,
        isTags: false,
        tags: action.payload
      }

    case ReducerType.GET_ALL_TAGS_ERROR:
      return {
        ...state,
        isTags: false,
        tags: []
      }

    //Clients  List

    case ReducerType.GET_ALL_CLIENTS_START:
      return {
        ...state,
        isClients: true,
        clientList: []
      }
    case ReducerType.GET_ALL_CLIENTS:
      return {
        ...state,
        isClients: false,
        clientList: action.payload
      }

    case ReducerType.GET_ALL_CLIENTS_ERROR:
      return {
        ...state,
        isClients: false,
        clientList: []
      }

    case ReducerType.GET_ALL_SKILL_START:
      return {
        ...state,
        isSkill: true
      }

    case ReducerType.GET_ALL_SKILLS:
      return {
        ...state,
        skills: action.payload,
        isSkill: false
      }

    case ReducerType.USER_UPDATE_STATUS:
      const { _id, isActive } = action.payload
      const updatedUsersList = state.usersList.map(user => {
        if (user._id === _id) {
          return { ...user, status: isActive }
        }
        return user
      })
      return {
        ...state,
        usersList: updatedUsersList
      }

    case ReducerType.DELETE_SKILL_ACTION:
      let filteredSkill = state.skills.filter(item => item._id !== action.payload)
      return {
        ...state,
        skills: filteredSkill,
        isSkill: false
      }

    case ReducerType.CLEAR_JOB_DETAIL:
      return {
        ...state,
        jobDetail: null
      }
    default:
      return state
  }
}
export default users
